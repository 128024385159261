<template>
	<div class="base-choose">
		<div class="acount-question" v-if="!isEmpty">
			<span>{{ currentQuestion+1}}</span>
			/{{ questionData.length }}
		</div>
		<div class="proress">
			<div
				class="con"
				:style="`width:${((currentQuestion+1) / questionData.length) * 100}%`"
			></div>
		</div>
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<coustm-empty v-if="isEmpty"></coustm-empty>
			
			<template v-else>
				<div class="words-box">
					<ul class="word-list" :class="{ hideWord: questionType == 2 , zixingbianxi: zixingbianxi}">
						<li v-for="(word, index) in questionData[currentQuestion].stem" :key="`word${index}`" :class="{ zixingbianxiChile: filertword(word) }">
							<!-- <wordBg> -->
								<span v-html="word"></span>
							<!-- </wordBg> -->
						</li>
					</ul>
					<p class="chouchu" v-if="questionData[currentQuestion].analysis">
						<span>--</span>
						<span v-html="questionData[currentQuestion].analysis"></span>
					</p>
					<div class="option-btns">
						<ul class="options">
							<li
								v-for="(option, index) in questionData[currentQuestion].option"
								:key="`option${index}`"
								@click="chooseAction(option.val, index)"
								:class="{
									success: option.status == 1,
									error: option.status == 2
								}"
							>
								<span v-html="option.text"></span>
							</li>
						</ul>
					</div>
				</div>

				<div class="question-handle">
					<div class="btn" :class="{ disable: currentQuestion == 0 }" @click="changeQuestion(1)">上一题</div>
					<div
						class="btn"
						:class="{ disable: currentQuestion == questionData.length - 1 }"
						@click="changeQuestion(2)"
					>
						下一题
					</div>
				</div>
			</template>
		</template>
		<audio
		  :src="errorVoice"
		  controlslist="nodownload"
		  controls="controls"
		  ref="errorAudio"
		  class="status-vioce"
		></audio>
		
		<audio
		  :src="properVoice"
		  controlslist="nodownload"
		  controls="controls"
		  ref="properAudio"
		  class="status-vioce"
		></audio>
	</div>
</template>

<script>
import { matchKeyWordToView, setOptions, deepClone, html2string } from '@/utils/public';
export default {
	name: 'baseChoose',
	components: {
		// wordBg: () => import('@/components/wordBg.vue')
	},
	props: {
		questionType: {
			type: [String, Number],
			default: 1
		},
		zixingbianxi: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			errorVoice: require("@/assets/audio/error.mp3"),
			properVoice: require("@/assets/audio/proper.mp3"),
			properAudio: null,
			errorAudio: null,
			isEmpty: false,
			isLoading: false,
			sourceData: [],
			questionData: [],

			currentQuestion: 0
		};
	},
	created() {
		//this.computeQuestion();
		this.initData();
		this.addHistory();
	},
	mounted() {
		this.computeQuestion();
		this.properAudio = this.$refs["properAudio"];
		this.errorAudio = this.$refs["errorAudio"];
	},
	methods: {
		matchKeyWordToView,
		setOptions,
		deepClone,
		html2string,
		changeQuestion(n) {
			console.log((this.currentQuestion+1)/this.questionData.length)
			if (n == 1) {
				if (0 < this.currentQuestion) {
					this.currentQuestion = --this.currentQuestion;
				} else {
					this.$notify({
						type: 'warning',
						message: '已经是第一道题了'
					});
				}
			}
			if (n == 2) {
				if (this.currentQuestion < this.questionData.length - 1) {
					this.currentQuestion = ++this.currentQuestion;
				} else {
					this.$dialog
						.confirm({
							message: '已是最后一个，再做一次？'
						})
						.then(() => {
							this.currentQuestion = 0;
							this.questionData = this.deepClone(this.sourceData);
						}) 
						.catch(() => {});
				}
			}
		},
		chooseAction(val, index) {
			let currentData = this.deepClone(this.questionData[this.currentQuestion]);
			let options = currentData.option;
			let answer = this.html2string(currentData.answer);
			for (let i = 0; i < options.length; i++) {
				options[i].status = 0;
			}
			if (answer == val) {
				options[index].status = 1;
				currentData.status = 1;
				this.properAudio.play();
			} else {
				options[index].status = 2;
				currentData.status = 2;
				this.errorAudio.play();
			}
			currentData.option = options;
			this.$set(this.questionData, this.currentQuestion, currentData);
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuQuestion({
					course_id:
						this.$route.query.childId ||
						this.$route.query.channelId ||
						this.$route.query.classId ||
						this.$route.query.unitId
				})
				.then((res) => {
					let resData = res.data;
					this.isLoading = false;
					if (resData.length) {
						this.questionData = resData[0].children;
						this.computeQuestion();
					} else {
						this.isEmpty = true;
					}
				});
		},

		computeQuestion() {
			let targetArr = [];
			for (let i = 0; i < this.questionData.length; i++) {
				//console.log(this.questionData[i]);
				targetArr.push({
					stem: this.matchKeyWordToView(this.questionData[i].stem),
					option: this.setOptions(this.questionData[i].option, true),
					answer: this.questionData[i].answer,
					status: 0
				});
			}
			this.sourceData = this.deepClone(targetArr);
			this.questionData = targetArr;
		},
		filertword(word) {
			return word.indexOf('em') != -1;
		}
	}
};
</script>
<style lang="scss">
.hideWord {
	li {
		em {
			opacity: 0;
		}
	}
}
.word-list {
	li {
		em {
			font-style: normal;
			color: #20a287;
		}
	}
}
.option-btns {
	.options {
		li {
			* {
				font-size: 0.36rem !important;
				font-weight: 400 !important;
				color: #333 !important;
			}
		}
		.success {
			* {
				color: #fff !important;
			}
		}
		.error {
			* {
				color: #fff !important;
			}
		}
	}
}
</style>
<style lang="scss" scoped>
.hideWord.zixingbianxi {
	li.zixingbianxiChile {
		height: 0.05rem;
		background-color: #20a287;
		border-radius: 0.03rem;
		padding: 0 0.2rem;
		position: relative;
		top: 0.4rem;
	}
}
.words-box {
	width: 100%;
	height: calc(100% - 1.08rem);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	// padding-top: 0.5rem;
}

.option-btns {
	width: calc(100% - 0.6rem);
	
	.options {
		margin: 0;
		width: 100%;
		li {
			list-style: none;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			padding: 0.3rem;
			background: #fff;
			border: 1px solid #dcdcdc;
			border-radius: 25px;
			margin: 0.3rem 0;
			color: #333;
			cursor: default;
			user-select: none;
		}
		.success {
			background: #20a287;
		}
		.error {
			background: #ff6d6d;
		}
	}
}
.word-list {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 0.6rem;
	font-size: 0.48rem;
	li {
		// margin: 5px;
	}
}
.base-choose {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	padding: 0.3rem 0;
}

.question-handle {
	position: fixed;
	left: -0.3rem;
	right: -0.3rem;
	bottom: 0.16rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.28rem;
	background: #fff;

	.btn {
		flex: 1;
		margin: 0 0.15rem;
		height: 0.68rem;
		background-size: cover;
		background-repeat: no-repeat;
		border: 0.02rem solid #28b295;
		border-radius: 33px;
		text-align: center;
		line-height: 35px;
		font-size: 0.26rem;
		font-weight: 400;
		color: #333;
		background: #fff;
		height: 35px;
		line-height: 35px;
		&:active {
			background: rgba(40, 178, 149, 0.1);
		}
		&.disable {
			border-color: #e1e1e1;
			background: #f1f1f1;
		}
	}
}

</style>
