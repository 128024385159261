<template>
	<div class="children-page">
		<navBar @goBack="gobackChapter">
			<span class="title">一词多义</span>
		</navBar>
		<baseChoose :questionType="1" :openType="true"></baseChoose>
	</div>
</template>

<script>
export default {
	name: 'yiCiDuoYi',
	components: {
		baseChoose: () => import('../components/baseChoose.vue')
	},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
.children-page {
	width: 100%;
	height: 100%;
}
</style>
