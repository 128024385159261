<template>
	<div class="base-choose">
		<div class="acount-question" v-if="!isEmpty">
			<span>{{ currentQuestion+1}}</span>
			/{{ questionData.length }}
		</div>
		<div class="proress">
			<div
				class="con"
				:style="`width:${((currentQuestion+1) / questionData.length) * 100}%`"
			></div>
		</div>
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<coustm-empty v-if="isEmpty"></coustm-empty>
			<template v-else>
				<div class="words-box">
					<div class="shiwen-box">
						<p class="shiwen">
							<span v-html="questionData[currentQuestion].stem"></span>
						</p>
						<p class="chouchu" v-if="questionData[currentQuestion].analysis">
							<span>--</span>
							<span v-html="questionData[currentQuestion].analysis"></span>
						</p>
					</div>
					<div class="option-btns">
						<div class="textarea-box">
							<textarea
								class="user-input"
								resize="none"
								rows="6"
								v-model="questionData[currentQuestion].userAnswer"
								placeholder="请输入您的答案~"
							></textarea>
							<!-- <div class="view-answer" @click="showAnswer = true">查看答案</div> -->
						</div>
					</div>
				</div>

				<div class="question-handle">
					<div class="btn" :class="{ disable: currentQuestion == 0 }" @click="changeQuestion(1)">上一题</div>
					<div
						class="btn"
						:class="{ disable: currentQuestion == questionData.length - 1 }"
						@click="changeQuestion(2)"
					>
						下一题
					</div>
					<div class="btn jiexi-btn" @click="showAnswer = true">查看解析</div>
				</div>
			</template>
		</template>
		<div class="answer-layer" v-if="showAnswer" @click="showAnswer = false">
			<div class="layer-main" v-html="questionData[currentQuestion].answer"></div>
		</div>
	</div>
</template>

<script>
import { matchKeyWordToView, setOptions, deepClone } from '@/utils/public';
export default {
	name: 'guShiWen',
	data() {
		return {
			showAnswer: false,
			isEmpty: false,
			isLoading: false,
			sourceData: [],
			questionData: [],

			currentQuestion: 0
		};
	},
	created() {
		//this.computeQuestion();
		this.initData();
		this.addHistory();
	},
	mounted() {
		this.computeQuestion();
	},
	methods: {
		matchKeyWordToView,
		setOptions,
		deepClone,
		changeQuestion(n) {
			if (n == 1) {
				if (0 < this.currentQuestion) {
					this.currentQuestion = --this.currentQuestion;
				} else {
					this.$notify({
						type: 'warning',
						message: '已经是第一道题了'
					});
				}
			}
			if (n == 2) {
				if (this.currentQuestion < this.questionData.length - 1) {
					this.currentQuestion = ++this.currentQuestion;
				} else {
					this.$dialog
						.confirm({
							message: '已是最后一个，再做一次？'
						})
						.then(() => {
							this.currentQuestion = 0;
							this.questionData = this.deepClone(this.sourceData);
						}).catch(() => {});
				}
			}
		},
		chooseAction(val, index) {
			let currentData = this.deepClone(this.questionData[this.currentQuestion]);
			let options = currentData.option;
			let answer = currentData.answer;
			for (let i = 0; i < options.length; i++) {
				options[i].status = 0;
			}
			if (answer == val) {
				options[index].status = 1;
				currentData.status = 1;
			} else {
				options[index].status = 2;
				currentData.status = 2;
			}
			currentData.option = options;
			this.$set(this.questionData, this.currentQuestion, currentData);
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuQuestion({
					course_id:
						this.$route.query.childId ||
						this.$route.query.channelId ||
						this.$route.query.classId ||
						this.$route.query.unitId
				})
				.then((res) => {
					let resData = res.data;
					this.isLoading = false;
					if (resData.length) {
						this.questionData = resData[0].children;
						this.computeQuestion();
					} else {
						this.isEmpty = true;
					}
				});
		},

		computeQuestion() {
			let targetArr = [];
			for (let i = 0; i < this.questionData.length; i++) {
				//console.log(this.questionData[i]);
				targetArr.push({
					stem: this.questionData[i].stem,
					answer: this.questionData[i].answer,
					userAnswer: '',
					analysis: this.questionData[i].analysis
				});
			}
			this.sourceData = this.deepClone(targetArr);
			this.questionData = targetArr;
		}
	}
};
</script>
<style lang="scss">
.word-list {
	li {
		em {
			font-style: normal;
			color: #20a287;
		}
	}
}
</style>
<style lang="scss" scoped>
.chouchu {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	font-size: 0.28rem;
}
.words-box {
	width: 100%;
	height: calc(100% - 1.3rem);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.shiwen-box {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 0.3rem;
		margin-bottom: 0.6rem;
		.shiwen {
			margin-bottom: 0.3rem;
			::v-deep {
				font-size: 0.32rem;
			}
			::v-deep em {
				font-style: normal;
				color: #20a287;
				font-weight: bold;
			}
		}
	}
}
.answer-layer {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	.layer-main {
		width: 95%;
		min-height: 3rem;
		padding: 0.3rem;
		border-radius: 0.1rem;
		background: #fff;
		::v-deep * {
			font-size: 0.26rem !important;
			color: #333 !important;
			line-height: 1.75em !important;
		}
	}
}
.option-btns {
	display: flex;
	justify-content: center;
	align-items: center;
	    font-size: 0.28rem;
	    margin-bottom: 1.2rem;
	.textarea-box {
		position: relative;
		width: 100vw;
		padding: 0 0.3rem;
		.user-input {
			width: 100%;
			height: 100%;
			border: 1px #dcdcdc solid;
			border-radius: 0.1rem;
			padding: 0.2rem;
			resize: none;
			font-size: 0.3rem;
		}
		.view-answer {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			user-select: none;
			cursor: default;
			z-index: 1000;
			height: 0.64rem;
			background: #dcdcdc;
			font-size: 0.24rem;
			font-weight: 400;
			color: #333;
		}
	}

	.btns {
		width: 2rem;
		height: 0.64rem;
		background: #20a287;
		border-radius: 0.32rem;
		font-size: 0.24rem;
		font-weight: 400;
		color: #fff;
		line-height: 0.64rem;
		text-align: center;
		margin: 0 0.64rem;
		padding: 0;
		border: 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		transition: all 0.3s ease-in-out;
		&:hover {
			opacity: 0.8;
		}
	}
	.btns.disable {
		background: #dcdcdc;
	}
}
.word-list {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 0.6rem;
	font-size: 0.48rem;
	li {
		// margin: 0.1rem;
	}
}
.base-choose {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	padding: 0.3rem 0;
}
</style>
