<template>
	<div class="public-page">
		<navBar @goBack="gobackMoudle">
			<span class="title">
				{{ currentTitle }}
			</span>
	
		</navBar>
		
		<div class="public-page-content">
			<template v-if="isFlash">
				<div class="is-flash">不支持Flash播放，请移步PC端使用360浏览器学习此模块知识。</div>
			</template>
			<template v-else>
				<template v-if="menutype == 2">
					<textImgContent :showLayer="showLayer" @closeLayer="closeLayer"></textImgContent>
				</template>
				<template v-if="menutype == 3">
					<vidoeList ></vidoeList>
				</template>
				<template v-if="menutype == 5">
					<wenXueChangShi ></wenXueChangShi>
				</template>
				<template v-if="menutype == 6">
					<baseInput v-if="['成语', '文言文虚词'].includes(currentTitle)"></baseInput>
					<jinYiCi v-else-if="['近义实词', '近义虚词'].includes(currentTitle)"></jinYiCi>
					<guShiWen v-else-if="['古诗文必备','古诗文必背'].includes(currentTitle)"></guShiWen>
					<template v-else>
						<questionContent v-if="['单元检测'].includes(currentTitle)"></questionContent>
						<questionList v-else></questionList>
					</template>
				</template>
				<normalQuestion v-if="menutype == 11"></normalQuestion>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	name: 'publicPage',
	components: {
		textImgContent: () => import('@/views/channel/components/textImgContent.vue'),
		questionContent: () => import('@/views/channel/components/questionContent.vue'),
		questionList: () => import('@/views/channel/components/questionList.vue'),
		normalQuestion: () => import('@/views/channel/components/normalQuestion.vue'),
		baseInput: () => import('@/views/channel/components/baseInput.vue'),
		vidoeList: () => import('@/views/channel/components/videoList2.vue'),
		jinYiCi: () => import('@/views/channel/components/jinYiCi.vue'),
		guShiWen: () => import('@/views/channel/components/guShiWen.vue'),
		wenXueChangShi: () => import('@/views/channel/yuWen/wenXueChangShiPublic.vue')
	},
	data() {
		return {
			showLayer: false,
			currentTitle: this.$route.query.childName || this.$route.query.channelName || this.$route.query.className,
			channelName: this.$route.query.channelName,
			menutype: 2,
			isFlash: false
		};
	},
	
	created() {
		this.menutype = this.$route.query.menutype;
		this.isFlash = this.$route.query.moudleName == '实验探究' ? true : false;
	},
	methods: {
		closeLayer() {
			this.showLayer = false;
		},
		showMenu() {
			this.showLayer = !this.showLayer;
		}
	}
};
</script>

<style lang="scss" scoped>
	
.is-flash {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.24rem;
	padding: 0 0.5rem;
	// margin-top: 0.2rem;
}

.public-page {
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
	background: #fafafa;
	.public-page-content {
		height: 100%;
		background: #fff;
		::v-deep {
			.question-content {
				padding-top: 0;
			}
		}
	}
}
</style>
