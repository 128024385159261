<template>
	<div>
		<navBar @goBack="goBack" >
			<span class="title">
				{{ currentTitle }}
			</span>
		</navBar>
	<div class="page-box hasnav notab">
		
		<loading-box v-if="isLoading"></loading-box>
		<div class="page-content" v-if="!isLoading">
			<ul class="course-outline" v-if="dataList.length">
				<li v-for="(item, index) in dataList" :key="`a${index}`" @click="goNext(item)">
					<van-image class="img" fit="cover" :src="setSourceUrl(item.h5icon)"></van-image>
				</li>
			</ul>
			<coustm-empty v-else></coustm-empty>
		</div>
	</div>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import loadingBox from '@/components/loadingBox.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
export default {
	components: { loadingBox, CoustmEmpty },
	name: 'classChannle',
	data() {
		return {
			isLoading: true,
			currentTitle: this.$route.query.subjectName,
			dataList: []
		};
	},
	created() {
		let moudleData = window.sessionStorage.getItem(this.$route.query.subjectId);
		if (!moudleData) {
			this.init();
		} else {
			this.isLoading = false;
			this.dataList = JSON.parse(moudleData);
		}
	},

	mounted() {
		window.sessionStorage.setItem('menuScrollTop', 0);
	},
	methods: {
		setSourceUrl,
		goBack() {
			this.$router.push({
				path: `/`,
				query: {
					active: 0
				}
			});
		},
		init() {
			this.isLoading = true;
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: 0
				})
				.then((res) => {
					this.isLoading = false;
					this.dataList = res.data || [];
					window.sessionStorage.setItem(this.$route.query.subjectId, JSON.stringify(this.dataList));
				});
		},
		goNext(item) {
			this.$router.push({
				path: item.url,
				query: {
					...this.$route.query,
					moudleId: item.id,
					moudleName: item.name,
					from: 'channel'
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
	.page-box.hasnav{
		background-color: #f5f5f5;
	}
.page-content {
	height: calc(100% - 1.08rem);
}

.course-outline {
	padding: 14px 14px 0 14px;

	li {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
		.img {
			width: 100%;
			border-radius: 5px;
			overflow: hidden;
		}
	}
}
 
.page-box.notab{
	// border-top: 0.2rem solid #f5f5f5;
}    
</style>
