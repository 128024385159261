<template>
	<div class="dancisuji-page">
		<navBar @goBack="gobackMoudle">
			<span class="title">
				{{ currentTitle }}
			</span>
			<template v-slot:right>
				<div class="acount-question" v-if="!isEmpty">
					<span>{{ currentQuestion + 1 }}</span>
					/{{ questionData.length }}
				</div>
			</template>
		</navBar>

		<ul class="suji-nav">
			<li
				v-for="(nav, index) in sujiNav"
				:key="index"
				@click="changeNav(index)"
				:class="{ active: activeMoudle == index }"
			>
				<div class="icon" :style="`backgroundImage:url(${nav.icon})`"></div>
				<span>{{ nav.title }}</span>
			</li>
		</ul>

		<div class="suji-content">
			<loadingBox v-if="isLoading"></loadingBox>
			<template v-else>
				<template v-if="!isEmpty">
					<danCiSuJi
						v-if="activeMoudle == 0"
						:lengthNum="questionData.length"
						:currentIndexParent="currentQuestion"
						:dataObj="questionData[currentQuestion]"
						:typeParent="typeParent"
					></danCiSuJi>
					<danCiPinXie
						v-if="activeMoudle == 1"
						:lengthNum="questionData.length"
						:dataObj="questionData[currentQuestion]"
					></danCiPinXie>

					<danCiLianXi
						v-if="activeMoudle == 2"
						:totalQuestion="questionData.length"
						:currentQuestion="currentQuestion"
						:dataObj="questionData[currentQuestion]"
						@changeQuestion="(val) => changeQuestion(val)"
					></danCiLianXi>
				</template>
				<coustmEmpty v-else></coustmEmpty>
			</template>
		</div>
	</div>
</template>

<script>
import { deepClone } from '@/utils/public';
export default {
	name: 'danCiSuJiPage',
	components: {
		danCiSuJi: () => import('@/views/channel/components/danCiSuJi.vue'),
		danCiPinXie: () => import('@/views/channel/components/danCiPinXie.vue'),
		danCiLianXi: () => import('@/views/channel/components/danCiLianXi.vue')
	},
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			currentTitle: this.$route.query.channelName||this.$route.query.childName,
			sujiNav: [
				{
					icon: require('@/assets/image/33.png'),
					title: '速记'
				},
				{
					icon: require('@/assets/image/22.png'),
					title: '拼写'
				},
				{
					icon: require('@/assets/image/11.png'),
					title: '练习'
				}
			],
			activeMoudle: 0,
			currentQuestion: 0,
			questionData: [],
			questionSourse: [],
			RecordsData: {
				sj: 0,
				px: 0,
				yyh: 0,
				hyy:0
			},
			typeParent:1
		};
	},
	created() {
		this.getRecords()
		this.initData();
	},
	mounted() {
		this.$eventBus.$on('repeatOperate', () => {
			this.currentQuestion = 0;
			this.questionData = this.deepClone(this.questionSourse);
			
			this.$eventBus.$emit('changeQuestion', {n:this.currentQuestion,type:2,num:this.currentQuestion});
		});
		this.$eventBus.$on('masterWord', (obj) => {
			this.changeQuestion(obj);
		});
		this.$eventBus.$on('selecTranslateModel', (obj) => {
			this.selecTranslateModel(obj);
		});
		
		this.$eventBus.$on('unKnowWord', (val) => {
			this.currentQuestion = val;
			let dataList = this.questionData;
			let unKuowObj = dataList[val];
			let unknowNum = ++unKuowObj.unknowNum;
			let step = 0;
			step = val + unknowNum + 1;
			if (dataList.length < step) {
				step = dataList.length;
			}
			unKuowObj.unknowNum = unknowNum;
			dataList.splice(val, 1);
			dataList.splice(step, 0, unKuowObj);
			this.questionData = dataList;
		});
	},
	methods: {
		deepClone,

		changeQuestion(obj) {
			let {n,type,num} = obj
			let that = this;
			if (n == 1) {
				if (0 < this.currentQuestion) {
					this.currentQuestion = --this.currentQuestion;
					num = num?num:this.currentQuestion
					this.setRecords(type,num);
				} else {
					this.$notify({
						type: 'warning',
						message: '已经是第一道题了'
					});
				}
			}
			if (n == 2) {
				if (this.currentQuestion < this.questionData.length - 1) {
					this.currentQuestion = ++this.currentQuestion;
					this.setRecords(type,num || this.currentQuestion);
				} else {
					this.$dialog
						.confirm({
							message: '已是最后一个，再做一次？'
						})
						.then(() => {
							that.currentQuestion = 0;
							that.questionData = that.deepClone(that.questionSourse);
						}).catch(() => {});
				}
			}
			// this.$nextTick(() => {
			// this.initSelectEvent(this.questionData[this.currentQuestion].classtext);
			// });
		},

		initData() {
			this.isLoading = true;
			this.$api
				.getWords({
					course_id: this.$route.query.childId || this.$route.query.channelId || this.$route.query.classId,
					size: 0
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						resData.forEach((item) => {
							item.unknowNum = 0;
							item.hyy = this.computedOptions(item.hyy);
							item.yyh = this.computedOptions(item.yyh);
							item.status = 0;
							item.userSelect = 100;
						});
						this.questionData = resData;
						this.questionSourse = this.deepClone(resData);
					} else {
						this.isEmpty = true;
					}
					this.isLoading = false;
				});
		},
		computedOptions(str) {
			str = str || '';
			str = str.replace(/<ol>/g, '');
			str = str.replace(/<ul>/g, '');
			str = str.replace(/<\/ul>/g, '');
			str = str.replace(/<\/ol>/g, '');
			str = str.replace(/<li>/g, '');
			str = str.replace(/<\/li>/g, '');
			let arr = str.split('\n');
			let targetArr = [];
			for (let i = 0; i < arr.length; i++) {
				if (arr[i]) {
					targetArr.push(arr[i]);
				}
			}
			return targetArr;
		},
		changeNav(n) {
			this.activeMoudle = n;
			if(n==0){
				this.currentQuestion = this.RecordsData.sj;
			}else if(n==2){
				this.currentQuestion = 0;
			}else{
				this.currentQuestion = 0;
			}
			this.$forceUpdate()
			this.questionData = this.deepClone(this.questionSourse);
			this.$eventBus.$emit('changeLianXi');
		},
		selecTranslateModel(n){
			if(n==1){
				this.currentQuestion = this.RecordsData.hyy;
			}else {
				this.currentQuestion = this.RecordsData.yyh;
			}
			this.$forceUpdate()
		},
		//添加记录
		setRecords(type,num) {
			this.$api
				.getAddnum({
					course_id: this.$route.query.childId || this.$route.query.channelId || this.$route.query.classId,
					type: type,
					num: num,
				})
				.then((res) => {
					// debugger;
				});
		},
		// 获取记录
		getRecords() {
			this.$api
				.getWordnum({
					course_id: this.$route.query.childId || this.$route.query.channelId || this.$route.query.classId,
					// type: 1
				})
				.then((res) => {
					let resData = res.data;
					if(resData.length){
						resData.forEach(item=>{
							if(item.type == 1){
								this.RecordsData.sj = item.wordnum + 1
								this.currentQuestion = item.wordnum + 1
							}else if(item.type == 2){
								this.RecordsData.px = item.wordnum + 1
							}else if(item.type == 3){
								this.RecordsData.yyh = item.wordnum + 1
							}else if(item.type == 4){
								this.RecordsData.hyy = item.wordnum + 1
							}
						})	
						this.$forceUpdate()
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.acount-question {
	font-size: 0.3rem;
	font-weight: 400;
	color: #969799;
	span {
		font-size: 0.52rem;
		color: #36ba9e;
		transform: translateY(0.03rem);
	}
}

.dancisuji-page {
	padding-top: 1.06rem;
	height: 100%;
	background: #fafafa;
	.suji-content {
		background: #fff;
		padding: 0.3rem;
		height: calc(100% - 0.9rem);
		
	}
}
.suji-nav {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	background: #fff;
	padding-top: 0.3rem;
	border-top: 0.2rem solid #f5f5f5;
	li {
		display: flex;
		align-items: center;
		font-size: 0.3rem;
		.icon {
			width: 0.32rem;
			height: 0.32rem;
			background-size: 0.32rem 0.32rem;
			margin-right: 0.1rem;
		}
	}
	.active {
		color: #20a287;
	}
}
</style>
