<template>
<div class="public-imgtext-page">
<!-- <textImgContent></textImgContent> -->
<textImgContent v-if="menutype == 2"></textImgContent>
<questionList v-else position="right" style="padding-top: 0"></questionList>
</div>
</template>

<script>
export default {
name: 'zhuanTiGuiNa',
components: {
questionList: () => import("@/views/channel/components/questionList.vue"),
textImgContent: () => import('@/views/channel/components/textImgContent.vue')
},
data() {
return {
menutype: "",
}
},

created() {
//this.addHistory();
this.menutype = this.$route.query.menutype;
},
}
</script>

<style lang="scss" scoped>
.public-imgtext-page {
height: 100%;
}
</style>