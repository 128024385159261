<template>
<div class="tongbuketang-page">
<navBar @goBack="gobackMoudle">
<span class="title">{{title}}</span>
</navBar>
<vidoeList2></vidoeList2>
</div>
</template>
<script>
export default {
name: 'kaoDianJingJiang',
components: {
vidoeList2: () => import("@/views/channel/components/videoList2.vue")
},
data () {
return {
title: this.$route.query.childName
}
},

created () {
},
}
</script>

<style lang="scss" scoped>
.tongbuketang-page {
width: 100%;
height: 100%;
padding-top:1.08rem;
}
</style>