<template>
	<div class="word-shorthand"  @click="showKeyboard = false">
		<div class="area-box">
			<div class="scroll-area">
				<div class="area-box-main">
					<div class="input-word" @click.stop="showKeyboardFn">
						<!-- <div class="input-word" @click.stop="onFoustu"> -->
						<span
							class="letter-input"
							:class="{
								'letter-input-right': letter == inputWord[index],
								'letter-input-error': letter != inputWord[index] && inputWord[index]
							}"
							v-for="(letter, index) in dataObj.word.toLowerCase()"
							:key="`letter${index}`"
						>
							{{ inputWord[index] }}
						</span>
					</div>
					<div class="voice-item" v-if="privateData.phonetics" @click.stop="startPlay">
						<span style="padding-right: 5px">{{ privateData.phonetics }}</span>

						<svg-icon
							icon-class="voice"
							class="icon"
							:class="{ 'icon-active': audioPlaying }"
							v-if="dataObj.audio"
						></svg-icon>
					</div>

					<div class="des">
						<div style="margin-bottom: 0.2rem" v-html="privateData.translate"></div>
					</div>
					<div class="area-example">
						<van-image fit="cover" class="moudle-img" :src="setSourceUrl(privateData.image)" />
					</div>

					<div class="prase">
						<p v-html="privateData.liju"></p>
						<p v-html="privateData.lijufy" class="parse-text"></p>
					</div>
				</div>
			</div>
		</div>

		<div class="audio-box">
			<audio id="audio" ref="audio" :src="setSourceUrl(audioSrc)" @ended="ended">
				您的浏览器不支持 audio 标签。
			</audio>
		</div>
		<!-- <div class="keyboard" v-show="showKeyboard" @click.stop="showKeyboard = true">
			<ul class="letter">
				<li v-for="(w, index) in keyWords" :key="`w${index}`" @click.stop="changeWrod(w)">
					{{ w }}
				</li>
			</ul>
		</div> -->
		<keyBoard v-model="keyBoardData" :showKeyboard="showKeyboard" :keyMax="keyMax"></keyBoard>
		<input
			style="width: 0px; height: 0px; position: absolute; z-index: -1; font-size: 14px"
			@input="inpevent"
			type="text"
			ref="inp"
			v-model="inpData"
		/>
	</div>
</template>

<script>
import { html2string, setSourceUrl, deepClone } from '@/utils/public';
import keyBoard from '../../../components/keyBoard'
export default {
	name: 'danCiPinXie',
	components:{keyBoard},
	props: {
		dataObj: {
			type: Object,
			default: () => {
				return {};
			}
		},
		lengthNum: {
			type: [String, Number],
			default: 0
		},
		currentIndexParent: {
			type: [String, Number],
			default: 0
		},
		active: {
			type: [String, Number],
			default: 0
		}
	},
	watch: {
		dataObj: {
			handler: function (val) {
				this.privateData = this.deepClone(val);
				this.audioSrc = this.privateData.audio;
				this.wordSource = [];
				this.inputWord = [];
				this.inputStatus = [];
				let word = val.word;
				for (let i = 0; i < word.length; i++) {
					this.$set(this.wordSource, i, word[i].toLowerCase());
					this.$set(this.inputStatus, i, 1);
				}

				setTimeout(() => {
					this.startPlay();
				}, 200);
			},
			immediate: true
		},
		lengthNum: {
			handler: function (val) {
				this.totalNum = val;
			},
			immediate: true
		},
		keyBoardData: {
			handler: function (val) {
				let _this = this;
				this.inputWord = val
				let targetWord = this.dataObj.word.toLowerCase();
				let _val = val.join('');
				if (_val == targetWord) {
					setTimeout(() => {
						this.showKeyboard = false;
						_this.changeQuestion();
					}, 1000);
				}
			},
			immediate: true
		},
		// inputWord: function (val) {
		// 	let _this = this;
		// 	let targetWord = this.dataObj.word.toLowerCase();
		// 	let _val = val.join('');
		// 	if (_val == targetWord) {
		// 		setTimeout(() => {
		// 			this.showKeyboard = false;
		// 			_this.changeQuestion();
		// 		}, 2000);
				
		// 	}
		// }
	},
	data() {
		return {
			keyBoardData:[],
			showKeyboard: false,
			keyMax:1,
			privateData: {},
			totalNum: 0,
			currentIndex: this.currentIndexParent,
			audioPlaying: false,
			audioSrc: '',
			nextBtn: false,
			acountQuestion: 0,
			wordSource: [],
			inputStatus: [],
			showViewAll: false,
			inpData: '',
			keyWords: [
				'q',
				'w',
				'e',
				'r',
				't',
				'y',
				'u',
				'i',
				'o',
				'p',
				'a',
				's',
				'd',
				'f',
				'g',
				'h',
				'j',
				'k',
				'l',
				'z',
				'x',
				'c',
				'v',
				'b',
				'n',
				'm',
				',',
				'.',
				'!',
				'?',
				'-',
				'　'
			],
			inputIndex: 0,
			inputWord: []
		};
	},
	created() {
		this.addHistory();
	},
	mounted() {},
	methods: {
		deepClone,
		setSourceUrl,
		html2string,
		onFoustu() {
			let that = this;
			let device = window.localStorage.getItem('device');
			if (device == 'android') {
				this.$toast('请切换至26键输入');
				setTimeout(() => {
					that.$refs.inp.focus();
				}, 2000);
			} else {
				that.$refs.inp.focus();
			}
		},
		inpevent(event) {
			let dnumber1 = event.target.value;
			console.log(dnumber1, 'yyyyyyyyyy');
			dnumber1 = dnumber1.toLowerCase();
			if (dnumber1.length > this.dataObj.word.length) {
				this.inpData = dnumber1.slice(0, this.dataObj.word.length);
				dnumber1 = dnumber1.slice(0, this.dataObj.word.length);
			}
			let wordData = [];
			for (let i = 0; i < dnumber1.length; i++) {
				wordData.push(dnumber1[i]);
			}
			this.inputWord = wordData;
		},

		changeQuestion() {
			if (this.inputWord.join('') == this.wordSource.join('')) {
				this.acountQuestion = ++this.acountQuestion;
			} else {
				return;
			}
			if (this.currentIndex < this.totalNum - 1) {
				this.currentIndex = ++this.currentIndex;
				this.inputWord = [];
				this.inpData = [];
				this.keyBoardData = [];
				this.inputIndex = 0;
				this.$refs.inp.blur();
			} else {
				let that = this;

				this.$dialog
					.confirm({
						message: '已是最后一个，再做一次？'
					})
					.then(() => {
						that.currentIndex = 0;
						that.nextBtn = false;
						that.$eventBus.$emit('repeatOperate');
					})
					.catch(() => {});

				return;
			}
			this.audioSrc = '';
			setTimeout(() => {
				this.audioSrc = this.privateData.audio;
				this.$refs.audio.pause();
				this.audioPlaying = false;
			}, 30);
			let obj = {
				type: 2,
				num: this.currentIndex,
				n: 2
			};
			this.$eventBus.$emit('masterWord', obj);
		},

		startPlay() {
			this.$refs.audio.play();
			this.audioPlaying = true;
		},
		ended() {
			this.audioPlaying = false;
		},
		changeWrod(val,e) {
			let targetWord = this.dataObj.word.toLowerCase();
			if (val == '　') {
				val = ' ';
			}
			if (val == targetWord[this.inputIndex]) {
				this.$set(this.inputWord, this.inputIndex, val);
				this.inputIndex = ++this.inputIndex;
			} else {
				this.$set(this.inputWord, this.inputIndex, val);
			}
		},
		showKeyboardFn(){
			this.showKeyboard = true;
			this.keyMax = this.dataObj.word.toLowerCase().length;
		}
	}
};
</script>

<style lang="scss" scoped>

.scroll-area {
	height: 100%;
	overflow: auto;
}
.acount-question {
	width: 1.78rem;
	height: 0.48rem;
	background: #ffffff;
	border: 0.02rem solid #1e9e85;
	border-radius: 0.24rem;
	font-size: 0.24rem;
	font-weight: 400;
	color: #1e9e85;
	line-height: 0.48rem;
	text-align: center;
	margin-bottom: 0.22rem;
}
.input-word {
	position: relative;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	overflow: hidden;
	margin-bottom: 15px;
	.words-input {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0;
	}
}
.letter-input {
	width: 28px;
	height: 36px;
	background: #e4e4e4;
	margin-right: 5px;
	margin-bottom: 5px;
	font-size: 30px;
	line-height: 36px;
	text-align: center;
	color: #fff;

	&-right {
		background: #27b295;
	}
	&-error {
		background: #ff545c;
	}
}

.area-box-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.word-txt {
	font-size: 0.42rem;
	font-weight: 500;
	color: #333;
	line-height: 0.52rem;
	margin-bottom: 0.35rem;
}
.iconfont {
	font-size: 0.42rem;
	margin-left: 0.2rem;
}
.icon-active {
	color: #009b7c;
}
.voice-item {
	display: flex;
	align-items: center;
	height: 32px;
	border-radius: 16px;
	padding: 8px 10px;
	font-size: 14px;
	color: #323233;
	background: #f4f5f7;
	margin-bottom: 15px;
	.icon-active {
		color: #009b7c;
	}
}
.des {
	font-size: 14px;
	line-height: 1.75em;
	color: #ff0000;
	margin-bottom: 15px;
}
.prase {
	font-weight: 400;
	font-size: 14px;
	line-height: 1.75em;
	.parse-text {
		color: #ff0000;
	}
}
.area-example {
	font-size: 14px;
	font-weight: 400;
	color: #333;
}
.word-shorthand {
	height: 100%;
	display: flex;

	.area-example {
		margin-right: 0.3rem;
		font-size: 0.28rem;
		font-weight: 400;
		color: #333;
	}

	.area-box {
		width: 100%;
		height: 100%;
		border: 1px solid #dcdcdc;
		border-radius: 5px;
		padding: 15px 0 15px 15px;
		.moudle-img {
			width: 100%;
			height: 240px;
			background: #fff;
			margin-bottom: 15px;
		}
	}
	.btns-area {
		width: 4.92rem;
		height: 100%;
		border: 1px solid #dcdcdc;
		border-radius: 0.1rem;
		margin-left: 0.3rem;
		padding: 0 0.55rem;
		user-select: none;
		.btn {
			width: 100%;
			height: 0.64rem;
			border-radius: 0.32rem;
			text-align: center;
			line-height: 0.64rem;
			font-size: 0.24rem;
			font-weight: 500;
			color: #fff;
			margin-bottom: 0.24rem;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
		.yizhangwo {
			background: #1180db;
		}
		.burenshi {
			background: #ff6363;
		}
		.change {
			background: #27b295;
		}
		.view-all {
			background: #009b7c;
		}
	}
}
.audio-box {
	width: 0;
	height: 0;
	overflow: hidden;
}
.keyboard {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	background: #fff;
	box-shadow: 0 0 5px #ccc;
	.letter {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		background: #dcdcdc;
		padding: 8px 0;
		li {
			text-align: center;
			line-height: 36px;
			font-size: 24px;
			width: 36px;
			background: #fff;
			margin: 5px;
			border-radius: 4px;
			color: #323233;
			&:active {
				box-shadow: 0 0 5px #ccc;
				background: #27b295;
				color: #fff;
			}
		}
	}
}
</style>
