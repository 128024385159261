<template>
<div class="yufajingjiang-page">
<navBar @goBack="goBack">
<span class="title">写作指导</span>
 
</navBar>
<textImgContent></textImgContent>
</div>
</template>

<script>
export default {
name:'xieZuoZhiDao',
components:{
textImgContent:()=>import('@/views/channel/components/textImgContent.vue')
},
data(){
return{
currentTitle:this.$route.query.channelName
}
},
created(){},
methods:{
goBack() {
let query = this.$route.query;
delete query.channelId;
delete query.channelName;
this.$router.push({
path: `/${this.$route.query.from}`,
query: {
...query,
from: "zhiShiQuanJie",
},
});
},
}
}
</script>

<style lang="scss" scoped>
.yufajingjiang-page{
width: 100%;
height: 100%;
padding-top: 1.08rem;
}
</style>