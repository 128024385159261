<template>
	<div class="question-content">
		<loading-box v-if="isLoading"></loading-box>
		<template v-else>
			<template v-if="questionData.length">
				<div class="proress">
					<div
						class="con"
						:style="`width:${(questionData[currentQuestion].questionIndex / questionNumber) * 100}%`"
					></div>
				</div>

				<div class="acount-num-time">
					<div class="num">
						<span class="large">{{ questionData[currentQuestion].questionIndex }}</span>
						<span class="small">/{{ questionNumber }}</span>
					</div>
					<div class="time">
						<span class="tit">已用时间：</span>
						<span v-for="(tiem, index) in timeString.split(':')" :key="index">
							<span class="itme-item">{{ tiem }}</span>
							<i>{{ index != timeString.split(':').length - 1 ? ':' : '' }}</i>
						</span>
					</div>
				</div>

				<div class="test-box" ref="testBox" id="testBox">
					<div class="qusetion-body" v-if="questionData[currentQuestion]">
						<div
							class="qcontent"
							style="font-size: 15px; font-weight: 500; margin-bottom: 25px"
							v-html="questionData[currentQuestion].content"
							v-if="questionData[currentQuestion].content"
						></div>
						<div class="question-area" :class="{ show: showQuestion, hide: !showQuestion }">
							<div class="question-mean" v-html="questionData[currentQuestion].stem"></div>
							<div class="answer-area">
								<ul class="options" id="options" ref="options">
									<li
										v-for="(opt, index) in questionData[currentQuestion].option"
										:key="index"
										:class="{
											active: questionData[currentQuestion].userAnswer.includes(index)
										}"
										@click="selectAction(index, questionData[currentQuestion].classtext)"
									>
										<span v-html="opt.label"></span>
									</li>
								</ul>
								<div v-if="2 < questionData[currentQuestion].classtext" class="answer-box">
									<van-field
										v-model="questionData[currentQuestion].userAnswer"
										type="textarea"
										rows="3"
										placeholder="请输入您的答案"
										:disabled="!startAnswer"
									></van-field>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="question-handle">
					<div class="btn start-btn" v-if="!startAnswer" @click="mainStartAction">
						{{ submited ? '继续答题' : '开始答题' }}
					</div>
					<template v-else>
						<div class="btn" @click="changeQuestion(1)">上一题</div>
						<div class="btn" @click="changeQuestion(2)">下一题</div>
						<div class="btn jiexi-btn" @click="submitAction">查看解析</div>
					</template>
				</div>
			</template>
			<coustm-empty v-else></coustm-empty>
		</template>
	</div>
</template>

<script>
import { deepClone, getNowTime, html2string, formatMathJaxImg, formatAddPointWords } from '@/utils/public';
import coustmEmpty from '@/components/coustmEmpty.vue';
import LoadingBox from '@/components/loadingBox.vue';
let timmer = null;
export default {
	components: { coustmEmpty, LoadingBox },
	name: 'questionContent',
	computed: {
		currentScore: function () {
			return this.questionData[this.currentQuestion]?.score || '*';
		}
	},
	data() {
		return {
			startAnswer: false,
			submited: false,
			startAudio: null,
			isLoading: false,
			isEmpty: false,
			showStartLayer: true,
			showResult: false,
			showScode: false,
			isSubmit: false,
			isViewAll: false,
			showQuestion: false,
			timeString: '00:00:00',
			startTime: '',
			questionSourse: [],
			questionData: [],
			currentQuestion: 0,
			totalScore: 0,

			answerProperNum: 0,
			zhuGuanNum: 0,
			keGuanNum: 0,
			customScore: 0,
			finalScore: 0,
			testTimes: 1,
			userScore: 0,
			questionNumber: 0
		};
	},
	created() {
		this.initData();
		this.addHistory();
	},
	mounted() {
		this.startAudio = this.$refs['startAudio'];
	},
	methods: {
		deepClone,
		getNowTime,
		html2string,
		formatMathJaxImg,
		formatAddPointWords,
		setMathJaxText() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
			setTimeout(() => {
				this.showQuestion = true;
			}, 200);
		},
		changeScore(val) {
			console.log(val);
			let question = this.questionData[this.currentQuestion];
			question.userScore = val;
			this.$set(this.questionData, this.currentQuestion, question);
		},
		repeatAction() {
			this.isSubmit = false;
			this.isStart = true;
			this.showScode = false;
			this.isViewAll = false;
			this.timeString = '00:00:00';
			this.currentQuestion = 0;
			this.zhuGuanNum = 0;
			this.keGuanNum = 0;
			this.answerProperNum = 0;
			this.customScore = 0;
			this.finalScore = 0;
			this.countTime();
			this.setParse(false, true);
			this.testTimes = ++this.testTimes;
			this.startTime = this.getNowTime();
		},
		setParse(handle, isRepeat = false) {
			if (isRepeat) {
				this.questionData = this.deepClone(this.questionSourse);
			} else {
				this.questionData.forEach((item) => {
					item.showAnalysis = handle;
					item.showAnalysis = handle;
				});
			}
		},

		submitAction() {
			clearInterval(timmer);
			this.startAnswer = false;
			this.submited = true;

			this.computeStatus();
			this.$router.push({
				path: '/questionResult',
				query: {
					...this.$route.query,
					from: this.$route.path
				}
			});
		},
		computeStatus() {
			let n = 0;
			let arr = [];
			let list = this.questionData;
			for (let j = 0; j < list.length; j++) {
				let answerVal = list[j].userAnswer;
				let rightkey = this.html2string(list[j].answer);
				let type = list[j].classtext;

				if (answerVal.length) {
					if (type < 3) {
						answerVal = list[j].userAnswerTag;
						if (rightkey == answerVal) {
							arr.push({
								status: 1,
								num: ++n,
								answerVal: answerVal
							});
						} else {
							arr.push({
								status: 2,
								num: ++n,
								answerVal: answerVal
							});
						}
					} else {
						arr.push({
							status: 3,
							num: ++n,
							answerVal: answerVal
						});
					}
				} else {
					arr.push({
						status: 0,
						num: ++n,
						answerVal: ''
					});
				}
			}
			console.log(arr, 'asdasddddddddddd');

			this.$store.commit('common/setAnswerStatus', arr);
			this.answerStatus = arr;
		},
		mainStartAction() {
			this.startAnswer = true;
			this.countTime();
			this.startTime = this.getNowTime();
		},

		countTime() {
			// 答题计时
			clearInterval(timmer);
			let time = 0;
			timmer = setInterval(() => {
				time++;
				let hour = parseInt(time / 3600);
				let minute = parseInt((time - hour * 3600) / 60);
				let second = parseInt(time - hour * 3600 - minute * 60);

				hour = hour < 10 ? `0${hour}` : hour;
				minute = minute < 10 ? `0${minute}` : minute;
				second = second < 10 ? `0${second}` : second;
				this.timeString = `${hour}:${minute}:${second}`;
			}, 1000);
		},

		initData() {
			this.isLoading = true;
			this.$api
				.getMenuQuestion({
					course_id:
						this.$route.query.childId ||
						this.$route.query.channelId ||
						this.$route.query.classId ||
						this.$route.query.unitId
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						let resetData = resData[0];
						let children = resetData.children;
						for (let i = 0; i < children.length; i++) {
							children[i].questionIndex = i + 1;
						}
						resetData.children = children;
						resetData = this.resetQuestion(resData[0]);
						if (resetData.length) {
							resetData.forEach((item) => {
								item.option = this.computedOptions(item.option);
								if (item.classtext < 3) {
									item.userAnswer = [];
								} else {
									item.userAnswer = '';
								}
								item.userAnswerTag = '';
								item.content = this.formatMathJaxImg(item.content);
								item.stem = this.formatMathJaxImg(item.stem);
								item.answer = this.formatMathJaxImg(item.answer);
								item.analysis = this.formatMathJaxImg(item.analysis);

								item.content = this.formatAddPointWords(item.content);
								item.stem = this.formatAddPointWords(item.stem);
								item.answer = this.formatAddPointWords(item.answer);
								item.analysis = this.formatAddPointWords(item.analysis);
							});
							this.questionData = resetData;
							this.questionSourse = this.deepClone(resetData);
							this.totalScore = resData[0].score;
							this.$store.commit('common/setQuestionObject', this.questionSourse);
							setTimeout(() => {
								this.setMathJaxText();
							}, 10);
						} else {
							this.isEmpty = true;
						}
					} else {
						this.isEmpty = true;
					}
					this.isLoading = false;
				});
		},
		resetQuestion(arr) {
			let children = arr.children || [];
			this.questionNumber = children.length;
			for (let i = 0; i < children.length; i++) {
				if (children[i].children) {
					let arr = [];
					let parentIndex = children[i].questionIndex;
					for (let j = 0; j < children[i].children.length; j++) {
						let child = children[i].children[j];
						child.content = children[i].content || '';
						child.questionIndex = parentIndex;
						arr.push(child);
					}
					children.splice(i, 1, ...arr);
				}
			}

			return children;
		},

		formateUseTime(str) {
			let time = str.split(':');
			let result = '';
			let hour = parseInt(time[0]) > 0 ? `${parseInt(time[0])}小时` : '';
			let minutes = parseInt(time[1]) > 0 ? `${parseInt(time[1])}分` : '';
			let second = parseInt(time[2]) > 0 ? `${parseInt(time[2])}秒` : '';
			result = hour + minutes + second;
			return result;
		},

		computedOptions(str) {
			str = str || '';
			str = str.replace(/<ol>/g, '');
			str = str.replace(/<ul>/g, '');
			str = str.replace(/<\/ul>/g, '');
			str = str.replace(/<\/ol>/g, '');
			str = str.replace(/<li>/g, '');
			str = str.replace(/<\/li>/g, '');
			let arr = str.split('\n');
			let targetArr = [];
			for (let i = 0; i < arr.length; i++) {
				if (arr[i]) {
					console.log(this.formatAddPointWords(arr[i]),'this.formatAddPointWords(arr[i])')
					targetArr.push({
						label: this.formatMathJaxImg(this.formatAddPointWords(arr[i])),
						status: 0
					});
				}
			}
			return targetArr;
		},
		selectAction(index, type) {
			if (!this.startAnswer) {
				this.$notify({
					type: 'warning',
					message: '请先点击"开始答题"!'
				});
				return false;
			}
			let question = this.questionData[this.currentQuestion];
			let userAnswer = question.userAnswer || [];
			let optionsTag = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];

			let idxof = userAnswer.indexOf(index);
			if (type == 1) {
				userAnswer = [];
			}
			if (userAnswer.includes(index)) {
				userAnswer.splice(idxof, 1);
			} else {
				if (type == 1) {
					userAnswer = [index];
				}
				if (type == 2) {
					if (idxof < 0) {
						userAnswer.push(index);
					}
				}
			}
			question.userAnswer = userAnswer.sort();
			let _userAnswerTag = '';
			for (let i = 0; i < question.userAnswer.length; i++) {
				_userAnswerTag = _userAnswerTag + optionsTag[question.userAnswer[i]];
			}

			question.userAnswerTag = _userAnswerTag;
			this.$set(this.questionData, this.currentQuestion, question);
			this.$store.commit('common/setQuestionObject', this.questionData);
			// console.log(this.questionData, 'asdasd')
		},

		changeQuestion(n) {
			let that = this;
			if (n == 1) {
				if (0 < this.currentQuestion) {
					this.currentQuestion = --this.currentQuestion;
					this.showQuestion = false;
				} else {
					this.$notify({
						type: 'warning',
						message: '已经是第一道题了'
					});
				}
			}
			if (n == 2) {
				if (this.currentQuestion < this.questionData.length - 1) {
					this.currentQuestion = ++this.currentQuestion;
					this.showQuestion = false;
				} else {
					// if (!that.isSubmit) {
					// 	this.$dialog
					// 		.confirm({
					// 			message: '已是最后一个，再做一次？'
					// 		})
					// 		.then(() => {
					// 			that.currentQuestion = 0;
					// 			that.questionData = that.deepClone(that.questionSourse);
					// 			that.repeatAction();
					// 			setTimeout(() => {
					// 				that.setMathJaxText();
					// 			}, 10);
					// 		});
					// } else {
						this.$notify({
							type: 'warning',
							message: '已经是最后一道题了'
						});
						return false;
					// }
				}
			}
			setTimeout(() => {
				this.setMathJaxText();
			}, 10);
			this.userScore = this.questionData[this.currentQuestion].userScore;
			// let testBox = window.document.getElementById("testBox");
			// testBox.scrollTo({
			// top: 0,
			// behavior: "auto",
			// });
			this.checkErrorToAdd();
		},
		checkErrorToAdd() {
			let activeQuestion = this.questionData[this.currentQuestion];
			let classText = activeQuestion.classtext;
			if (classText < 3) {
				let answer = this.html2string(activeQuestion.answer);
				let userAnswerTag = activeQuestion.userAnswerTag;
				if (answer != userAnswerTag) {
					let currentErrorId = activeQuestion.id;
					this.$api
						.addErrorQuestion({
							subject_id: this.$route.query.subjectId,
							questionbank_id: currentErrorId
						})
						.then(() => {
							//console.log(res);
						});
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.options {
	line-height: 1.6 !important;
	font-size: 0.3rem !important;
	font-family: initial !important;
	li {
		position: relative;
		padding: 15px 15px 15px 40px;
		margin: 15px 0;
		cursor: default;
		background: #f8f8f8;
		border-radius: 5px;
		&::after {
			position: absolute;
			left: 15px;
			top: 50%;
			transform: translateY(-50%);
			padding-right: 5px;
		}
		&::before {
			position: absolute;
			left: 30px;
			top: 50%;
			transform: translateY(-50%);
			padding-right: 5px;
			content: '';
			height: 10px;
			border-left: 1px #ccc solid;
		}
		&:nth-child(1)::after {
			content: 'A';
		}
		&:nth-child(2)::after {
			content: 'B';
		}
		&:nth-child(3)::after {
			content: 'C';
		}
		&:nth-child(4)::after {
			content: 'D';
		}
		&:nth-child(5)::after {
			content: 'E';
		}
		&:nth-child(6)::after {
			content: 'F';
		}
		&:nth-child(7)::after {
			content: 'G';
		}
		&:nth-child(8)::after {
			content: 'H';
		}
		&:nth-child(9)::after {
			content: 'I';
		}
		&:nth-child(10)::after {
			content: 'J';
		}
	}
	.active {
		background: #e2f5f1;
	}
}
.answer-box {
	border: 1px #969799 solid;
	border-radius: 0.1rem;
	overflow: hidden;
}
.question-mean {
	margin-bottom: 0.3rem;
	font-family: initial !important;
	font-size: 0.3rem  !important;
	line-height: 1.75em;
}
.qcontent {
	margin-bottom:0.3rem;
	font-family: initial !important;
	font-size: 0.3rem !important;
	line-height: 1.75em;
}
.question-handle {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.24rem;
	background: #fff;
	.btn {
		width: 2.1rem;
		height: 0.68rem;
		border: 1px solid #28b295;
		border-radius: 0.34rem;
		text-align: center;
		line-height: 0.68rem;
		font-size: 0.24rem;
		font-weight: 400;
		color: #333;
		background: #fff;
		&:active {
			background: rgba(40, 178, 149, 0.1);
		}
		&.disable {
			border-color: #e1e1e1;
			background: #f1f1f1;
		}
	}
	.jiexi-btn {
		background: #28b295;
		color: #fff;
	}
	.start-btn {
		width: 100%;
		background: #28b295;
		color: #fff;
	}
}
.test-box {
	position: fixed;
	left: 0;
	right: 0;
	top: 120px;
	bottom: 63px;
	font-size: 13px;
	color: #333;
	padding: 14px;
	background: #fff;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
	transition: all 0 0.3s ease-in-out;
}

.question-area.show {
	opacity: 1;
}
.question-area.hide {
	opacity: 0;
}
.acount-num-time {
	position: fixed;
	left: 0;
	right: 0;
	top: 1.28rem;
	z-index: 3;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.3rem 0.24rem;
	background: #fff;
	.time {
		display: flex;
		align-items: center;
		color: #ff1e1e;
		font-size: 0.24rem;
		font-weight: 400;
		.tit {
			color: #333;
		}
		.itme-item {
			display: inline-block;
			text-align: center;
			line-height: 0.46rem;
			width: 0.48rem;
			height: 0.46rem;
			background: #36ba9e;
			border-radius: 0.04rem;
			color: #fff;
			font-size: 0.24rem;
		}
		i {
			font-style: normal;
			padding: 0 0.1rem;
			color: #36ba9e;
		}
	}
	.num {
		display: flex;
		align-items: flex-end;
		margin-right: 0.3rem;
		font-size: 0.28rem;
		font-weight: 400;
		color: #969799;
		.large {
			color: #28b295;
			font-size: 0.42rem;
			transform: translateY(0.06rem);
		}
	}
}
.question-content {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
	background: #fff;
	::v-deep img {
		height: auto !important;
	}
	.contant-main {
		width: 100%;
		height: 100%;
	}
}
.start-layer {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1000;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	.start-main {
		position: relative;
		width: 4.56rem;
		height: 5.44rem;
		background-size: cover;
		.result-detail {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-bottom: 0.5rem;
			.total-scode {
				width: 100%;
				text-align: center;
				font-size: 0.26rem;
				color: #ff0000;
				font-style: italic;
				margin-bottom: 0.5rem;
				span {
					font-size: 0.5rem;
					padding-right: 0.05rem;
				}
			}
			.detail-item {
				width: 100%;
				margin-bottom: 0.2rem;
				font-size: 0.22rem;
				font-weight: 400;
				padding: 0 0.6rem;
			}
		}
		.again-btn {
			position: absolute;
			right: 0.67rem;
			top: 0.52rem;
			font-size: 0.2rem;
			font-weight: 400;
			color: #ff8d31;
			cursor: pointer;
			user-select: none;
		}
		.start-btn {
			position: absolute;
			left: 50%;
			bottom: 0.71rem;
			transform: translateX(-50%);
			width: 2.47rem;
			height: 0.68rem;
			background: #ffa632;
			box-shadow: 0rem 0.02rem 0.09rem 0rem rgba(234, 148, 36, 0.44);
			border-radius: 0.34rem;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 0.28rem;
			font-weight: 500;
			color: #fff;
			transition: all 0.15s ease-in-out;
			cursor: pointer;
			&:hover {
				box-shadow: 0 0 0.05rem 0.05rem rgba(234, 148, 36, 0.44);
			}
		}
		.text {
			text-align: center;
			padding-top: 0.8rem;
			color: #1e9e85;
			line-height: 1;
			.s {
				font-size: 0.26rem;
				margin-bottom: 0.2rem;
			}
			.l {
				font-size: 0.28rem;
			}
		}
	}
}
.result-layer {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	.result-box {
		position: relative;
		width: 6.61rem;
		height: 5.72rem;
		background-repeat: no-repeat;
		background-size: cover;
		.result-main {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			width: 100%;
			height: 100%;
			padding: 0.25rem 0.35rem;
			.final-score {
				position: absolute;
				right: 0.33rem;
				bottom: 2.55rem;
				font-size: 0.24rem;
				font-weight: 400;
				color: #909090;
				span {
					font-weight: 500;
					color: #ff0000;
					font-size: 0.6rem;
				}
			}
			.result-btn {
				width: 100%;
				display: flex;
				padding-top: 0.32rem;
				cursor: pointer;
				user-select: none;
				.btn {
					flex: 1;
					height: 0.48rem;
					background: #1e9e85;
					border-radius: 0.24rem;
					text-align: center;
					line-height: 0.48rem;
					font-size: 0.24rem;
					color: #fff;
				}
			}
			.tit {
				font-size: 0.26rem;
				font-weight: 500;
				color: #333;
				margin-bottom: 0.5rem;
			}
			.item {
				font-size: 0.24rem;
				color: #333;
				font-weight: 300;
				margin-bottom: 0.28rem;
			}
		}
		.close-box {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			right: -0.2rem;
			top: 0.4rem;
			z-index: 1000;
			width: 0.64rem;
			height: 0.64rem;
			background: #ffffff;
			border-radius: 50%;
			.el-icon-close {
				color: #1e9e85;
				font-size: 0.36rem;
				font-weight: bold;
			}
		}
	}
}
</style>
