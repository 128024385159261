import http from "../http";

const user = {
  //发送验证码接口
  sendSms: function (params) {
    return http.post("/api/v2/captcha/sms", params);
  },
  //用户注册接口
  userSigin: function (params) {
    return http.post("/api/v2/register", params);
  },
  //用户密码登录接口
  userLoginPassword: function (params) {
    return http.post("/api/v2/login/password", params);
  },
  //用户手机登录接口
  userLoginPhone: function (params) {
    return http.post("/api/v2/login/mobile", params);
  },
  //获取用户基本信息接口
  userBaseInfos: function (params) {
    return http.get("/api/v2/member/detail", params);
  },
  //个人信息添加修改
  uploadUserInfos: function (params) {
    return http.post("/api/v2/member/profile", params);
  },
  //用户退出接口
  userLogout: function (params) {
    return http.post("/api/v2/logout", params);
  },
  //用户激活接口
  userActivation: function (params) {
    return http.post("/api/v2/action", params);
  },

  //用户绑定手机校验接口
  checkPhone: function (params) {
    return http.post("/api/v2/member/verify", params);
  },

  //获取版本年级
  versionGrade: function (params) {
    return http.get("/api/v2/getgrade", params);
  },

  //获取选择的出版社
  selectPress: function (params) {
    return http.get("/api/v2/pressuser", params);
  },

  //获取出版社
  pressList: function (params) {
    return http.get("/api/v2/press", params);
  },

  //保存科目版本
  saveCouresPress: function (params) {
    return http.post("/api/v2/pressuser/addpress", params);
  },

  //获取学习历史记录
  getLearnHistory: function (params) {
    return http.get("/api/v2/learningrecord", params);
  },
  //获取录音记录
  getRecordList: function (params) {
    return http.get("/api/v2/member/recording/all", params);
  },

  //修改密码
  changePassword: function (params) {
    return http.post("/api/v2/member/detail/uppassword", params);
  },

  //添加学习记录
  addHistory: function (params) {
    return http.post("/api/v2/learningrecord/add", params);
  },
  
  //删除录音记录
  deleteRecord: function (params) {
    return http.post("/api/v2/member/recording/deleted", params);
  },

  //校验验证码
  checkSms: function (params) {
    return http.post("/api/v2/member/verify", params);
  },
  //修改个人信息
  changeUserInfs: function (params) {
    return http.post("/api/v2/member/profile", params);
  },
  
  //获取错题记录
  getErrorBookList: function (params) {
    return http.get("/api/v2/member/wrongtopic", params);
  },

  //添加录音
  addRecorde: function (params) {
    return http.post("/api/v2/member/recording/add", params);
  },

  //添加错题本
  addErrorQuestion: function (params) {
    return http.post("/api/v2/member/addquestionbank", params);
  },
  //删除错题本
  deleteErrorQuestion: function (params) {
    return http.get("/api/v2/member/deletewrongtopic", params);
  },

  //获取错题本基本信息
  baseErrorQuestion: function (params) {
    return http.get("/api/v2/member/get_wrongtopic_info", params);
  },

  
 
  
};

export default user;
