<template>
	<div class="moudle-page-box">
		<navBar @goBack="goBack">
			<span class="title">编辑个人信息</span>
		</navBar>
		<div class="page-box hasnav notab haspadding">
			<div class="infs-from complate-infs">
				<div class="infs-item" style="position: relative">
					<div class="item-label">
						<span class="label">头像：</span>
					</div>
					<div class="item-input">
						<van-image fit="cover" class="avator" :src="setSourceUrl(userInfo.avatar)"></van-image>
						<input
							type="file"
							class="upload-avator"
							ref="avatorUpload"
							accept="image/*"
							@change="selectAvator"
						/>
						<div class="mask" v-if="!isEdit" @click="changeAvatar"></div>
					</div>
				</div>
				<!-- <div class="infs-item">
<div class="item-label">
<span class="label">用户名：</span>
</div>
<div class="item-input">{{ userInfo.username }}</div>
</div> -->
				<template v-for="(item, tag) in formData">
					<div class="infs-item" v-if="item.show" :key="tag">
						<div class="item-label">
							<span class="require" v-if="item.require && isEdit">*</span>
							<span class="label">{{ item.label }}：</span>
						</div>
						<div class="item-input" v-if="item.type == 0">
							<input
								v-model="item.data"
								:readonly="!isEdit"
								:placeholder="item.placeholder"
								:maxlength="item.maxlength"
								:type="item.inputType || 'text'"
							/>
						</div>
						<div class="item-input" v-if="item.type == 1">
							<span
								@click="selectGender(1)"
								class="gender"
								:class="{ 'gender-select-boy': item.data == 1 }"
							>
								<i class="iconfont icon-xingbienan"></i>
								男生
							</span>
							<span
								@click="selectGender(2)"
								class="gender"
								:class="{ 'gender-select-girl': item.data == 2 }"
							>
								<i class="iconfont icon-xingbienv"></i>
								女生
							</span>
						</div>
						<div class="item-input" v-if="item.type == 2">
							<input
								type="text"
								v-model="item.data"
								:placeholder="item.placeholder"
								:maxlength="item.maxlength"
							/>
							<van-icon name="arrow" class="arrow" />
							<div class="mask" @click="pickerAction(tag)"></div>
						</div>
						<div class="item-input" v-if="item.type == 3">
							<input
								type="text"
								v-model="item.data"
								:placeholder="item.placeholder"
								:maxlength="item.maxlength"
								style="padding-right: 105px"
							/>
							<div class="send-code" :class="{ disable: isSend }" @click="sendCodeAction">
								{{ isSend ? sendText : '发送验证码' }}
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="enter-learn" @click="submitAction" v-if="isEdit">保存</div>
			<div class="enter-learn" v-else @click="isEdit = true">编辑</div>

			<div class="area-picker" v-if="showPicker" @click="showPicker = false">
				<div class="picker-main">
					<div class="picker-header">
						<div class="btn cancle" @click="showPicker = false">取消</div>
						<div class="tit">{{ computeTitle() }}</div>
						<div class="btn confirm" @click="confirmAction(currentTag)">确认</div>
					</div>
					<div class="picker-body">
						<ul class="picker-data" v-if="pickerData.length">
							<li
								v-for="(item, index) in pickerData"
								:class="{ active: selectData.label == item.label }"
								:key="index"
								@click.stop="selectPicker(index, item)"
							>
								{{ item.label }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import adminRegions from '@/utils/city.json';
import { setSourceUrl } from '@/utils/public';
let timer = null;
export default {
	name: 'complateInfs',
	watch: {
		isEdit: function (val) {
			this.$set(this.formData.code, 'show', val);
		}
	},
	data() {
		return {
			isEdit: false,
			isSend: false,
			sendText: '60s后重新发送',
			showPicker: false,
			userInfo: {
				avator: '',
				name: ''
			},
			formData: {
				name: {
					show: true,
					label: '姓名',
					require: true,
					data: '',
					type: 0,
					placeholder: '请输入学生姓名',
					maxlength: 6
				},
				gender: {
					show: true,
					label: '性别',
					require: true,
					data: '',
					type: 1,
					maxlength: 2
				},
				nick_name: {
					show: true,
					label: '昵称',
					require: true,
					data: '',
					type: 0,
					placeholder: '请输入学生昵称',
					maxlength: 10
				},
				province: {
					show: true,
					label: '省份',
					require: true,
					data: '',
					type: 2,
					placeholder: '请选择所在省份'
				},
				city: {
					show: true,
					label: '城市',
					require: true,
					data: '',
					type: 2,
					placeholder: '请选择所在城市'
				},
				area: {
					show: true,
					label: '所在区(县)',
					require: true,
					data: '',
					type: 2,
					placeholder: '请选择所在区(县)'
				},
				school: {
					show: true,
					label: '学校名称',
					require: true,
					data: '',
					type: 0,
					placeholder: '请输入学校名称',
					maxlength: 15
				},
				grade: {
					show: true,
					label: '所在年级',
					require: true,
					data: '',
					type: 2,
					placeholder: '请选择所在年级'
				},
				register_mobile: {
					show: true,
					label: '家长电话号码',
					require: true,
					data: '',
					type: 0,
					placeholder: '请输入家长电话号码',
					maxlength: 11,
					inputType: 'number'
				},
				code: {
					show: false,
					label: '验证码',
					require: true,
					data: '',
					type: 3,
					placeholder: '请输入验证码',
					maxlength: 6,
					inputType: 'number'
				}
			},
			currentTag: '',
			selectData: {},
			pickerData: [],
			cityData: [],
			areaData: [],
			gradeData: []
		};
	},
	created() {
		this.initData();
	},
	mounted() {},
	methods: {
		setSourceUrl,
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					active: this.$route.query.active
				}
			});
		},
		sendCodeAction() {
			if (this.regTel(this.formData.register_mobile.data)) {
				if (!this.isSend) {
					this.sendCodeFun();
				}
			}
		},
		sendCodeFun() {
			this.$api
				.sendSms({
					register_mobile: this.formData.register_mobile.data,
					scene: 'mobile_bind'
				})
				.then((res) => {
					if (res.code == 0) {
						this.$notify({
							type: 'success',
							message: res.message
						});
						this.isSend = true;
						this.sendText = `60s后重新发送`;
						clearInterval(timer);
						this.computeTime();
					} else {
						this.$notify({
							type: 'danger',
							message: res.message
						});
					}
				});
		},
		initData() {
			this.$api.userBaseInfos().then((res) => {
				let resData = res.data || {};
				let area = res.data.register_area || '';
				area = area.split('/');
				//console.log(area);
				resData.province = area[0];
				resData.city = area[1];
				resData.area = area[2];
				this.userInfo = {
					...resData
				};
				for (let i in resData) {
					let val = resData[i];
					if (this.formData[i]) {
						this.$set(this.formData[i], 'data', val);
					}
				}
			});

			this.$api.versionGrade().then((res) => {
				let resData = res.data || [];
				let gradeData = [];
				for (let i = 0; i < resData.length; i++) {
					gradeData.push({
						label: resData[i],
						value: i
					});
				}
				this.gradeData = gradeData;
			});
		},
		selectGender(val) {
			if (!this.isEdit) {
				this.$notify({
					message: '请先点击下方的“编辑”按钮！'
				});
				return false;
			}
			this.$set(this.formData.gender, 'data', val);
		},
		changeAvatar() {
			if (!this.isEdit) {
				this.$notify({
					message: '请先点击下方的“编辑”按钮！'
				});
				return false;
			}
		},
		selectAvator() {
			let file = this.$refs['avatorUpload'].files[0];
			let formData = new FormData();
			formData.append('file', file);
			this.$api.uploadFile(formData).then((res) => {
				if (res.data) {
					this.userInfo.avatar = res.data.path;
				} else {
					this.$notify({
						type: 'fail',
						message: res.message
					});
				}
			});
		},
		submitAction() {
			let params = {};
			let hanndle = true;
			for (let i in this.formData) {
				if (this.formData[i].require && !this.formData[i].data) {
					hanndle = false;
					this.$notify({
						message: `请${this.formData[i].type == 0 || this.formData[i].type == 3 ? '输入' : '选择'}${
							this.formData[i].label
						}`
					});
					break;
				} else {
					if (i == 'mobile' && !this.regTel(this.formData.mobile.data)) {
						hanndle = false;
						break;
					}
					params[i] = this.formData[i].data;
				}
			}
			params.avatar = this.userInfo.avatar;
			if (hanndle) {
				this.$toast({
					message: '保存中...',
					type: 'loading'
				});
				this.$api
					.checkSms({
						register_mobile: this.formData.register_mobile.data,
						register_mobile_code: this.formData.code.data
					})
					.then((res) => {
						if (!['4000', '4001', '4002'].includes(res.code)) {
							params.register_area = `${this.userInfo.province}/${this.userInfo.city}/${this.userInfo.area}`;
							this.$api.changeUserInfs(params).then((res) => {
								window.sessionStorage.setItem('changeUserInfos', true);
								this.$notify({
									type: 'success',
									message: res.message
								});
							});
						} else {
							this.$notify({
								message: res.message
							});
						}
					});
			}
		},

		regTel(str) {
			let reg = /(13\d|14[579]|15[^4\D]|17[^49\D]|18\d)\d{8}/;
			if (!reg.test(str)) {
				this.$notify({
					message: '请输入正确格式的手机号码！'
				});
				return false;
			} else {
				return true;
			}
		},
		computeTime() {
			let num = 60;
			timer = setInterval(() => {
				if (1 < num) {
					this.sendText = `${--num}s后重新发送`;
				} else {
					clearInterval(timer);
					this.isSend = false;
				}
			}, 1000);
		},
		confirmAction(tag) {
			this.$set(this.formData[tag], 'data', this.selectData.label);
			if (this.currentTag == 'province') {
				this.$set(this.formData.city, 'data', '');
				this.$set(this.formData.area, 'data', '');
			}
			if (this.currentTag == 'city') {
				this.$set(this.formData.area, 'data', '');
			}
		},
		selectPicker(index, item) {
			if (this.currentTag == 'province') {
				this.cityData = adminRegions[index].children;
			}
			if (this.currentTag == 'city') {
				this.areaData = this.cityData[index].children;
			}
			this.selectData = item;
		},
		computeTitle() {
			if (this.currentTag == 'province') {
				return '请选择省份';
			}
			if (this.currentTag == 'city') {
				return '请选择城市';
			}
			if (this.currentTag == 'area') {
				return '请选择区(县)';
			}
		},
		pickerAction(tag) {
			if (!this.isEdit) {
				this.$notify({
					message: '请先点击下方的“编辑”按钮！'
				});
				return false;
			}
			if (tag == 'province') {
				this.pickerData = adminRegions;
			}
			if (tag == 'city') {
				if (!this.formData.province.data) {
					this.$notify({
						message: '请先选择省份！'
					});
					return false;
				}
				this.pickerData = this.cityData;
			}
			if (tag == 'area') {
				if (!this.formData.province.data) {
					this.$notify({
						message: '请先选择省份！'
					});
					return false;
				}
				if (!this.formData.city.data) {
					this.$notify({
						message: '请先选择城市！'
					});
					return false;
				}
				this.pickerData = this.areaData;
			}
			if (tag == 'grade') {
				this.pickerData = this.gradeData;
			}
			this.currentTag = tag;
			this.showPicker = true;
		}
	}
};
</script>

<style lang="scss" scoped>
.area-picker {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1000;
	display: flex;
	align-items: flex-end;
	background: rgba(0, 0, 0, 0.3);
	.picker-main {
		width: 100%;
		height: 50vh;
		background: #fff;
		border-radius: 20px 20px 0 0;
		overflow: hidden;
		animation: showPicker 0.2s ease-in-out;
		@keyframes showPicker {
			0% {
				opacity: 0.5;
				transform: translateY(50%);
			}
			100% {
				opacity: 1;
				transform: translateY(0);
			}
		}
		.picker-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 15px;
			height: 50px;
			font-size: 14px;
			color: #333;
			font-weight: 400;
			box-shadow: 0 0 5px #ccc;
			.cancle {
				color: #ccc;
			}
			.confirm {
				color: #2db79a;
			}
		}
		.picker-body {
			width: 100%;
			height: calc(50vh - 50px);
			overflow-y: auto;
		}
		.picker-data {
			width: 100%;
			min-height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			li {
				width: 100%;
				padding: 0 20px;
				height: 40px;

				color: #333;
				line-height: 40px;
				font-weight: 400;
				border-bottom: 1px #f1f1f1 solid;
				&:last-child {
					border: 0;
				}
			}
			.active {
				background: #2db79a;
				color: #fff;
			}
		}
	}
}
.enter-learn {
	margin: 50px 15px;
	height: 50px;
	background: #2db79a;
	border-radius: 49px;
	text-align: center;
	line-height: 50px;
	font-size: 16px;
	font-weight: 500;
	color: #fff;
	&:active {
		opacity: 0.8;
	}
}
.complate-infs {
	.infs-from {
		border-top: 1px #dcdcdc solid;
		padding: 15px;
	}
	.infs-item {
		display: flex;
		align-items: center;
		height: 50px;
		border-bottom: 1px #dcdcdc solid;
		.item-label {
			display: flex;
			align-items: center;
			font-size: 14px;
			color: #333;
			font-weight: 400;
			word-break: break-all;
			min-width: 125px;
			.require {
				color: #ff3838;
				padding-right: 10px;
			}
		}
		.item-input {
			position: relative;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			height: 100%;
			padding: 05px;
			overflow: hidden;
			font-size: 14px;
			.avator {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				overflow: hidden;
			}
			.upload-avator {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 2;
				opacity: 0;
			}
			.mask {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				z-index: 2;
			}
			input {
				margin: 0;
				border: 0;
				width: 100%;
				height: 50px;
				font-size: 14px;
				text-align: right;
			}
			.arrow {
				position: relative;
				z-index: 2;
				font-size: 14px;
				color: #909090;
				margin-right: -5px;
			}
			.gender {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 59px;
				height: 24px;
				background: #ededed;
				border-radius: 12px;

				color: #909090;
				font-weight: 400;
				margin-left: 6px;
				&-select {
					&-boy {
						color: #fff;
						background: #4892ff;
					}
					&-girl {
						color: #fff;
						background: #ff554c;
					}
				}
			}
			.send-code {
				position: absolute;
				right: 0;
				top: 50%;
				z-index: 2;
				width: 100px;
				height: 28px;
				background: #2db79a;
				border-radius: 14px;
				transform: translateY(-50%);
				font-size: 13px;
				color: #fff;
				text-align: center;
				line-height: 28px;
				&:active {
					opacity: 0.8;
				}
			}
			.disable {
				background: #dcdcdc;
			}
		}
	}
}
</style>
