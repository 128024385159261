<template>
	<div class="moudle-page-box">
		<navBar @goBack="goBack">
			<span class="title">
				{{ currentTitle }}
			</span>
		</navBar>
	<div class="page-box hasnav notab">
		
		<loading-box v-if="isLoading"></loading-box>
		<ul class="list-box" v-else>
			<li v-for="(item, index) in dataList" :key="index" @click="goDetail(item.id, item.title)">
				<van-image fit="cover" class="book-img" :src="setSourceUrl(item.image)"></van-image>
				<div class="book-infs">
					<div class="book-name">{{ item.title }}</div>
					<div class="book-author-view">
						<div class="book-author">{{ item.author }}</div>
						<div class="book-view">
							<i class="iconfont icon-view"></i>
							<span>{{ item.num || 0 }}人阅读</span>
						</div>
					</div>
					<!-- <div class="book-des" v-html="item.des"></div> -->
				</div>
			</li>
		</ul>
	</div>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import loadingBox from '@/components/loadingBox.vue';
export default {
	components: { loadingBox },
	name: 'libraryList',
	data() {
		return {
			isLoading: true,
			dataList: [],
			currentTitle: '',
			currentType: 0
		};
	},
	created() {
		this.currentTitle = this.$route.query.name;
		this.init();
	},
	mounted() {},
	methods: {
		setSourceUrl,
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					active: 1
				}
			});
		},
		init() {
			this.isLoading = true;
			this.$api
				.getLabraryBook({
					bookclass_id: this.$route.query.id,
					limit: 0
				})
				.then((res) => {
					let resData = res.data || [];
					this.dataList = resData;
					this.isLoading = false;
				});
		},
		goDetail(id, name) {
			this.$router.push({
				path: '/bookDetail',
				query: {
					id: id,
					name: name,
					from: 'libraryList',
					typeId: this.$route.query.id,
					typeName: this.$route.query.name
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.page {
	background: #fafafa;
}
.page-box{
	border-top: 0.2rem solid #f5f5f5;
	padding:  0.3rem;
}
.page-content {
	position: fixed;
	left: 0;
	right: 0;
	top: 1.28rem;
	bottom: 0;
	background: #fff;
	padding: 0.3rem 0;
	.scroll-box {
		height: calc(100vh - 1.28rem);
		padding-bottom: 0.3rem;
		overflow: hidden;
		overflow-y: auto;
	}
}
.empty-box {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
.list-box {
	
	li {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 0.3rem;
		.book-img {
			width: 1.88rem;
			height: 2.4rem;
			margin-right: 0.3rem;
		}
		.book-name {
			color: #333;
			font-weight: 500;
			font-size: 0.3rem;
			margin-bottom: 0.16rem;
		}
		.book-author-view {
			display: flex;
			align-items: center;
			margin-bottom: 0.4rem;
			.book-author {
				font-weight: 400;
				color: #909090;
				font-size: 0.24rem;
				margin-right: 0.35rem;
			}
			.book-view {
				display: flex;
				align-items: center;
				font-weight: 400;
				color: #909090;
				font-size: 0.24rem;
				span {
					font-weight: 400;
					color: #909090;
					font-size: 0.24rem;
				}
			}
		}
		.book-des {
			font-size: 0.24rem;
			p {
				font-size: 0.24rem !important;
			}
		}
	}
}
</style>
