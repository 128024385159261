<template>
	<div class="zhishiyaodian-main">
		<!-- <span
			style="color: #333; font-size: 18px"
			class="menu-icon iconfont icon-mulu"
			@click="activeMenu = !activeMenu"
		></span> -->
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<coustm-empty v-if="isEmpty"></coustm-empty>
			<template v-else>
				<ul class="children-nav" v-if="activeMenu" @click="activeMenu = false">
					<li
						v-for="(nav, index) in childrenNav"
						:key="`nav${index}`"
						:class="{ active: activeNav == index }"
						@click="changeChildNav(nav, index)"
					>
						{{ nav.name }}
					</li>
				</ul>
				<div class="children-content">
					<div class="content-area">
						<div class="area-con" v-html="dataGroup[activeNav].children[currentQuestion].content"></div>
					</div>
					<div class="content-area question-area">
						<div class="area-con" style="padding-bottom: 0.6rem">
							<div
								class="question-stem"
								v-html="dataGroup[activeNav].children[currentQuestion].stem"
							></div>
							<div
								class="sigin-choose"
								v-if="dataGroup[activeNav].children[currentQuestion].classtext < 3"
							>
								<ul class="options" id="options" ref="options">
									<li
										v-for="(opt, index) in dataGroup[activeNav].children[currentQuestion].option"
										:key="index"
										:class="{
											active: dataGroup[activeNav].children[currentQuestion].userAnswer.includes(
												index
											),
											//6.5修改
											error:
												dataGroup[activeNav].children[currentQuestion].userAnswer.includes(
													index
												) &&
												dataGroup[activeNav].children[currentQuestion].userAnswerTag !==
													textFlge()
										}"
										@click="
											selectAction(
												index,
												dataGroup[activeNav].children[currentQuestion].classtext
											)
										"
									>
										<span v-html="opt.label"></span>
									</li>
								</ul>
							</div>
							<div
								class="answer-area"
								v-if="dataGroup[activeNav].children[currentQuestion].classtext == 3"
							>
								<el-input
									placeholder="请输入您的答案"
									type="textarea"
									resize="none"
									:rows="5"
									v-model="dataGroup[activeNav].children[currentQuestion].userAnswer"
								></el-input>
							</div>

							<div
								class="analysis-area"
								v-if="dataGroup[activeNav].children[currentQuestion].showAnalysis"
							>
								<div class="analysis-item">
									<span class="tit">【正确答案】</span>
									<span v-html="dataGroup[activeNav].children[currentQuestion].answer"></span>
								</div>
								<div
									class="analysis-item"
									v-if="dataGroup[activeNav].children[currentQuestion].classtext < 3"
								>
									<span class="tit">【您的答案】</span>
									<span
										v-if="dataGroup[activeNav].children[currentQuestion].userAnswerTag"
										v-html="dataGroup[activeNav].children[currentQuestion].userAnswerTag"
									></span>
									<span v-else>暂未作答</span>
								</div>
								<div class="analysis-item" v-else>
									<span class="tit">【您的答案】</span>
									<span
										v-if="dataGroup[activeNav].children[currentQuestion].userAnswer"
										v-html="dataGroup[activeNav].children[currentQuestion].userAnswer"
									></span>
									<span v-else>暂未作答</span>
								</div>
								<div class="analysis-item">
									<span class="tit">【答案解析】</span>
									<span
										v-if="dataGroup[activeNav].children[currentQuestion].analysis"
										v-html="dataGroup[activeNav].children[currentQuestion].analysis"
									></span>
									<span v-else>略</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="opreate-area">
					<div class="btn-box">
						<div
							class="btn"
							:class="{ disable: currentQuestion == 0 }"
							@click="changeQuestion(1)"
							v-if="1 < dataGroup[activeNav].children.length"
						>
							上一题
						</div>
					</div>
					<div class="btn-box">
						<div
							class="btn"
							:class="{
								disable: currentQuestion == dataGroup[activeNav].children.length - 1
							}"
							@click="changeQuestion(2)"
							v-if="1 < dataGroup[activeNav].children.length"
						>
							下一题
						</div>
					</div>
					<div class="btn-box">
						<div class="btn jiexi-btn" @click="analysisAction">解析</div>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import coustmEmpty from '@/components/coustmEmpty.vue';
import { deepClone, formatMathJaxImg } from '@/utils/public';
export default {
	components: { coustmEmpty },
	name: 'zhiShiYaoDianCompinent',
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			activeMenu: false,
			childrenNav: [],
			dataGroup: [],
			questionSourse: [],
			activeNav: 0,
			currentQuestion: 0
		};
	},
	created() {
		this.getQuestion();
		this.addHistory();
	},
	activated() {
		this.getQuestion();
	},
	methods: {
		deepClone,
		formatMathJaxImg,
		setMathJaxText() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
		},
		textFlge() {
			const htmlString = this.dataGroup[this.activeNav].children[this.currentQuestion].answer;
			const regex = /(<([^>]+)>)/gi;
			const text = htmlString.replace(regex, '');
			return text;
		},
		changeQuestion(n) {
			let that = this;
			if (n == 1) {
				if (0 < this.currentQuestion) {
					this.currentQuestion = --this.currentQuestion;
				} else {
					this.$notify({
						type: 'warning',
						message: '已经是第一道题了'
					});
				}
			}
			if (n == 2) {
				if (this.currentQuestion < this.dataGroup[this.activeNav].children.length - 1) {
					this.currentQuestion = ++this.currentQuestion;
				} else {
					this.$dialog
						.confirm({
							message: '已是最后一个，再做一次？'
						})
						.then(() => {
							that.currentQuestion = 0;
							that.dataGroup[this.activeNav].children = that.deepClone(
								that.questionSourse[this.activeNav].children
							);
							setTimeout(() => {
								that.setMathJaxText();
							}, 10);
						})
						.catch(() => {});
				}
			}
			setTimeout(() => {
				this.setMathJaxText();
			}, 10);
		},
		analysisAction() {
			this.dataGroup[this.activeNav].children[this.currentQuestion].showAnalysis = true;
			setTimeout(() => {
				this.setMathJaxText();
			}, 10);
		},
		changeChildNav(item, index) {
			this.activeNav = index;
			if (!this.dataGroup[this.activeNav]) {
				this.getQuestion();
			}
			this.activeMenu = false;
			setTimeout(() => {
				this.setMathJaxText();
			}, 10);
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: this.$route.query.channelId
				})
				.then((res) => {
					let resData = res.data || [];
					this.childrenNav = resData;
					this.isLoading = false;
					for (let i = 0; i < resData.length; i++) {
						if (this.$route.query.childId == resData[i].id) {
							this.activeNav = i;
							break;
						}
					}
					if (this.childrenNav.length) {
						this.getQuestion();
					} else {
						this.isLoading = false;
						this.isEmpty = true;
					}
				});
		},
		getQuestion() {
			this.isLoading = true;
			this.$api
				.getMenuQuestion({
					course_id: this.$route.query.channelId,
					pid: 0
				})
				.then((res) => {
					this.isLoading = false;
					let resData = res.data || [];
					resData = resData[0];
					//console.log(resData);
					let resetData = this.resetQuestion(resData);
					//console.log(resetData);
					if (resetData.length) {
						resetData.forEach((item) => {
							item.option = this.computedOptions(item.option);
							if (item.classtext < 3) {
								item.userAnswer = [];
							} else {
								item.userAnswer = '';
							}
							item.userAnswerTag = '';
							item.content = this.formatMathJaxImg(item.content);
							item.stem = this.formatMathJaxImg(item.stem);
							item.answer = this.formatMathJaxImg(item.answer);
							item.analysis = this.formatMathJaxImg(item.analysis);
						});
						resData.children = resetData;
						this.$set(this.dataGroup, this.activeNav, resData);

						this.questionSourse = this.deepClone(this.dataGroup);
						setTimeout(() => {
							this.setMathJaxText();
						}, 10);
					} else {
						this.isEmpty = true;
					}
					this.isLoading = false;
				});
		},
		resetQuestion(arr) {
			let children = arr.children || [];
			for (let i = 0; i < children.length; i++) {
				if (children[i].children) {
					let arr = [];
					for (let j = 0; j < children[i].children.length; j++) {
						let child = children[i].children[j];
						child.content = children[i].content || '';
						arr.push(child);
					}
					children.splice(i, 1, ...arr);
				}
			}

			return children;
		},
		selectAction(index, type) {
			let question = this.dataGroup[this.activeNav].children[this.currentQuestion];
			let userAnswer = question.userAnswer || [];
			let optionsTag = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
			let idxof = userAnswer.indexOf(index);
			if (type == 1) {
				userAnswer = [];
			}
			if (userAnswer.includes(index)) {
				userAnswer.splice(idxof, 1);
			} else {
				if (type == 1) {
					userAnswer = [index];
				}
				if (type == 2) {
					if (idxof < 0) {
						userAnswer.push(index);
					}
				}
			}
			//console.log(userAnswer);
			question.userAnswer = userAnswer.sort();
			let _userAnswerTag = '';
			for (let i = 0; i < question.userAnswer.length; i++) {
				_userAnswerTag = _userAnswerTag + optionsTag[question.userAnswer[i]];
			}

			question.userAnswerTag = _userAnswerTag;
			this.$set(this.dataGroup[this.activeNav].children, this.currentQuestion, question);
		},
		computedOptions(str) {
			str = str || '';
			str = str.replace(/<ol>/g, '');
			str = str.replace(/<ul>/g, '');
			str = str.replace(/<\/ul>/g, '');
			str = str.replace(/<\/ol>/g, '');
			str = str.replace(/<li>/g, '');
			str = str.replace(/<\/li>/g, '');
			let arr = str.split('\n');
			let targetArr = [];
			for (let i = 0; i < arr.length; i++) {
				if (arr[i]) {
					targetArr.push({
						label: this.formatMathJaxImg(arr[i]),
						status: 0
					});
				}
			}
			return targetArr;
		}
	}
};
</script>
<style lang="scss">
.zhishiyaodian-main {
	.question-stem {
		font-size: 0.26rem;
		line-height: 1.75em;
		* {
			font-size: 0.26rem !important;
			line-height: 1.75em !important;
		}
	}
	.area-con {
		font-size: 0.26rem;
		line-height: 1.75em;
		.options {
			li {
				position: relative;
				padding: 0.3rem 0.3rem 0.3rem 40px;
				margin: 0.3rem 0;
				cursor: default;
				background: #f8f8f8;
				border-radius: 5px;
				&::after {
					position: absolute;
					left: 0.3rem;
					top: 50%;
					transform: translateY(-50%);
					padding-right: 5px;
				}
				&::before {
					position: absolute;
					left: 30px;
					top: 50%;
					transform: translateY(-50%);
					padding-right: 5px;
					content: '';
					height: 10px;
					border-left: 1px #ccc solid;
				}
				&:nth-child(1)::after {
					content: 'A';
				}
				&:nth-child(2)::after {
					content: 'B';
				}
				&:nth-child(3)::after {
					content: 'C';
				}
				&:nth-child(4)::after {
					content: 'D';
				}
				&:nth-child(5)::after {
					content: 'E';
				}
				&:nth-child(6)::after {
					content: 'F';
				}
				&:nth-child(7)::after {
					content: 'G';
				}
				&:nth-child(8)::after {
					content: 'H';
				}
				&:nth-child(9)::after {
					content: 'I';
				}
				&:nth-child(10)::after {
					content: 'J';
				}
			}
			.active {
				background: #e2f5f1;
			}
			.error {
				background: rgb(230, 50, 50);
			}
		}
		* {
			font-size: 0.26rem !important;
			line-height: 1.75em !important;

			text-align: justify;
			justify-content: space-between;
			sub,
			sup {
				font-size: 0.5em !important;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.menu-icon {
	position: fixed;
	right: 0.3rem;
	top: 0.3rem;
	z-index: 1000;
}
.content-area.question-area {
	position: relative;
	margin-top: 0.4rem;
	border-top: 1px dashed #dcdcdc;
	padding-top: 0.3rem;
	.analysis-item {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 0.3rem;
		.tit {
			min-width: 0.16rem;
			color: #1e9e85;
		}
	}
}
.opreate-area {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0.3rem;
	background: #fff;
	z-index: 10;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	user-select: none;
	border-top: 1px #dcdcdc solid;
	.btn-box {
		width: 33.33%;
		display: flex;
		justify-content: center;
	}
	.btn {
		width: 100px;
		height: 32px;
		background: #e2f5f1;
		border-radius: 16px;
		font-size: 12px;
		font-weight: 400;
		color: #333;
		line-height: 32px;
		text-align: center;
		&:hover {
			opacity: 0.8;
		}
	}
	.btn.disable {
		background: #dcdcdc;
	}
	.jiexi-btn {
		background: #32b39a;
		color: #fff;
	}
}
.zhishiyaodian-main {
	height: 100%;
	width: 100%;
	margin-top: 1.08rem;
	border-top: 0.2rem solid #f5f5f5;
	padding: 0rem 0.3rem 1.8rem 0.3rem;
	overflow: auto;
	background: #fff;

	::v-deep img {
		height: auto !important;
	}
	.children-content {
		.content-area {
			height: 100%;
			border-radius: 10px;

			.area-con {
				height: 100%;
			}
			.question-stem {
				margin-bottom: 0.3rem;
			}
			.answer-area {
				margin-bottom: 0.3rem;
			}
		}
	}
	.children-nav {
		position: fixed;
		left: 0;
		right: 0;
		top: 50px;
		bottom: 0;
		z-index: 100;
		background: rgba(0, 0, 0, 0.8);
		user-select: none;
		cursor: pointer;
		li {
			line-height: 50px;
			text-align: center;
			height: 50px;
			padding: 0 0.3rem;
			border-bottom: 1px #dcdcdc solid;
			font-size: 0.26rem;
			color: #fff;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.active {
			background: #1e9e85;
			color: #fff;
		}
	}
}
.children-content {
	padding-top: 10px;
}
</style>
