<template>
	<div class="wenxuechangshi-page" id="contentText">
		<navBar @goBack="gobackChapter">
			<span class="title">文学常识</span>
		</navBar>
		<div class="content-box" :class="{ hasMore: 1 < classArr.length }">
			<loadingBox v-if="isLoading"></loadingBox>
			<template v-else>
				<coustmEmpty v-if="isEmpty"></coustmEmpty>
				<template v-else>
					<div class="author-des">
						<div class="author-base">
							<div class="author-name" @click="showAuthorList = !showAuthorList">
								<p>点击匹配作者</p>
								<div class="selected-author" v-if="selectAuthor[activeClass]">
									<van-image
										class="avator"
										fit="cover"
										:src="setSourceUrl(selectAuthor[activeClass].image)"
									></van-image>
									<div
										class="aname"
										:class="`${selectAuthor[activeClass].thisauthor ? 'proper' : 'error'}`"
									>
										{{ selectAuthor[activeClass].title }}
									</div>
								</div>
								<ul class="author-list" v-show="showAuthorList">
									<li
										v-for="(author, index) in authorData[activeClass]"
										:key="`author${index}`"
										@click="chooseAuthor(author)"
									>
										<van-image
											class="avator"
											fit="cover"
											:src="setSourceUrl(author.image)"
										></van-image>
										<div class="aname">{{ author.title }}</div>
									</li>
								</ul>
							</div>
							<div v-html="authorContent[activeClass].zzjj" class="base-html"></div>
						</div>
					</div>

					<div class="common-sense">
						<div class="author-title">
							<div class="title-icon" :style="`backgroundImage:url(${authorSense})`"></div>
							文学常识
						</div>
						<div class="sense-base">
							<div class="sense-infs base-html" v-html="authorContent[activeClass].wxcs"></div>
						</div>
					</div>
				</template>
			</template>
		</div>

		<div class="answer-status" v-if="resultStatus" @click="resultStatus = false">
			<van-image
				v-show="selectAuthor[activeClass].thisauthor"
				fit="cover"
				class="match-img"
				:src="successImg"
			></van-image>
			<van-image
				v-show="!selectAuthor[activeClass].thisauthor"
				fit="cover"
				class="match-img"
				:src="errorImg"
			></van-image>
		</div>

		<div class="answer-status" v-if="answerStatus" @click="answerStatus = 0">
			<div class="status-main">
				<img :src="answerPerpor" v-if="answerStatus == 1" class="statue-img" />
				<img :src="answerError" v-if="answerStatus == 2" class="statue-img" />
				<div class="main-text">
					<template>正确答案：<span v-html="keyWords[activeClass][currentInput]"></span></template>
					<i class="close-btn el-icon-close"></i>
				</div>
			</div>
		</div>

		<ul class="class-length" v-if="1 < classArr.length">
			<li
				:class="{ active: activeClass == index }"
				v-for="(item, index) in classArr"
				:key="index"
				@click="changeAuthor(index)"
			>
				{{ index + 1 }}
			</li>
		</ul>
		<audio
			:src="authorProper"
			controlslist="nodownload"
			controls="controls"
			ref="authorProper"
			class="author-vioce"
		></audio>
		<audio
			:src="authorError"
			controlslist="nodownload"
			controls="controls"
			ref="authorError"
			class="author-vioce"
		></audio>
	</div>
</template>

<script>
import { setSourceUrl, matchKeyWordToInput } from '@/utils/public';
export default {
	name: 'wenXueChangShi',
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			showAuthorList: false,
			resultStatus: false,
			authorName: require('@/assets/img/icon/authorName.png'),
			authorSense: require('@/assets/img/icon/authorSense.png'),
			answerPerpor: require('@/assets/img/answerPerpor.png'),
			answerError: require('@/assets/img/answerError.png'),
			successImg: require('@/assets/img/success.png'),
			errorImg: require('@/assets/img/error.png'),
			authorProper: require('@/assets/audio/proper.mp3'),
			authorError: require('@/assets/audio/error.mp3'),
			classArr: [],
			activeClass: 0,
			authorContent: [],
			keyWords: [],
			currentInput: 0,
			answerStatus: 0,

			authorData: [],
			selectAuthor: []
		};
	},
	created() {
		this.getContent();
		this.addHistory();
	},
	mounted() {
		this.properAudio = this.$refs['authorProper'];
		this.errorAudio = this.$refs['authorError'];
	},
	methods: {
		matchKeyWordToInput,
		setSourceUrl,

		chooseAuthor(author) {
			this.resultStatus = true;
			this.$set(this.selectAuthor, this.activeClass, author);
			if (author.thisauthor == true) {
				this.properAudio.play();
			} else {
				this.errorAudio.play();
			}
		},
		changeAuthor(n) {
			this.activeClass = n;
			if (!this.authorContent[this.activeClass]) {
				this.getContent();
			}
			this.initEvent();
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: this.$route.query.channelId
				})
				.then((res) => {
					let resData = res.data || [];
					this.classArr = resData;
					if (resData.length) {
						this.getContent();
					} else {
						this.isLoading = false;
						this.isEmpty = true;
					}
				});
		},
		getContent() {
			this.isLoading = true;
			this.$api
				.getMenuAuthor({
					course_id: this.$route.query.channelId
				})
				.then((res) => {
					this.isLoading = false;
					let resData = res.data || [];
					let targetData = {};
					for (let i = 0; i < resData.length; i++) {
						if (resData[i].thisauthor) {
							targetData = resData[i];
							break;
						}
					}
					let keyWords = this.matchKeyWordToInput(targetData.zzjj).arr;
					targetData.zzjj = this.matchKeyWordToInput(targetData.zzjj).str;
					this.$set(this.authorData, this.activeClass, resData);
					this.$set(this.authorContent, this.activeClass, targetData);
					this.$set(this.keyWords, this.activeClass, keyWords);
					this.initEvent();
				});
		},
		initEvent() {
			this.$nextTick(() => {
				let that = this;
				let contentText = window.document.getElementById('contentText');
				let inputItems = contentText.getElementsByTagName('input');
				for (let i = 0; i < inputItems.length; i++) {
					let inputVal = '';
					let targetVal = '';
					inputItems[i].oninput = function () {
						inputVal = this.value.trim();
						targetVal = that.keyWords[that.activeClass][i];
						if(targetVal.indexOf('&middot;')!=-1){
							targetVal = targetVal.replace('&middot;','·')
						}
						that.currentInput = i;
					};
					inputItems[i].onblur = function () {
					  that.currentIndex = i;
					  if (inputVal) {
					    if (inputVal == targetVal) {
					      that.answerStatus = 1;
					    } else {
					      that.answerStatus = 2;
					    }
					  }
					};
					inputItems[i].addEventListener('keyup', (e) => {
						if (e.keyCode == 13) {
							if (inputVal) {
								if (inputVal == targetVal) {
									that.answerStatus = 1;
								} else {
									that.answerStatus = 2;
								}
							}
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss">
.sense-infs,
.base-html {
	* {
		line-height: 1.75em !important;
		font-size: 0.26rem !important;
		color: #333 !important;
		background: none !important;
		em {
			font-style: normal;
		}
	}
	input {
		border: 0;
		border-bottom: 1px #333 solid;
		text-align: center;
		max-width: 1.5rem;
	}
}
</style>
<style lang="scss" scoped>
.author-des {
	margin-bottom: 0.6rem;
}
.answer-status {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	.status-main {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: -1.42rem;
	}
	.statue-img {
		width: 3.08rem;
		height: 1.36rem;
		margin-bottom: 0.36rem;
	}
	.main-text {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 5.26rem;
		height: 2.06rem;
		background: #ffffff;
		border-radius: 0.1rem;
		font-size: 0.3rem;
		color: #333;
		font-weight: 400;
		padding: 0.3rem;
	}
}
.author-base {
	display: flex;
	margin-bottom: 0.3rem;
	background: #e2f5f1;
	padding: 0.3rem;
	border-radius: 0.1rem;
	.author-name {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: 100px;
		height: 2.24rem;
		background: #f1f1f1;
		border-radius: 8px;
		font-size: 0.22rem;
		cursor: pointer;
		margin-right: 0.28rem;
		.icon-renyuanguanli {
			color: #878787;
			margin-right: 0.1rem;
			font-size: 0.28rem;
		}
		.selected-author {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 0.15rem;
			overflow: hidden;
			.avator {
				width: 100%;
				height: 100%;
			}
			.aname {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				line-height: 1.75em;
				color: #fff;
				text-align: center;
			}
			.aname.proper {
				background: rgba(40, 178, 149, 0.8);
			}
			.aname.error {
				background: rgba(205, 0, 0, 0.8);
			}
		}
		.author-list {
			position: absolute;
			left: 1.4rem;
			top: 0.8rem;
			z-index: 10;
			width: 5rem;
			padding: 0.3rem 0.15rem 0 0.15rem;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			background: #ffffff;
			box-shadow: 0px 0px 8px 0px rgba(40, 179, 150, 0.19);
			border-radius: 0.1rem;
			li {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 0.15rem;
				.avator {
					width: 2rem;
					height: 2.4rem;
					border-radius: 0.1rem;
					overflow: hidden;
				}
				&:hover {
					.avator {
						border-color: #28b295;
					}
				}

				.aname {
					text-align: center;
				}
			}
		}
	}
}
.class-length {
	position: fixed;
	left: 0;
	right: 0;
	top: 1.2rem;
	z-index: 100;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 0.72rem;
	border-bottom: 1px #dcdcdc solid;
	margin-bottom: 0.3rem;
	background: #fff;
	li {
		position: relative;
		line-height: 0.72rem;
		font-size: 0.28rem;
	}
	.active {
		color: #28b295;
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			border-bottom: 1px #28b295 solid;
		}
	}
}
.wenxuechangshi-page {
	position: relative;
	// padding-top: 1.08rem;
	height: 100%;
	.content-box {
		padding: 0.3rem;
		height: 100%;
		background: #fff;
		overflow: auto;
		// border-top: 0.2rem solid #f5f5f5;
	}
	.hasMore {
		padding-top: 1rem;
	}
	.author-title {
		display: flex;
		align-items: center;
		font-size: 0.3rem;
		font-weight: 500;
		color: #333333;
		line-height: 0.52rem;
		margin-bottom: 0.4rem;
		.title-icon {
			width: 0.32rem;
			height: 0.36rem;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin-right: 0.1rem;
		}
	}
}
.sense-base {
	display: block !important;
	padding: 0.28rem;
	background: #ffffff;
	border: 0.02rem solid #dcdcdc;
	border-radius: 0.1rem;
	font-size: 0.26rem;
	color: #333;
	text-indent: 0.52rem;
	line-height: 2 !important;
}
</style>
