<template>
<div class="children-page">

<navBar @goBack="gobackChapter">
<span class="title">课文朗读</span>
</navBar>
<langDuKeWen></langDuKeWen>
</div>
</template>

<script>
export default {
name:'keWenLangDu',
components:{
langDuKeWen:()=>import('@/views/channel/components/langDuKeWen.vue')
},
data(){
return {}
},
methods:{

}
}
</script>

<style lang="scss" scoped>
.children-page{
width: 100%;
height: 100%;
}
</style>