<template>
	<div class="lianxi-box" :class="{ noborder: translateMoudle }">
		<div class="lianxi-content" v-if="translateMoudle">
			<div class="question">
				<span v-html="questionItem.word" v-if="translateMoudle == 2"></span>
				<span v-html="questionItem.translate" v-if="translateMoudle == 1"></span>
			</div>
			<div class="options" id="options">
				<ul class="options-item" id="optionsItem" v-if="translateMoudle == 2">
					<li
						v-for="(item, index) in questionItem.yyh"
						:key="index"
						:class="{
							error: questionItem.status == 1 && questionItem.userSelect == index,
							proper: questionItem.status == 2 && questionItem.userSelect == index
						}"
						@click="selectAction(index)"
					>
						<span v-html="item"></span>
					</li>
				</ul>
				<ul class="options-item" id="optionsItem" v-if="translateMoudle == 1">
					<li
						v-for="(item, index) in questionItem.hyy"
						:key="index"
						:class="{
							error: questionItem.status == 1 && questionItem.userSelect == index,
							proper: questionItem.status == 2 && questionItem.userSelect == index
						}"
						@click="selectAction(index)"
					>
						<span v-html="item"></span>
					</li>
				</ul>
			</div>
		</div>

		<div class="question-handle" v-show="translateMoudle">
			<div class="btn" :class="{ disable: currentQuestion == 0 }" @click="changeQuestion(1)">上一题</div>
			<div class="btn" :class="{ disable: currentQuestion == totalQuestion - 1 }" @click="changeQuestion(2)">
				下一题
			</div>
		</div>

		<div class="lianxi-select" v-if="showTranslateModel">
			<div class="select-option" @click="selecTranslateModel(2)">
				<img :src="optionImg" alt="" />
				<span >英译汉</span>
			</div>
			<div class="select-option" @click="selecTranslateModel(1)">
				<img :src="optionImg" alt="" />
				<span >汉译英</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'danCiLianXi',
	props: {
		dataObj: {
			type: Object,
			default: () => {
				return {};
			}
		},
		currentQuestion: {
			type: [String, Number],
			default: 0
		},
		totalQuestion: {
			type: [String, Number],
			default: 0
		}
	},
	watch: {
		dataObj: function (val) {
			this.questionItem = val;
		}
	},
	data() {
		return {
			showTranslateModel: true,
			questionItem: this.dataObj,
			translateMoudle: 0,
			optionImg: require('@/assets/image/44.png')
		};
	},
	created() {
		this.addHistory();
	},
	mounted() {
		this.$eventBus.$on('changeLianXi', () => {
			this.translateMoudle = 0;
			this.showTranslateModel = true;
		});
	},
	methods: {
		changeQuestion(n) {
			let obj ={
				type:this.translateMoudle == 1?4:3,
				num:null,
				n:n
			}
			this.$emit('changeQuestion', obj);
			
		},
		selecTranslateModel(n) {
			this.translateMoudle = n;
			this.showTranslateModel = false;
			this.$eventBus.$emit('selecTranslateModel', n)
		},

		selectAction(n) {
			let optionsTag = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];

			let userAnswer = optionsTag[n];
			let questionAnswer = '';
			if (this.translateMoudle == 1) {
				questionAnswer = this.questionItem.hyydaan;
			} else {
				questionAnswer = this.questionItem.yyhdaan;
			}
			this.questionItem.userSelect = n;
			if (userAnswer == questionAnswer) {
				this.questionItem.status = 2;
			} else {
				this.questionItem.status = 1;
			}
		}
	}
};
</script>
<style lang="scss">
.options-item {
	li {
		&:hover {
			opacity: 0.8;
		}
		user-select: none;
		cursor: default;
		position: relative;
		display: flex;
		align-items: center;
		padding: 15px;
		margin-bottom: 10px;
		background: #f8f8f8;
		border-radius: 4px;
		font-size: 13px;
		color: #3a3a3a;
		padding-left: 50px;
		&::after {
			position: absolute;
			left: 35px;
			top: 50%;
			transform: translateY(-50%);
			content: '|';
			color: #d8d8d8;
		}
		&::before {
			position: absolute;
			left: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:nth-child(1) {
			&::before {
				content: 'A';
			}
		}
		&:nth-child(2) {
			&::before {
				content: 'B';
			}
		}
		&:nth-child(3) {
			&::before {
				content: 'C';
			}
		}
		&:nth-child(4) {
			&::before {
				content: 'D';
			}
		}
		&:nth-child(5) {
			&::before {
				content: 'E';
			}
		}
		&:nth-child(6) {
			&::before {
				content: 'F';
			}
		}
		&:nth-child(7) {
			&::before {
				content: 'J';
			}
		}

		&:nth-child(8) {
			&::before {
				content: 'H';
			}
		}
	}
	.error {
		color: #fff;
		background: #ff494a;
	}
	.proper {
		color: #fff;
		background: #27b295;
	}
}
</style>
<style lang="scss" scoped>
.lianxi-content {
	width: 100%;
	height: calc(100% - 60px);
	border: 1px solid #dcdcdc;
	padding: 15px;
	margin-bottom: 15px;
}
.lianxi-box {
	position: relative;
	height: 100%;
	width: 100%;
	border: 1px solid #dcdcdc;
	border-radius: 0.1rem;
	.question {
		margin-bottom: 25px;
		font-size: 16px;
		line-height: 1.75em;
	}
}
.noborder {
	border: none;
}
.lianxi-select {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	background: #fff;
	border-radius: 0.1rem;
	flex-direction: column;
	.select-option {
		display: flex;
		position: relative;
		flex-direction: column;
		align-items: center;
		margin: 0.45rem 0;
		img {
			width: 108px;
			height: 106px;
		}
		span {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -10px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 64px;
			height: 17px;
			background: #ffa632;
			border-radius: 17px;
			font-size: 14px;
			color: #fff;
			font-weight: 500;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
</style>
