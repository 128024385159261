var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-page"},[(_vm.isLoading)?_c('loading-box'):_c('div',{staticClass:"text-area"},[[(1 < _vm.classArr.length)?_c('ul',{staticClass:"class-length"},_vm._l((_vm.classArr.length),function(num){return _c('li',{key:num,class:{ active: _vm.activeClass == num - 1 },on:{"click":function($event){return _vm.changeClass(num)}}},[_vm._v(" 文章"+_vm._s(num)+" ")])}),0):_vm._e(),(!_vm.isEmpty)?[_c('ul',{staticClass:"read-model"},_vm._l((_vm.innerMenu),function(model,index){return _c('li',{key:`model${index}`,class:{ active: _vm.moudleActive == index },on:{"click":function($event){return _vm.changeMoudle(index)}}},[_vm._v(" "+_vm._s(model.text)+" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.moudleActive == index),expression:"moudleActive == index"}],staticClass:"el-icon-caret-bottom activeIcon"})])}),0),(_vm.moudleActive === 2)?_c('div',{staticClass:"recite-box"},[_c('div',{staticClass:"recite-moudel"},[_c('ul',{staticClass:"moudel-list"},_vm._l((_vm.moudelData),function(item,index){return _c('li',{key:`m${index}`,class:{ active: _vm.reciteIndex == index },on:{"click":function($event){return _vm.selectRecite(index)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_c('div',{staticClass:"recite-text"},[_c('div',{staticClass:"context-main"},[_c('ul',{staticClass:"paragraph"},_vm._l((_vm.currentClass),function(paragraph,index){return _c('li',{key:`p${index}`,class:{ hiddenDom: index == 0 }},_vm._l((paragraph),function(words,idx){return _c('span',{key:`words${idx}`,class:{
											status1: words.status == 1,
											status2: words.status == 2,
											status3: words.status == 3
										},domProps:{"innerHTML":_vm._s(words.content)},on:{"click":function($event){return _vm.clickWords(index, idx)}}})}),0)}),0)])])]):_c('div',{attrs:{"id":"classContent"}},[_c('div',{staticClass:"class-title",class:{
							active: _vm.moudleActive == 0 && _vm.isPlaying && _vm.pidx == 0 && _vm.widx == 0
						}},[_vm._v(" "+_vm._s(_vm.classContent[_vm.activeClass][0][0].content.split('-')[0])+" ")]),(_vm.subjectName == '语文')?_c('div',{staticClass:"class-author",class:{
							active: _vm.moudleActive == 0 && _vm.isPlaying && _vm.pidx == 0 && _vm.widx == 0
						}},[_c('span',{staticStyle:{"font-size":"0.4rem","opacity":"0","padding-right":"1rem"}},[_vm._v(" "+_vm._s(_vm.classContent[_vm.activeClass][0][0].content.split('-')[0])+" ")]),_vm._v(" ——"+_vm._s(_vm.classContent[_vm.activeClass][0][0].content.split('-')[1])+" ")]):_vm._e(),_c('div',{staticClass:"class-content",class:{ heightYIngYu: _vm.subjectName == '英语' }},[_c('div',{staticClass:"class-content-main",class:{ centerLayout: _vm.classArr[_vm.activeClass].classtype == 2 },attrs:{"id":"classContentMain"}},[_c('ul',{staticClass:"paragraph"},_vm._l((_vm.classContent[_vm.activeClass]),function(paragraph,index){return _c('li',{key:`p${index}`,class:{ hiddenDom: index == 0 }},_vm._l((paragraph),function(words,idx){return _c('span',{key:`words${idx}`,class:{ active: _vm.pidx == index && _vm.widx == idx },attrs:{"id":`span${index}-${idx}`},on:{"click":function($event){return _vm.selectWords(index, idx)}}},[_c('span',{style:({
												textAlign: _vm.computeAlign(JSON.parse(words.style).layout),
												display: _vm.computeDisplay(JSON.parse(words.style).layout),
												color: JSON.parse(words.style).color
											}),domProps:{"innerHTML":_vm._s(words.content)}}),_c('audio',{ref:`audio${index}-${idx}`,refInFor:true,staticClass:"audio-item",attrs:{"src":_vm.setSourceUrl(words.audio),"id":`audio${index}-${idx}`,"controlslist":"nodownload","controls":"controls"},on:{"ended":function($event){return _vm.playEnd(index)},"playing":() => {
													_vm.isPlaying = true;
												}}})])}),0)}),0)])])])]:_c('coustm-empty')]],2),(_vm.moudleActive !== 2)?_c('div',{staticClass:"audio-box"},[_c('div',{staticClass:"audio-palyer"},[_c('div',{staticClass:"custom-audio"},[_c('div',{staticClass:"play-btn",on:{"click":_vm.playAction}},[_c('i',{class:`iconfont ${_vm.isPlaying ? 'icon-zanting' : 'icon-bofang'}`})]),_c('div',{staticClass:"play-progress"},[_c('van-slider',{attrs:{"max":_vm.timeMax,"disabled":_vm.moudleActive == 1},on:{"drag-start":_vm.changeStart,"input":_vm.changeMove,"change":_vm.changeAction},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}})],1),_c('div',{staticClass:"play-tiem"},[_vm._v(_vm._s(_vm.totalTime))])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }