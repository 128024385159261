<template>
	<div class="common-page">
		<loading-box v-if="isLoading"></loading-box>
		<div class="text-area" v-else>
			<template>
				<ul class="class-length" v-if="1 < classArr.length">
					<li
						:class="{ active: activeClass == num - 1 }"
						v-for="num in classArr.length"
						:key="num"
						@click="changeClass(num)"
					>
						文章{{ num }}
					</li>
				</ul>
				<template v-if="!isEmpty">
					<ul class="read-model">
						<li
							:class="{ active: moudleActive == index }"
							v-for="(model, index) in innerMenu"
							:key="`model${index}`"
							@click="changeMoudle(index)"
						>
							{{ model.text }}
							<i class="el-icon-caret-bottom activeIcon" v-show="moudleActive == index"></i>
						</li>
					</ul>

					<div class="recite-box" v-if="moudleActive === 2">
						<div class="recite-moudel">
							<ul class="moudel-list">
								<li
									v-for="(item, index) in moudelData"
									:key="`m${index}`"
									:class="{ active: reciteIndex == index }"
									@click="selectRecite(index)"
								>
									{{ item.text }}
								</li>
							</ul>
						</div>
						<div class="recite-text">
							<div class="context-main">
								<ul class="paragraph">
									<li
										v-for="(paragraph, index) in currentClass"
										:key="`p${index}`"
										:class="{ hiddenDom: index == 0 }"
									>
										<span
											v-for="(words, idx) in paragraph"
											:key="`words${idx}`"
											:class="{
												status1: words.status == 1,
												status2: words.status == 2,
												status3: words.status == 3
											}"
											v-html="words.content"
											@click="clickWords(index, idx)"
										></span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div v-else id="classContent" >
						<div
							class="class-title"
							:class="{
								active: moudleActive == 0 && isPlaying && pidx == 0 && widx == 0
							}"
						>
							{{ classContent[activeClass][0][0].content.split('-')[0] }}
						</div>
						<div
							class="class-author"
							v-if="subjectName == '语文'"
							:class="{
								active: moudleActive == 0 && isPlaying && pidx == 0 && widx == 0
							}"
						>
							<span style="font-size: 0.4rem; opacity: 0; padding-right: 1rem">
								{{ classContent[activeClass][0][0].content.split('-')[0] }}
							</span>
							——{{ classContent[activeClass][0][0].content.split('-')[1] }}
						</div>
						<div class="class-content"  :class="{ heightYIngYu: subjectName == '英语' }">
							<div
								class="class-content-main"
								:class="{ centerLayout: classArr[activeClass].classtype == 2 }"
								id="classContentMain"
							>
								<ul class="paragraph">
									<li
										v-for="(paragraph, index) in classContent[activeClass]"
										:key="`p${index}`"
										:class="{ hiddenDom: index == 0 }"
									>
										<span
											v-for="(words, idx) in paragraph"
											:key="`words${idx}`"
											:class="{ active: pidx == index && widx == idx }"
											:id="`span${index}-${idx}`"
											@click="selectWords(index, idx)"
										>
											<span
												v-html="words.content"
												:style="{
													textAlign: computeAlign(JSON.parse(words.style).layout),
													display: computeDisplay(JSON.parse(words.style).layout),
													color: JSON.parse(words.style).color
												}"
											></span>
											<audio
												:src="setSourceUrl(words.audio)"
												class="audio-item"
												:ref="`audio${index}-${idx}`"
												:id="`audio${index}-${idx}`"
												controlslist="nodownload"
												controls="controls"
												@ended="playEnd(index)"
												@playing="
													() => {
														isPlaying = true;
													}
												"
											></audio>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</template>
				<coustm-empty v-else></coustm-empty>
			</template>
		</div>

		<div v-if="moudleActive !== 2" class="audio-box">
			<div class="audio-palyer">
				<div class="custom-audio">
					<div class="play-btn" @click="playAction">
						<i :class="`iconfont ${isPlaying ? 'icon-zanting' : 'icon-bofang'}`"></i>
					</div>
					<div class="play-progress">
						<van-slider
							@drag-start="changeStart"
							@input="changeMove"
							@change="changeAction"
							:max="timeMax"
							:disabled="moudleActive == 1"
							v-model="progress"
						/>
					</div>
					<div class="play-tiem">{{ totalTime }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setSourceUrl, formatTime, deepClone } from '@/utils/public';
import loadingBox from '@/components/loadingBox.vue';
export default {
	components: { loadingBox },
	name: 'langDuKeWen',
	watch: {
		moudleActive: function (val) {
			if (val == 0) {
				this.audioTime = this.timeMax;
				this.totalTime = this.formatTime(this.timeMax);
			}
			if (val == 1) {
				this.pauseAll();
			}
		},
		currentAudio: function (val) {
			val.currentTime = this.stratTime;
			val.addEventListener('loadedmetadata', function () {});
			val.play();
			val.addEventListener('timeupdate', () => {
				let currentTime = val.currentTime;
				this.progress = Number(this.playEdTime + currentTime);
				this.totalTime = this.formatTime(this.timeMax - this.progress);
			});

			let redaBox = document.getElementById('classContent');
			let boxHeight = redaBox.offsetHeight;
			let currentSpan = document.getElementById(`span${this.pidx}-${this.widx}`);
			let spanHeight = currentSpan.offsetHeight;
			let st = currentSpan.offsetTop;
			redaBox.scrollTo({
				top: st - boxHeight / 2 + spanHeight,
				behavior: 'smooth'
			});
		}
	},
	data() {
		return {
			currentTitle: this.$route.query.channelName,
			isLoading: false,
			isPlaying: false,
			isEmpty: false,
			progress: 0,
			audioTime: 0,
			timeMax: 0,
			currentTime: '00:00:00',
			totalTime: '00:00:00',
			audioDome: null,

			classArr: 0,
			activeClass: 0,

			moudleActive: 0,
			innerMenu: [
				{
					name: 0,
					text: '名家朗读'
				},
				{
					name: 1,
					text: '单句跟读'
				},
				{
					name: 2,
					text: '背诵练习'
				}
			],
			moudelData: [
				{
					id: 3,
					text: '正常模式'
				},
				{
					id: 6,
					text: '中等模式'
				},
				{
					id: 9,
					text: '复杂模式'
				}
			],
			classContent: [],
			audioObj: [],
			pidx: 0,
			widx: 0,
			playEdTime: 0,
			currentAudio: null,
			stratTime: 0,
			subjectName: '语文',

			isRecite: false,
			modelActive: 0,
			reciteIndex: 0,
			currentClass: [],
			currentClassSource: [],

			timeNum: 0
		};
	},
	created() {
		this.initData();
		this.addHistory();
		this.subjectName = this.$route.query.subjectName;
	},
	mounted() {
		this.audioDome = this.$refs['audio'];
	},
	methods: {
		setSourceUrl,
		formatTime,
		deepClone,
		computeDisplay(n) {
			let display = 'inline';
			if (1 < n) {
				display = 'block';
			}
			return display;
		},
		computeAlign(n) {
			let align = 'left';
			switch (n) {
				case 2: {
					align = 'center';
					break;
				}
				case 3: {
					align = 'right';
					break;
				}
				default: {
					align = 'left';
				}
			}
			return align;
		},
		changeStart() {
			this.pauseAll();
		},
		changeMove(val) {
			this.pauseAll();
			this.audioTime = this.formatTime(Math.floor(this.timeNum - val));
		},
		clickWords(idx1, idx2) {
			let status = this.currentClass[idx1][idx2].status;
			if (status == 2) {
				this.$set(this.currentClass[idx1][idx2], 'status', 3);
			}
		},
		setRecite(arr, n) {
			arr = arr || [];
			let num = this.moudelData[n].id;
			let indexArr = [];
			arr.forEach((item1, index1) => {
				item1.forEach((item2, index2) => {
					indexArr.push(`${index1},${index2}`);
				});
			});

			let selectArr = [];
			for (let i = 0; i < num; i++) {
				let randomNum = 1 + Math.floor(Math.random() * indexArr.length);
				if (-1 < selectArr.indexOf(randomNum)) {
					randomNum = Math.floor(Math.random() * indexArr.length);
					selectArr.push(randomNum);
				} else {
					selectArr.push(randomNum);
				}
			}
			let targetArr = [];
			for (let i = 0; i < indexArr.length; i++) {
				if (selectArr.includes(i)) {
					targetArr.push(indexArr[i]);
				}
			}
			let newArr = [];
			arr.forEach((item1, index1) => {
				let words = [];
				item1.forEach((item2, index2) => {
					let target = `${index1},${index2}`;
					let _status = 1;
					if (targetArr.includes(target)) {
						_status = 2;
					} else {
						_status = 1;
					}
					words.push({
						content: item2.content,
						status: _status
					});
				});
				newArr.push(words);
			});
			return newArr;
		},
		closeRecite() {
			this.moudleActive = 0;
			this.isRecite = false;
			this.reciteIndex = 0;
			this.getAudioObj();
		},
		selectRecite(idx) {
			this.reciteIndex = idx;
			let newCurrentClass = this.deepClone(this.currentClassSource);
			newCurrentClass = this.setRecite(newCurrentClass, idx);
			this.currentClass = newCurrentClass;
		},
		selectWords(x, y) {
			if (this.moudleActive == 1) {
				this.pauseAll();
				this.pidx = x;
				this.widx = y;
				this.timeMax = 0;
				this.progress = 0;
				this.stratTime = 0;
				this.currentAudio = this.audioObj[this.pidx][this.widx];
				let duration = this.classContent[this.activeClass][this.pidx][this.widx].audio_length;

				this.timeMax = duration;
				this.audioTime = Number(duration);
				this.totalTime = this.formatTime(duration);
				this.isPlaying = true;
				this.currentAudio.play();
			}
		},
		pauseAll() {
			console.log(this.audioObj)
			for (let i = 0; i < this.audioObj.length; i++) {
				for (let j = 0; j < this.audioObj[i].length; j++) {
					let audioItem = this.audioObj[i][j];
					console.log(audioItem.currentTime,'audioItem.currentTime')
					audioItem.currentTime = 0;
					audioItem.pause();
				}
			}
			
		},
		// 切换文章
		changeClass(n) {
			this.activeClass = n - 1;
			if (!this.classContent[this.activeClass]) {
				this.getContent();
			}
			this.isPlaying = false;
			this.pauseAll();
			this.pidx = 0;
			this.widx = 0;
			this.playEdTime = 0;
			this.reciteIndex = 0;
			// this.$nextTick(() => {
			// 	this.getAudioObj();
			// });
			this.changeMoudle(this.moudleActive)
		},
		// 初始化第一篇文章
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: this.$route.query.channelId
				})
				.then((res) => {
					let resData = res.data || [];
					this.classArr = resData;
					if (resData.length) {
						this.getContent();
					} else {
						this.isLoading = false;
						this.isEmpty = true;
					}
				});
		},
		getContent() {
			// this.isLoading = true;
			this.$api
				.getMenuMjld({
					course_id: this.classArr[this.activeClass].id
					// course_id: this.$route.query.channelId
				})
				.then((res) => {
					this.isLoading = false;
					let resData = res.data || [];
					if (resData.length) {
						this.$set(this.classContent, this.activeClass, resData);
						this.$nextTick(() => {
							this.getAudioObj();
						});
					} else {
						this.isEmpty = true;
					}
				});
		},
		getAudioObj() {
			let time = 0;
			for (let i = 0; i < this.classContent[this.activeClass]?.length; i++) {
				let praagraph = this.classContent[this.activeClass][i];
				let arr = [];
				for (let j = 0; j < praagraph.length; j++) {
					let audioItem = window.document.getElementById(`audio${i}-${j}`);
					arr.push(audioItem);
					time = time + Number(praagraph[j].audio_length);
				}
				this.totalTime = this.formatTime(time);
				this.timeMax = time;
				this.audioTime = time;
				this.$set(this.audioObj, i, arr);
			}
		},
		playEnd() {
			this.stratTime = 0;
			if (this.moudleActive == 0) {
				this.playEdTime =
					this.playEdTime + Number(this.classContent[this.activeClass][this.pidx][this.widx].audio_length);
				this.widx = ++this.widx;
				if (!this.classContent[this.activeClass][this.pidx][this.widx]) {
					this.pidx = ++this.pidx;
					this.widx = 0;
				}
				if (this.pidx < this.classContent[this.activeClass].length) {
					this.currentAudio = this.audioObj[this.pidx][this.widx];
				} else {
					this.isPlaying = false;
					this.pidx = 0;
					this.widx = 0;
					this.playEdTime = 0;
				}
			}
			if (this.moudleActive == 1) {
				this.isPlaying = false;
			}
		},
		playAction() {
			if (this.isLoading) {
				this.$notify({
					type: 'warning',
					message: '加载中，请稍后！'
				});
				return false;
			}

			this.currentAudio = this.audioObj[this.pidx][this.widx];
			if (this.moudleActive == 0) {
				this.isPlaying = !this.isPlaying;
				if (this.isPlaying) {
					this.currentAudio.play();
				} else {
					this.currentAudio.pause();
				}
			}
			if (this.moudleActive == 1) {
				if (!this.pidx && !this.widx) {
					this.$notify({
						type: 'warning',
						message: '请点击需要朗读的句子！'
					});
					return false;
				}
				this.isPlaying = !this.isPlaying;
				if (!this.isPlaying) {
					this.currentAudio.pause();
				} else {
					this.currentAudio.play();
				}
			}
		},
		changeMoudle(n) {
			this.moudleActive = n;
			this.isPlaying = false;
			this.pauseAll();
			this.totalTime = '00:00:00';
			this.pidx = 0;
			this.widx = 0;
			this.currentTime = '00:00:00';
			this.progress = 0;
			this.playEdTime = 0;
			if (n == 0) {
				this.$nextTick(() => {
					this.getAudioObj();
				});
			}
			if (n == 2) {
				this.isRecite = true;
				let currentClass = this.classContent[this.activeClass];
				let newCurrentClass = [];
				currentClass.forEach((item) => {
					let words = [];
					item.forEach((item1) => {
						words.push({
							content: item1.content,
							status: 1
						});
					});
					item = words;
					newCurrentClass.push(item);
				});
				this.currentClassSource = newCurrentClass;
				newCurrentClass = this.setRecite(newCurrentClass, 0);
				this.currentClass = newCurrentClass;
			}
		},

		changeAction(val) {
			this.pauseAll();
			let currentContent = this.classContent[this.activeClass];
			let timeNum = 0;
			let hanndle = false;
			for (let i = 0; i < currentContent.length; i++) {
				for (let j = 0; j < currentContent[i].length; j++) {
					timeNum = timeNum + Number(currentContent[i][j].audio_length);
					if (val < timeNum) {
						this.pidx = i;
						this.widx = j;
						this.stratTime = currentContent[i][j].audio_length - (timeNum - val);
						hanndle = true;
						break;
					}
				}
				if (hanndle) {
					break;
				}
			}
			this.playEdTime = timeNum - currentContent[this.pidx][this.widx].audio_length;
			this.currentAudio = this.audioObj[this.pidx][this.widx];
		}
	}
};
</script>

<style lang="scss" scoped>
.audio-box {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	padding: 0.3rem;
	background: #fff;
	.readall-btn {
		position: absolute;
		left: 0.24rem;
		top: 0.24rem;
		width: 1rem;
		height: 1rem;
		z-index: 100;
	}
}
.custom-audio {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 1rem;
	padding: 0 0.36rem;
	border-radius: 0.5rem;
	background: #e2f5f1;
	::v-deep .van-slider {
		background: #fff;
	}
	::v-deep .van-slider__bar {
		background: #28b295;
	}
	::v-deep .van-slider__button {
		width: 0.16rem;
		height: 0.16rem;
		border-radius: 50%;
		border: 1px #28b295 solid;
	}
	.play-progress {
		flex: 1;
		padding: 0 0.14rem;
		.van-slider {
			width: 100%;
		}
	}
	.play-tiem {
		text-align: right;
		font-size: 0.24rem;
		color: #28b295;
	}
	.play-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1;
		border-radius: 50%;
		overflow: hidden;
		width: 0.64rem;
		height: 0.64rem;
		.iconfont {
			font-size: 0.72rem;
			line-height: 1;
			color: #28b295;
		}
		.iconloading {
			font-size: 0.68rem;
			animation: circle 2s linear infinite;
		}
		@keyframes circle {
			0% {
				transform: rotate(0);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
	.disable {
		.iconfont {
			color: #909090;
		}
	}
}
.centerLayout {
	display: flex;
	justify-content: center;
	.paragraph {
		text-indent: 0;
	}
}
.class-length {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 0.72rem;
	border-bottom: 1px #dcdcdc solid;
	margin-bottom: 0.3rem;
	li {
		position: relative;
		line-height: 0.72rem;
		font-size: 0.24rem;
	}
	.active {
		color: #28b295;
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			border-bottom: 1px #28b295 solid;
		}
	}
}
.read-model {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0 0.3rem;
	margin-bottom: 0.3rem;
	li {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 0.64rem;
		min-width: 2.1rem;
		background: #e2f5f1;
		border-radius: 0.32rem;
		font-size: 0.24rem;
		color: #333;
		font-weight: 400;
	}
	.active {
		position: relative;
		background: #28b295;
		color: #fff;
		&::after {
			position: absolute;
			left: 50%;
			top: 0.52rem;
			transform: translateX(-50%);
			font-family: 'iconfont' !important;
			font-style: normal;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: '\e65f';
			font-size: 0.32rem;
			color: #28b295;
		}
	}
}
.recite-box {
	// position: fixed;
	// left: 0;
	// right: 0;
	// top: 0;
	// bottom: 0;
	// z-index: 100000;
	background: #fff;
	display: flex;
	flex-direction: column;
	.recite-text {
		flex: 1;
		overflow: auto;
		p {
			text-indent: 2em;
		}
		.context-main {
			padding: 0.3rem;
			height: calc(100vh - 3rem);
			overflow: auto;
			display: flex;
			align-items: center;
			flex-direction: column;
			overflow-y: scroll;
			.nointent {
				p {
					text-indent: 0;
				}
			}
			.paragraph {
				//width: 100%;
				font-size: 0.24rem;
				line-height: 1.75em;
				//text-indent: 2em;
				padding: 0.1rem 0.3rem;
				.hiddenDom {
					text-align: center;
					font-size: 0.32rem;
					font-weight: bold;
				}
				li {
					margin-bottom: 0.05rem;
					text-align: justify;
					justify-content: space-between;
					.status2 {
						color: #fff;
						border-bottom: 1px #909090 solid;
						cursor: default;
					}
					.status3 {
						color: #f00;
						border-bottom: 1px #909090 solid;
						cursor: default;
					}
				}
			}
		}
	}
	.recite-moudel {
		position: relative;
		height: 1rem;
		width: 100vw;
		display: flex;
		justify-content: space-around;
		align-items: center;
		// box-shadow: 0 0 5px #ccc;
		// padding-right: 70px;
		.close-btn {
			position: absolute;
			right: 0.2rem;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			font-size: 0.6rem;
			text-indent: 0;
			color: #909090;
		}
		.moudel-list {
			width: 100vw;
			display: flex;
			justify-content: space-around;
			align-items: center;
			li {
				font-size: 0.24rem;
				font-weight: 400;
				color: #909090;
			}
			.active {
				position: relative;
				color: #28b295;
				&::after {
					position: absolute;
					left: 50%;
					bottom: -0.08rem;
					transform: translateX(-50%);
					content: '';
					width: 0.5rem;
					height: 0.03rem;
					background: #28b295;
					border-radius: 1px;
				}
			}
		}
	}
}
#classContent{
	    padding: 0 .3rem 1.8rem .3rem;
	    height: 100%;
	    overflow-y: auto;
	    scroll-behavior: smooth;
}
.common-page {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	padding-top: 1.28rem;
	background: #f5f5f5;
	.class-title {
		text-align: center;
		font-size: 0.32rem;
		font-weight: bold;
		color: #333;
	}
	.class-title.active {
		color: #f00;
	}
	.class-author {
		display: flex;
		justify-content: center;
		font-size: 0.24rem;
		margin-bottom: 0.3rem;
	}
	.class-author.active {
		color: #f00;
	}
	.class-content {
		flex: 1;
		width: 100%;
		// padding: 0.3rem 0.3rem 1.5rem 0.3rem;
		font-size: 0.26rem;
		line-height: 1.6;
		text-indent: 2em;
		overflow: auto;
		text-align: justify;
		justify-content: space-around;
		.hiddenDom {
			height: 0;
			overflow: hidden;
			opacity: 0;
			visibility: hidden;
		}
		span.active {
			color: #ff0000 !important;
			span {
				color: #ff0000 !important;
			}
		}
	}
	.text-area {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		background: #fff;
		padding-top: 0.3rem;
	}
	.audio-item {
		width: 0;
		height: 0;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
}
</style>
