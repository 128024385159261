<template>
	<div>
		<navBar @goBack="goBack">
			<span class="title">
				{{ currentTitle }}
			</span>
			<template v-slot:right>
				<span
					style="color: #333; font-size: 18px"
					class="menu-icon iconfont icon-mulu"
					:class="{ active: activeMenu }"
					@click="showMenu"
				></span>
			</template>
		</navBar>
	<div class="page-box hasnav notab">
		
		<loading-box v-if="isLoading"></loading-box>
		<template v-else>
			<ul class="course-outline" v-if="bookList && bookList.length">
				<li v-for="(item, index) in bookList" :key="`a${index}`" @click="goNext(item.name, item.id,item.is_grounding)">
					<div class="li-box">
						<van-image class="img"  :src="setSourceUrl(item.h5icon)"></van-image>
						<p>{{ item.name }}</p>
					</div>
				</li>
			</ul>
			<coustm-empty v-else></coustm-empty>
		</template>
		<customPicker
			v-if="activeMenu"
			v-model="currentVersion"
			:pickerData="pickerData"
			name="bookversionname"
			@close="closePicker"
			@selected="selected"
		></customPicker>
	</div>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import customPicker from '@/components/customPicker.vue';
import LoadingBox from '@/components/loadingBox.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
export default {
	name: 'zhiShiQuanJie',
	components: {
		customPicker,
		LoadingBox,
		CoustmEmpty
	},
	computed: {},
	data() {
		return {
			isLoading: true,
			currentTitle: this.$route.query.moudleName,
			currentId: this.$route.query.moudleId,
			activeMenu: false,
			currentVersion: '',
			pickerData: [],
			bookList: [],
			userPress: []
		};
	},
	created() {
		this.bookList = [];
		this.currentVersion = window.sessionStorage.getItem(this.$route.query.subjectName);
		let bookData = window.sessionStorage.getItem(`${this.$route.query.subjectId}-${this.currentVersion}`);
		//console.log(bookData);
		if (!bookData) {
			this.initBook();
		} else {
			this.isLoading = false;
			this.bookList = JSON.parse(bookData);
			this.userPress = JSON.parse(window.sessionStorage.getItem(`${this.$route.query.subjectName}-用户版本`));
			let versionData = JSON.parse(window.sessionStorage.getItem(`${this.$route.query.subjectName}-版本`));
			let targetData = [];
			for (let i = 0; i < versionData.length; i++) {
				if (this.userPress.includes(String(versionData[i].versionId))) {
					targetData.push({
						id: versionData[i].id,
						versionId: versionData[i].versionId,
						bookversionname: versionData[i].name
					});
				}
			}
			//console.log(targetData);
			this.pickerData = targetData;
		}
	},
	mounted() {
		window.sessionStorage.setItem('menuScrollTop', 0);
	},
	methods: {
		setSourceUrl,
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					...this.$route.query
				}
			});
		},
		initBook() {
			this.getBookVersion();
		},
		getBook() {
			//获取用户版本下的课本

			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: this.currentVersion
				})
				.then((res) => {
					let resData = res.data || [];
					this.isLoading = false;
					this.bookList = resData;
					window.sessionStorage.setItem(
						`${this.$route.query.subjectId}-${this.currentVersion}`,
						JSON.stringify(resData)
					);
				});
		},
		getTypeCourse(params) {
			//获取所有版本
			this.$api.getMenuById(params).then((res) => {
				let resData = res?.data || [];
				let targetData = [];
				window.sessionStorage.setItem(`${this.$route.query.subjectName}-版本`, JSON.stringify(resData));
				for (let i = 0; i < resData.length; i++) {
					if (this.userPress.includes(String(resData[i].versionId))) {
						targetData.push({
							id: resData[i].id,
							versionId: resData[i].versionId,
							bookversionname: resData[i].name
						});
					}
				}
				if (!this.currentVersion && targetData.length) {
					this.currentVersion = targetData[0].id;
					window.sessionStorage.setItem(this.$route.query.subjectName, this.currentVersion);
					this.pickerData = targetData;
					this.getBook();
				}
				this.isLoading = true;
			});
		},
		showMenu() {
			this.activeMenu = true;
		},
		selected(val) {
			this.currentVersion = val;
			window.sessionStorage.setItem(this.$route.query.subjectName, this.currentVersion);
			this.getBook();
		},
		getBookVersion() {
			//获取用户选取的所有版本
			this.isLoading = true;
			this.$api
				.getUserPress({
					subject_id: this.$route.query.subjectId
				})
				.then((res) => {
					this.userPress = res.data[0].pressid;
					window.sessionStorage.setItem(
						`${this.$route.query.subjectName}-用户版本`,
						JSON.stringify(res.data[0].pressid)
					);
					
					this.getTypeCourse({
						subject_id: this.$route.query.subjectId,
						pid: this.$route.query.moudleId
					});
				});
		},
		closePicker() {
			this.activeMenu = false;
		},
		goNext(name, id,is_grounding) {
			this.$router.push({
				path: '/bookContent',
				query: {
					...this.$route.query,
					bookId: id,
					bookName: name,
					is_grounding: is_grounding,
					from: 'zhiShiQuanJie'
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.page-content {
	min-height: 100vh;
}
.page-box.hasnav {
   background-color: #f5f5f5;
}
.course-outline {
	padding: 0.42rem 0 0 0;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	// border-top: 0.2rem solid #f5f5f5;
	li {
		display: flex;
		flex-direction: column;
		margin-bottom: 0.4rem;
		margin-left: 0.3rem;
		.img {
			width: 2.1rem;
			height: 2.86rem;
			border-radius: 0.1rem;
			// margin-bottom: 0.27rem;
			overflow: hidden;
		}
		p {
			width: 2.1rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: center;
			font-size: 0.28rem;
			font-weight: 400;
			color: #333;
		}
	}
}
</style>
