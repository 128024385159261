<template>
	<div class="page-box">
		<loading-box v-if="!endLoad"></loading-box>
		<template v-for="(item, index) in bookList">
			<div class="book-grid" :key="`a${index}`" v-if="endLoad && item.children.length">
				<div class="grid-header">
					<div class="grid-tit" :style="`backgroundImage:url(${titBg})`">
						<div class="ctit">
							{{ item.title }}
						</div>
					</div>
					<div class="more" @click="goNext(item.id, item.title)">
						<span>查看更多</span>
						<van-icon name="arrow" class="icon" />
					</div>
				</div>
				<div class="grid-body">
					<ul class="book-list">
						<li
							v-for="(book, index) in item.children"
							:key="`b${index}`"
							@click="goDetail(book.id, book.title)"
						>
							<van-image fit="cover" class="book-img" :src="setSourceUrl(book.image)"></van-image>
							<div class="book-infs">
								<p class="book-name">{{ book.title }}</p>
								<p class="book-author">
									{{ book.author }}
									<!-- &nbsp;&nbsp;|&nbsp;&nbsp;阅读人数：{{
book.num
}} -->
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import loadingBox from '@/components/loadingBox.vue';
export default {
	components: { loadingBox },
	name: 'libraryPage',
	watch: {
		acount: function (val) {
			if (val == this.requestLen) {
				this.endLoad = true;
			}
		}
	},
	data() {
		return {
			endLoad: false,
			titBg: require('@/assets/img/biaoti.png'),
			requestLen: 0,
			acount: 0,
			bookList: [],
			defaultId: 1000
		};
	},
	created() {
		this.getLibraryData();
	},
	methods: {
		setSourceUrl,
		getLibraryData() {
			this.$api.getLabraryClass().then((res) => {
				if (res.data) {
					let resData = res.data || [];
					this.requestLen = resData.length;
					for (let i = 0; i < resData.length; i++) {
						let _bookMoudle = { ...resData[i] };
						this.$api
							.getLabraryBook({
								bookclass_id: res.data[i].id,
								limit: 6
							})
							.then((res) => {
								this.acount = ++this.acount;
								_bookMoudle.children = res.data || [];
								this.$set(this.bookList, i, _bookMoudle);
							});
					}
				}
			});
		},
		goDetail(id, name) {
			this.$router.push({
				path: '/bookDetail',
				query: {
					id: id,
					name: name,
					from: 'library'
				}
			});
		},
		goNext(id, title) {
			this.$router.push({
				path: '/libraryList',
				query: {
					id: id,
					name: title,
					from: 'library'
				}
			});
		}
	}
};
</script>
<style lang="scss">
.nothing {
	padding: 0.1rem 0;
	.van-empty__image {
		width: 40.1rem;
		height: 40.1rem;
	}
	.van-empty__description {
		font-size: 0.24rem;
	}
}
</style>
<style lang="scss" scoped>
.page-box {
	.book-grid {
		background: #fff;
		padding:  0.3rem 0.3rem 0.3rem;
		border-radius: 0.1rem;
		.grid-body {
			.book-list {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				li {
					width: 33.33%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-bottom: 0.2rem;
					&:first-child {
						margin-left: 0;
					}
					.book-img {
						width: 1.92rem;
						height: 2.6rem;
						margin: 0 auto 0.26rem auto;
						border-radius: 0.1rem;
						overflow: hidden;
					}
					.book-name {
						width: 100%;
						font-size: 0.28rem;
						color: #333;
						line-height: 1;
						font-weight: 400;
						margin-bottom: 0.1rem;
						text-align: center;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.book-author {
						width: 100%;

						text-align: center;
						font-size: 0.24rem;
						color: #909090;
						font-weight: 400;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.book-infs {
					width: 100%;
				}
				.book-img {
					width: 110px;
					height: 1.72rem;
					margin-right: 0.3rem;
				}
			}
		}
		.grid-header {
			line-height: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.32rem;
			.grid-tit {
				color: #333;
				font-size: 0.32rem;
				font-weight: 500;
				height: 0.46rem;
				min-width: 2.26rem;
				line-height: 0.46rem;
				background-size: 2.26rem 0.46rem;
				background-repeat: no-repeat;
				background-position: left bottom;
				padding-left: 0.48rem;
				.ctit {
					height: 100%;
					background-repeat: no-repeat;
					background-position: top right;
					font-size: 0.3rem;
					font-weight: 500;
					color: #333;
				}
			}

			.more {
				display: flex;
				align-items: center;
				color: #909090;
				span {
					font-size: 0.24rem;
					font-weight: 400;
				}
				.icon {
					color: #909090;
					font-size: 0.24rem;
				}
			}
			.last-tit {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.icon {
					width: 20px;
					height: 20px;
					margin-right: 0 10px;
				}
				span {
					color: #333;
					font-size: 0.28rem;
				}
			}
		}
		&:last-child {
			margin: 0;
		}
		.last {
			margin-bottom: 17px;
			.more {
				margin-bottom: 0;
			}
		}
	}
}
</style>
