<template>
	<div class="children-page">
		<navBar @goBack="gobackChapter">
			<span class="title">通假释义</span>
		</navBar>
		<baseInput></baseInput>
	</div>
</template>

<script>
export default {
	name: 'tongJiaShiYi',
	components: {
		baseInput: () => import('../components/baseInput.vue')
	},
	data() {
		return {};
	},
	created() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
.children-page {
	width: 100%;
	height: 100%;
}
</style>
