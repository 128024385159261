<template>
<div class="question-page">
<navBar @goBack="gobackChapter">
<span class="title">期末测试</span>
</navBar>
<questionContent></questionContent>
</div>
</template>

<script>
export default {
name: "danYuanCeShi",
components:{
questionContent:()=>import('@/views/channel/components/questionContent.vue')
},
data() {
return {
currentTitle:this.$route.query.channelName
};
},
created() {},
methods: {

},
};
</script>

<style lang="scss" scoped>
.question-page {
width: 100%;
height: 100%;
}
</style>