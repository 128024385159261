<template>
	<div>
		<navBar @goBack="goBack">
			<span class="title">
				{{ currentTitle }}
			</span>
		</navBar>
	<div class="page-box hasnav notab" id="scroll-menu">
		

		<loading-box v-if="!showMenu"></loading-box>
		<menuPage fromPage="bookContent" :menuData="dataList" v-if="showMenu&&isbookName"></menuPage>
		<coustm-empty v-else></coustm-empty>
	</div>
	</div>
</template>

<script>
import menuPage from '@/components/treeMenu/menuPage.vue';
import { deepClone } from '@/utils/public';
export default {
	name: 'bookContent',
	components: { menuPage },
	watch: {
		accountRequest: function (val) {
			if (val == this.totalRequest) {
				this.showMenu = true;
			}
		}
	},
	data() {
		return {
			currentTitle: this.$route.query.bookName,
			currentId: this.$route.query.bookId,
			menuData: [],
			navType: 0,
			dataList: [],
			showMenu: false,
			totalRequest: -100,
			accountRequest: 0,
			isbookName:true
		};
	},
	
	created() {
		if(this.$route.query.is_grounding==0){
				this.isbookName = false;
				this.showMenu = true;
				return 
		}
		this.dataList = this.$store.getters["common/getBookContent"];
		if (this.dataList.length == 0) {
		  this.initData();
		}else{
			this.showMenu = true;
		}
	},
	mounted() {
		let scrollMenu = window.document.getElementById('scroll-menu');
		scrollMenu.addEventListener('scroll', () => {
			let scrollTop = scrollMenu.scrollTop;
			window.sessionStorage.setItem('menuScrollTop', scrollTop);
		});
		let scrollTop = window.sessionStorage.getItem('menuScrollTop');
		this.$nextTick(() => {
			if (scrollTop) {
				scrollMenu.scrollTo({
					top: scrollTop,
					behavior: 'auto'
				});
			}
		});
		window.addEventListener('popstate',this.clertData,false);
	},
	destroyed() {
		window.removeEventListener('popstate',this.clertData,false);
	},
	methods: {
		deepClone,
		goBack() {
			let query = this.$route.query;
			delete query.bookId;
			delete query.bookName;
			this.$store.commit("common/setBookContent", []);
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					...query,
					from: 'channel'
				}
			});
		},
		clertData(){
			this.$store.commit("common/setBookContent", []);
		},
		initData() {
			this.$api
				.getCourseList({
					subject_id: this.$route.query.subjectId,
					id: this.$route.query.bookId
				})
				.then((res) => {
					let resData = res.data || [];
					resData.forEach((item) => {
					  let idx = 0;
					  item.index = idx;
					  item.showChild = true;
					  this.forEachData(item, idx);
					});
					// this.menuData = resData;
					this.$store.commit("common/setBookContent", resData);
					this.dataList = resData;
					this.totalRequest = resData.length;
					this.showMenu = true;
					if (this.totalRequest<= 0) {
						this.isEmpty = true;
					}
					
				});
		},
		forEachData(arr, idx,url,name) {
		  if (arr.children) {
		    ++idx;
		    arr.children.forEach((item1) => {
				
		      item1.index = idx;
		      item1.showChild = false;
		      item1.url = item1.url?item1.url:url;
		      item1.name = item1.name?item1.name:name;
		      if (item1.children) {
		        this.forEachData(item1, idx,item1.url?item1.url:url,item1.name?item1.name:name);
		      }
		    });
		  }
		},
		
	}
};
</script>

<style lang="scss" scoped></style>
