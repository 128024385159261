<template>
	<div class="word-shorthand">
		<div class="area-box">
			<div class="scroll-area">
				<div class="area-box-main">
					<div class="word-txt">
						{{ privateData.word }}
					</div>
					<div class="voice-item" v-if="privateData.phonetics" @click.stop="startPlay">
						<span style="padding-right: 5px">{{ privateData.phonetics }}</span>

						<svg-icon
							icon-class="voice"
							class="icon"
							:class="{ 'icon-active': audioPlaying }"
							v-if="dataObj.audio"
						></svg-icon>
					</div>
					<div class="des">
						<div
							v-if="privateData.showResult"
							style="margin-bottom: 0.2rem"
							v-html="privateData.translate"
						></div>
					</div>

					<div class="area-example">
						<van-image fit="cover" class="moudle-img" :src="setSourceUrl(privateData.image)" />

						<div class="prase">
							<p v-html="privateData.liju"></p>
							<p v-if="privateData.showResult" v-html="privateData.lijufy" class="parse-text"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="btns-area">
			<div class="btn change" @click="changeQuestion(1)" v-if="nextBtn">正确</div>
			<div class="btn yizhangwo" :class="{ disable: unKnown }" @click="showResult" v-else>已掌握</div>
			<div class="btn burenshi" :class="{ disable: unKnown }" @click="changeQuestion(2)">
				{{ nextBtn ? '错误' : '不认识' }}
			</div>

			<div v-if="showViewAll" class="btn view-all" @click="viewAll()">查看全部</div>
		</div>

		<div class="audio-box">
			<audio id="audio" ref="audio" controls :src="setSourceUrl(audioSrc)" @ended="ended">
				您的浏览器不支持 audio 标签。
			</audio>
		</div>
	</div>
</template>

<script>
import { deepClone, setSourceUrl, html2string } from '@/utils/public';
export default {
	name: 'danCiSuJi',
	props: {
		dataObj: {
			type: Object,
			default: () => {
				return {};
			}
		},
		lengthNum: {
			type: [String, Number],
			default: 0
		},
		currentIndexParent: {
			type: [String, Number],
			default: 0
		},
		typeParent: {
			type: [String, Number],
			default: 0
		},
		active: {
			type: [String, Number],
			default: 0
		},
		
	},
	watch: {
		dataObj: {
			handler: function (val) {
				this.privateData = this.deepClone(val);
				this.audioSrc = this.privateData.audio;
				setTimeout(() => {
					this.startPlay();
				}, 100);
			},
			immediate: true
		},
		lengthNum: {
			handler: function (val) {
				this.totalNum = val;
			},
			immediate: true
		}
	},
	data() {
		return {
			btnsImg: require('@/assets/image/img2.png'),
			privateData: {},
			audioPlaying: false,
			totalNum: 0,
			inputIndex: 0,
			currentIndex: this.currentIndexParent,
			audioSrc: '',
			nextBtn: false,
			unKnown: false,
			showViewAll: false
		};
	},
	created() {
		this.addHistory();
	},
	mounted() {},
	methods: {
		deepClone,
		setSourceUrl,
		html2string,
		viewAll() {
			this.$eventBus.$emit('viewAllWords');
		},
		showResult() {
			if (this.unKnown) {
				return false;
			}
			if (this.currentIndex >= this.totalNum - 1) {
				let that = this;
				this.$dialog
					.confirm({
						message: '已是最后一个，再做一次？'
					})
					.then(() => {
						that.currentIndex = 0;
						that.nextBtn = false;
						that.audioPlaying = false;
						that.$eventBus.$emit('repeatOperate');
					}).catch(() => {});
			}
			this.$set(this.privateData, 'showResult', true);
			this.nextBtn = true;
			//this.startPlay();
		},
		changeQuestion(n) {
			if (this.unKnown) {
				return false;
			}
			if (n == 1) {
				if (this.currentIndex < this.totalNum - 1) {
					let apiData = {
						type: this.typeParent,
						num: this.currentIndex,
						n:2
					};
					this.currentIndex = ++this.currentIndex;
					this.nextBtn = false;
					this.$eventBus.$emit('masterWord', apiData);
					this.audioSrc = '';
					setTimeout(() => {
						this.audioSrc = this.privateData.audio;
						if (this.$refs.audio) {
							this.$refs.audio.pause();
						}
						this.audioPlaying = false;
					}, 10);
					
				} else {
					let that = this;
					that.$confirm('已是最后一个，再做一次？', {
						showClose: false,
						callback: function (val) {
							if (val == 'confirm') {
								that.currentIndex = 0;
								that.nextBtn = false;
								that.audioPlaying = false;
								that.$eventBus.$emit('repeatOperate');
							}
						}
					});
				}
			}
			if (n == 2) {
				this.$set(this.privateData, 'showResult', true);
				if (!this.nextBtn) {
					this.unKnown = true;
					this.startPlay();
					setTimeout(() => {
						this.nextBtn = false;
						this.unKnown = false;
						this.$eventBus.$emit('unKnowWord', this.currentIndex);
					}, 3000);
				} else {
					this.nextBtn = false;
					this.$eventBus.$emit('unKnowWord', this.currentIndex);
				}
			}
		},

		startPlay() {
			if (this.$refs.audio) {
				this.$refs.audio.play();
				this.audioPlaying = true;
			}
		},
		ended() {
			this.audioPlaying = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.scroll-area {
	padding-right: 15px;
	height: calc(100% - 0.6rem);
	overflow: auto;
}

.area-box-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.word-txt {
	position: relative;
	z-index: 2;
	font-size: 30px !important;
	margin-bottom: 20px;
	&::before {
		position: absolute;
		left: 0;
		right: 0;
		bottom: -8px;
		content: '';
		height: 8px;
		background: #e1f5f1;
	}
}
.voice-item {
	display: flex;
	align-items: center;
	height: 32px;
	border-radius: 16px;
	padding: 8px 10px;
	font-size: 14px;
	color: #323233;
	background: #f4f5f7;
	margin-bottom: 15px;
	.icon-active {
		color: #009b7c;
	}
}

.word-shorthand {
	height: 100%;

	.des {
		font-size: 14px;
		line-height: 1.75em;
		color: #ff0000;
		margin-bottom: 15px;
	}
	.prase {
		font-weight: 400;
		.parse-text {
			color: #ff0000;
		}
	}
	.area-example {
		font-size: 14px;
		font-weight: 400;
		color: #333;
		.prase {
			font-size: 14px;
			line-height: 1.75em;
		}
	}
	.area-box {
		width: 100%;
		height: calc(100% - 50px);
		border: 1px solid #dcdcdc;
		border-radius: 5px;
		padding: 15px 0 15px 15px;
		.moudle-img {
			width: 100%;
			height: 240px;
			background: #fff;
			margin-bottom: 15px;
		}
	}
	.btns-area {
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		user-select: none;
		.btn {
			width: 48%;
			height: 40px;
			border-radius: 20px;
			text-align: center;
			line-height: 40px;
			font-size: 15px;
			font-weight: 500;
			color: #fff;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
		.yizhangwo {
			background: #27b295;
		}
		.burenshi {
			background: #ff6363;
		}
		.change {
			background: #27b295;
		}
		.disable {
			opacity: 0.8;
		}
		.view-all {
			background: #009b7c;
		}
	}
}
.audio-box {
	width: 0;
	height: 0;
	overflow: hidden;
}
</style>
