// import "babel-polyfill"

import Vue from "vue"
import App from "./App.vue"

import router from "./router"
import store from "./store"
import axios from "axios"
import VueAxios from "vue-axios"
import api from "@/api/index.js"
import Vant from "vant"
import vueg from "vueg"
import "vant/lib/index.css"
import "./icons/index.js"
import MathJax from "@/utils/MathJax"
Vue.prototype.MathJax = MathJax
import navBar from "@/components/navBar"
import coustmEmpty from "@/components/coustmEmpty.vue"
import loadingBox from "@/components/loadingBox"
// import vConsole from 'vconsole'
Vue.use(VueAxios, axios)
Vue.use(Vant)
Vue.use(vueg, router, { shadow: false })
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$eventBus = new Vue()
// Vue.prototype.$vConsole = new vConsole()
Vue.config.productionTip = false

Vue.component("navBar", navBar)
Vue.component("coustmEmpty", coustmEmpty)
Vue.component("loadingBox", loadingBox)

import device from "current-device"
import "@/icons"
if (device.mobile()) {
  //console.log("移动手机");
} else if (device.ipad()) {
  window.location.href = "https://gz.jingdian985.com/#/?active=0"
} else if (device.desktop()) {
  window.location.href = "https://gz.jingdian985.com/#/?active=0"
}
if (device.ios()) {
  window.localStorage.setItem("device", "ios")
}
if (device.android()) {
  window.localStorage.setItem("device", "android")
}
import globalMethods from "@/mixins/globalMethods"
Vue.mixin(globalMethods)

// 若是没有开启Devtools工具，在开发环境中开启，在生产环境中关闭
if (process.env.NODE_ENV == "development") {
  Vue.config.devtools = true
} else {
  Vue.config.devtools = false
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
