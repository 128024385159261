<template>
	<div class="question-content" :class="{'question-content-yingyu':channelName.indexOf('高频词汇') != -1}">
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<div class="contant-main" v-if="!isEmpty">
				<div class="acount-question">
					<span>{{ questionData[currentQuestion].questionIndex }}</span>
					/{{ questionNumber }}
				</div>
				<div class="proress" v-if="channelName!='听力理解'">
					<div
						class="con"
						:style="`width:${(questionData[currentQuestion].questionIndex / questionNumber) * 100}%`"
					></div>
				</div>

				<div class="question-area">
					<div class="area content-area" v-if="questionData[currentQuestion].content">
						<div class="con-box" v-html="questionData[currentQuestion].content"></div>
					</div>
					<div class="area main-area" :class="{ show: showQuestion, hide: !showQuestion }">
						<div class="box question-body">
							<div class="stem" v-html="questionData[currentQuestion].stem"></div>
							<div class="sigin-choose" v-if="questionData[currentQuestion].classtext < 3">
								<ul class="options" id="options" ref="options">
									<li
										v-for="(opt, index) in questionData[currentQuestion].option"
										:key="index"
										:class="{
											active: questionData[currentQuestion].userAnswer.includes(index),
											proper:
												(questionData[currentQuestion].option[index].status == 1 &&
													questionData[currentQuestion].userAnswer.includes(index)) ||
												(html2string(questionData[currentQuestion].answer).includes(
													optionsTag[index]
												) &&
													questionData[currentQuestion].showAnalysis),
											error:
												questionData[currentQuestion].option[index].status == 2 &&
												questionData[currentQuestion].userAnswer.includes(index)
										}"
										@click="selectAction(index, questionData[currentQuestion].classtext)"
									>
										<span v-html="htmlLabel(opt.label)"></span>
									</li>
								</ul>
							</div>

							<div class="fill-blanks" v-if="questionData[currentQuestion].classtext == 3">
								<textarea
									class="user-textarea"
									:rows="5"
									v-model="questionData[currentQuestion].userAnswer"
								/>
							</div>
						</div>
						<div class="box question-operate" v-if="questionData[currentQuestion].showAnalysis">
							<ul class="result-list">
								<li>
									<span class="label-for">【正确答案】</span>
									<span class="label-text" v-html="questionData[currentQuestion].answer"></span>
								</li>
								<li>
									<span class="label-for">【您的答案】</span>
									<span class="label-text" v-if="questionData[currentQuestion].classtext < 3">
										{{ questionData[currentQuestion].userAnswerTag || '暂未作答' }}
									</span>
									<span class="label-text" v-else>
										{{ questionData[currentQuestion].userAnswer || '暂未作答' }}
									</span>
								</li>
								<li>
									<span class="label-for">【答案解析】</span>
									<span
										class="label-text"
										v-html="questionData[currentQuestion].analysis"
										v-if="questionData[currentQuestion].analysis"
									></span>
									<span class="label-text" v-else>略</span>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="question-handle">
					<div class="btn" @click="changeQuestion(1)">上一题</div>
					<div class="btn" @click="changeQuestion(2)">下一题</div>
					<div class="btn" v-if="questionData[currentQuestion].audio" @click="playAudio">
						{{ tingliPlaying ? '暂停' : '播放' }}
					</div>
					<div class="btn submit-btn jiexi-btn" @click="analysisAction">查看解析</div>
				</div>

				<div class="audio-palyer">
					<div class="custom-audio">
						<div class="play-btn" @click="playAction">
							<i :class="`iconfont ${isPlaying ? 'icon-zanting' : 'icon-bofang'}`"></i>
						</div>
						<div class="play-progress">
							<!-- <van-slider
								@drag-start="changeStart"
								@input="changeMove"
								@change="changeAction"
								:max="timeMax"
								:disabled="moudleActive == 1"
								v-model="progress"
							/> -->
						</div>
						<div class="play-tiem">{{ totalTime }}</div>
					</div>
				</div>
			</div>

			<coustm-empty v-else></coustm-empty>
		</template>
		<audio
		  :src="errorVoice"
		  controlslist="nodownload"
		  controls="controls"
		  ref="errorAudio"
		  class="status-vioce"
		></audio>
		
		<audio
		  :src="properVoice"
		  controlslist="nodownload"
		  controls="controls"
		  ref="properAudio"
		  class="status-vioce"
		></audio>
		<audio
			:src="setSourceUrl(tingliAudio)"
			controlslist="nodownload"
			controls="controls"
			ref="tingliPlayer"
			class="status-vioce"
			@loadedmetadata="loadedmetadata"
			@ended="audioEnd"
		></audio>
	</div>
</template>

<script>
import {
	deepClone,
	getNowTime,
	html2string,
	formatMathJaxImg,
	MathJaxLabelImg,
	formatAddPointWords,
	setSourceUrl
} from '@/utils/public';
export default {
	name: 'questionList',
	computed: {
		currentScore: function () {
			return this.questionData[this.currentQuestion]?.score || '*';
		}
	},
	watch: {
		currentQuestion: function (val) {
			this.tingliAudio = this.questionData[val].audio;
		}
	},
	data() {
		return {
			channelName:this.$route.query.channelName,
			errorVoice: require("@/assets/audio/error.mp3"),
			properVoice: require("@/assets/audio/proper.mp3"),
			properAudio: null,
			errorAudio: null,
			isLoading: false,
			isEmpty: false,
			showQuestion: false,
			questionSourse: [],
			questionData: [],
			currentQuestion: 0,

			questionNumber: 0,
			optionsTag: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],

			tingliAudio: '',
			tingliPlayer: null,
			isPlaying: false,
			tingliPlaying: false,
			isCanplay: false,
			timeMax: 0,
			currentTime: '00:00:00',
			totalTime: '--:--:--'
		};
	},
	created() {
		this.initData();
		this.addHistory();
	},
	mounted() {
		this.tingliPlayer = this.$refs['tingliPlayer'];
		this.properAudio = this.$refs["properAudio"];
		this.errorAudio = this.$refs["errorAudio"];
	},
	methods: {
		deepClone,
		getNowTime,
		html2string,
		formatMathJaxImg,
		MathJaxLabelImg,
		formatAddPointWords,
		setSourceUrl,
		audioEnd() {
			this.tingliPlaying = false;
		},
		loadedmetadata() {
			this.tingliPlaying = false;
			this.isCanplay = true;
		},
		playAudio() {
			if (!this.isCanplay) {
				this.$notify({
					type: 'warning',
					message: '音频加载中...'
				});
				return false;
			}

			if (this.tingliPlaying) {
				this.tingliPlayer.pause();
			} else {
				this.tingliPlayer.play();
			}
			this.tingliPlaying = !this.tingliPlaying;
		},
		setMathJaxText() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
			setTimeout(() => {
				this.showQuestion = true;
			}, 200);
		},
		analysisAction() {
			let showAnalysis = this.questionData[this.currentQuestion].showAnalysis;
			if (showAnalysis) {
				return false;
			}
			this.$set(this.questionData[this.currentQuestion], 'showAnalysis', true);
			let classtext = this.questionData[this.currentQuestion].classtext;
			if (classtext < 3) {
				let answer = this.html2string(this.questionData[this.currentQuestion].answer);
				let option = this.questionData[this.currentQuestion].option;
				for (let i = 0; i < option.length; i++) {
					if (answer.includes(this.optionsTag[i])) {
						option[i].status = 1;
					} else {
						option[i].status = 2;
					}
				}
				this.$set(this.questionData[this.currentQuestion], 'option', option);
			}
			this.checkErrorToAdd();
			setTimeout(() => {
				this.setMathJaxText();
			}, 0);
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuQuestion({
					course_id:
						this.$route.query.childId ||
						this.$route.query.channelId ||
						this.$route.query.classId ||
						this.$route.query.unitId
				})
				.then((res) => {
					let resData = res.data || [];

					if (resData.length) {
						let resetData = resData[0];
						let children = resetData.children || [];
						for (let i = 0; i < children.length; i++) {
							children[i].questionIndex = i + 1;
						}
						resetData.children = children;
						resetData = this.resetQuestion(resData[0]);
						if (resetData.length) {
							resetData.forEach((item) => {
								item.option = this.computedOptions(item.option);
								if (item.classtext < 3) {
									item.userAnswer = [];
								} else {
									item.userAnswer = '';
								}
								item.userAnswerTag = '';
								item.content = this.formatMathJaxImg(item.content);
								console.log(item.stem,'未处理')
								item.stem = this.formatMathJaxImg(item.stem);
								console.log(item.stem,'formatMathJaxImg处理')
								item.answer = this.formatMathJaxImg(item.answer);
								item.analysis = this.formatMathJaxImg(item.analysis);

								item.content = this.formatAddPointWords(item.content);
								item.stem = this.formatAddPointWords(item.stem);
								console.log(item.stem,'formatAddPointWords处理')
								item.answer = this.formatAddPointWords(item.answer);
								item.analysis = this.formatAddPointWords(item.analysis);
							});
							this.questionData = resetData;
							this.questionSourse = this.deepClone(resetData);
							this.totalScore = resData[0].score;
							setTimeout(() => {
								this.setMathJaxText();
								this.tingliAudio = this.questionData[0].audio;
							}, 10);
						} else {
							this.isEmpty = true;
						}
					} else {
						this.isEmpty = true;
					}
					this.isLoading = false;
				});
		},
		resetQuestion(arr) {
			let children = arr.children || [];
			this.questionNumber = children.length;
			for (let i = 0; i < children.length; i++) {
				if (children[i].children) {
					let arr = [];
					let parentIndex = children[i].questionIndex;
					for (let j = 0; j < children[i].children.length; j++) {
						let child = children[i].children[j];
						child.content = children[i].content || '';
						child.questionIndex = parentIndex;
						arr.push(child);
					}
					children.splice(i, 1, ...arr);
				}
			}

			return children;
		},

		computedOptions(str) {
			str = str || '';
			str = str.replace(/<ol>/g, '');
			str = str.replace(/<ul>/g, '');
			str = str.replace(/<\/ul>/g, '');
			str = str.replace(/<\/ol>/g, '');
			str = str.replace(/<li>/g, '');
			str = str.replace(/<\/li>/g, '');
			let arr = str.split('\n');
			let targetArr = [];
			for (let i = 0; i < arr.length; i++) {
				if (arr[i]) {
					targetArr.push({
						label: this.formatAddPointWords(this.formatMathJaxImg(arr[i])),
						status: 0
					});
				}
			}
			return targetArr;
		},
		pauseAll() {
			for (let i = 0; i < this.audioObj.length; i++) {
				for (let j = 0; j < this.audioObj[i].length; j++) {
					let audioItem = this.audioObj[i][j];
					audioItem.currentTime = 0;
					audioItem.pause();
				}
			}
		},
		changeStart() {
			this.pauseAll();
		},
		changeMove(val) {
			this.pauseAll();
			this.audioTime = this.formatTime(Math.floor(this.timeNum - val));
		},
		selectAction(index, type) {
			let showAnalysis = this.questionData[this.currentQuestion].showAnalysis;
			if (showAnalysis) {
				return false;
			}
			let question = this.questionData[this.currentQuestion];
			let userAnswer = question.userAnswer || [];

			let idxof = userAnswer.indexOf(index);
			if (type == 1) {
				userAnswer = [];
			}
			if (userAnswer.includes(index)) {
				userAnswer.splice(idxof, 1);
			} else {
				if (type == 1) {
					userAnswer = [index];
				}
				if (type == 2) {
					if (idxof < 0) {
						userAnswer.push(index);
					}
				}
			}
			question.userAnswer = userAnswer.sort();
			let _userAnswerTag = '';
			for (let i = 0; i < question.userAnswer.length; i++) {
				_userAnswerTag = _userAnswerTag + this.optionsTag[question.userAnswer[i]];
			}

			question.userAnswerTag = _userAnswerTag;
			this.$set(this.questionData, this.currentQuestion, question);
		},
		htmlLabel(html) {
			return MathJaxLabelImg(html);
		},
		changeQuestion(n) {
			let that = this;
			if (n == 1) {
				if (0 < this.currentQuestion) {
					this.currentQuestion = --this.currentQuestion;
					this.showQuestion = false;
				} else {
					this.$notify({
						type: 'warning',
						message: '已经是第一道题了'
					});
				}
			}
			if (n == 2) {
				if (this.currentQuestion < this.questionData.length - 1) {
					this.currentQuestion = ++this.currentQuestion;
					this.showQuestion = false;
				} else {
					this.$dialog
						.confirm({
							message: '已是最后一个，再做一次？'
						})
						.then(() => {
							that.currentQuestion = 0;
							that.questionData = that.deepClone(that.questionSourse);
							setTimeout(() => {
								that.setMathJaxText();
							}, 10);
						}).catch(() => {});
				}
			}
			setTimeout(() => {
				this.setMathJaxText();
			}, 10);
		},
		playAction() {
			this.isPlaying = !this.isPlaying;
			if (this.isPlaying) {
				this.currentAudio.play();
			} else {
				this.currentAudio.pause();
			}
		},
		checkErrorToAdd() {
			let activeQuestion = this.questionData[this.currentQuestion];
			let classText = activeQuestion.classtext;
			if (classText < 3) {
				let answer = this.html2string(activeQuestion.answer);
				let userAnswerTag = activeQuestion.userAnswerTag;
				if (answer != userAnswerTag) {
					let currentErrorId = activeQuestion.id;
					this.errorAudio.play();
					this.$api
						.addErrorQuestion({
							subject_id: this.$route.query.subjectId,
							questionbank_id: currentErrorId
						})
						.then(() => {
							//console.log(res);
						});
				}else{
					this.properAudio.play();
				}
			}
		}
	}
};
</script>

<style lang="scss">
	.question-content-yingyu{
		img {
			width: 1.3rem !important;
		}
	}
.question-content {
	img {
		vertical-align: middle;
	}
	
	em {
		position: relative;
		font-style: normal;
		&::after {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 100%);
			content: '';
			width: 4px;
			height: 4px;
			background: #333;
			border-radius: 50%;
		}
	}
}
.question-body {
	* {
		sub,
		sup {
			font-size: 0.5em !important;
		}
	}
	.stem {
		line-height: 1.6 !important;
		font-size: 0.3rem !important;
		font-family: initial !important;
		margin-bottom: 0.3rem;
	}
	.options {
			li {
				position: relative;
				padding: 0.3rem 0.3rem 0.3rem 40px;
				margin: 0.3rem 0;
				cursor: default;
				background: #f8f8f8;
				border-radius: 5px;
				font-size: 0.28rem;
				&::after {
					position: absolute;
					left: 0.3rem;
					top: 50%;
					transform: translateY(-50%);
					padding-right: 5px;
				}
				&::before {
					position: absolute;
					left: 30px;
					top: 50%;
					transform: translateY(-50%);
					padding-right: 5px;
					content: '';
					height: 10px;
					border-left: 1px #ccc solid;
				}
				&:nth-child(1)::after {
					content: 'A';
				}
				&:nth-child(2)::after {
					content: 'B';
				}
				&:nth-child(3)::after {
					content: 'C';
				}
				&:nth-child(4)::after {
					content: 'D';
				}
				&:nth-child(5)::after {
					content: 'E';
				}
				&:nth-child(6)::after {
					content: 'F';
				}
				&:nth-child(7)::after {
					content: 'G';
				}
				&:nth-child(8)::after {
					content: 'H';
				}
				&:nth-child(9)::after {
					content: 'I';
				}
				&:nth-child(10)::after {
					content: 'J';
				}
			}
			.active {
				background: #e2f5f1;
			}
			.proper {
				background: #1e9e85;
				color: #fff;
			}
			.error {
				color: #fff;
				background: #f00;
			}
		}
	
}
.question-area {
	font-size: 0.3rem;
	color: #2c3e50;
	.content-area {
		font-size: 0.3rem !important;
		font-family: initial !important;
		line-height: 1.6;
		margin-bottom: .25rem;
		padding-bottom: .25rem;
		border-bottom: 1px dashed #dcdcdc;
		p{
			color:#2c3e50
		}
		.con-box {
			font-size: 0.3rem;
			line-height: 1.75em;
		}
		  
	}
}

.fill-blanks {
	display: flex;
	margin-bottom: 0.3rem;
}
</style>

<style lang="scss" scoped>
.user-textarea {
	margin-top: 0.3rem;
	width: 100%;
	padding: 0.3rem;
	border: 1px #dcdcdc solid;
	border-radius: 5px;
	resize: none;
}
.result-list {
	li {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 0.3rem;
		text-align: justify;
		.label-for {
			font-size: 0.26rem;
			line-height: 1.75em;
			color: #1e9e85;
			word-break: keep-all;
			margin-right: 0.3rem;
		}
		.label-text {
			font-size: 0.26rem;
			line-height: 1.75em;
		}
	}
}


.question-area {
	padding: 0.45rem 0.3rem 0.3rem 0.3rem;
	overflow: auto;
	height: calc(100% - 60px);
	transition: all 0 0.3s ease-in-out;
	.area {
		width: 100%;
		margin-bottom: 0.3rem;
	}
	.main-area {
		overflow: auto;
		transition: all 0 0.3s ease-in-out;
	}
	.main-area.show {
		opacity: 1;
	}
	.main-area.hide {
		opacity: 0;
	}
	.flexBox {
		display: flex;
		border-right: 0 !important;
		.box {
			height: 100%;
			&:first-child {
				border-right: 1px #dcdcdc dashed;
			}
		}
	}
}

.question-content {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
	font-size: 0.3rem;
	::v-deep img {
		height: auto !important;
	}
	// padding: 0.3rem;
	.contant-main {
		width: 100%;
		height: 100%;
		background: #fff;
	}
	
}

.custom-audio {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 50px;
	padding: 0 18px;
	border-radius: 25px;
	background: #e2f5f1;
	::v-deep .van-slider {
		background: #fff;
	}
	::v-deep .van-slider__bar {
		background: #28b295;
	}
	::v-deep .van-slider__button {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		border: 1px #28b295 solid;
	}
	.play-progress {
		flex: 1;
		padding: 0 7px;
		.van-slider {
			width: 100%;
		}
	}
	.play-tiem {
		text-align: right;
		font-size: 14px;
		color: #28b295;
	}
	.play-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1;
		border-radius: 50%;
		overflow: hidden;
		width: 32px;
		height: 32px;
		.iconfont {
			font-size: 36px;
			line-height: 1;
			color: #28b295;
		}
		.iconloading {
			font-size: 34px;
			animation: circle 2s linear infinite;
		}
		@keyframes circle {
			0% {
				transform: rotate(0);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
	.disable {
		.iconfont {
			color: #909090;
		}
	}
}


</style>
