<template>
	<div class="question-wrapper" :class="currentSubject">
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<div class="contant-main" v-if="!isEmpty">
				<div
					class="question-item"
					v-for="(item, contentIndex) in questionData"
					:key="`question${contentIndex}`"
				>
					<div class="question-content">
						<span v-html="item.content"></span>
					</div>
					<ul class="question-list">
						<li v-for="(question, questionIndex) in item.children" :key="`item${questionIndex}`">
							<div class="stem" v-html="question.stem"></div>
							<div class="sigin-choose" v-if="question.classtext < 3">
								<ul class="options" id="options" ref="options">
									<li
										v-for="(opt, optionIndex) in question.option"
										:key="`option${optionIndex}`"
										:class="{
											active: question.userAnswer.includes(optionIndex),
											proper:
												(question.option[optionIndex].status == 1 &&
													question.userAnswer.includes(optionIndex)) ||
												(html2string(question.answer).includes(optionsTag[optionIndex]) &&
													question.showAnalysis),
											error:
												question.option[optionIndex].status == 2 &&
												question.userAnswer.includes(optionIndex)
										}"
										@click="
											selectAction(contentIndex, questionIndex, optionIndex, question.classtext)
										"
									>
										<span v-html="opt.label"></span>
									</li>
								</ul>
							</div>

							<div class="fill-blanks" v-if="question.classtext == 3">
								<textarea
									class="user-textarea"
									:rows="5"
									:disabled="question.showAnalysis"
									v-model="question.userAnswer"
								/>
							</div>

							<div class="jiexi-btn-box">
								<div class="btn" @click="jiexiAction(contentIndex, questionIndex)">解析</div>
							</div>

							<ul class="result-list" v-if="question.showAnalysis">
								<li>
									<span class="label-for">【正确答案】</span>
									<span class="label-text" v-html="question.answer"></span>
								</li>
								<li>
									<span class="label-for">【您的答案】</span>
									<span class="label-text" v-if="question.classtext < 3">
										{{ question.userAnswerTag || '暂未作答' }}
									</span>
									<span class="label-text" v-else>{{ question.userAnswer || '暂未作答' }}</span>
								</li>
								<li>
									<span class="label-for">【答案解析】</span>
									<span class="label-text" v-html="question.analysis" v-if="question.analysis"></span>
									<span class="label-text" v-else>略</span>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<coustm-empty v-else></coustm-empty>
		</template>
	</div>
</template>

<script>
import { deepClone, getNowTime, html2string, formatMathJaxImg, formatAddPointWords } from '@/utils/public';
export default {
	name: 'normalQuestion',
	computed: {},
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			questionSourse: [],
			questionData: [],

			optionsTag: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']
		};
	},
	created() {
		this.initData();
		let subjectName = this.$route.query.subjectName;
		switch (subjectName) {
			case '语文': {
				this.currentSubject = 'yuwen';
				break;
			}
			case '数学': {
				this.currentSubject = 'shuxue';
				break;
			}
			case '英语': {
				this.currentSubject = 'yingyu';
				break;
			}
			case '物理': {
				this.currentSubject = 'wuli';
				break;
			}
			case '化学': {
				this.currentSubject = 'huaxue';
				break;
			}
			case '政治': {
				this.currentSubject = 'zhengzhi';
				break;
			}
			case '历史': {
				this.currentSubject = 'lishi';
				break;
			}
			case '生物': {
				this.currentSubject = 'shengwu';
				break;
			}
			case '地理': {
				this.currentSubject = 'dili';
				break;
			}
			default: {
				this.currentSubject = '';
			}
		}
	},
	mounted() {
		this.addHistory();
	},
	methods: {
		deepClone,
		getNowTime,
		html2string,
		formatMathJaxImg,
		formatAddPointWords,
		setMathJaxText() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
		},
		jiexiAction(contentIndex, questionIndex) {
			let targetQuestion = this.questionData[contentIndex].children[questionIndex];
			targetQuestion.showAnalysis = true;
			this.$set(this.questionData[contentIndex].children, questionIndex, targetQuestion);
			this.$set(this.questionData[contentIndex].children[questionIndex], 'showAnalysis', true);
			let classtext = targetQuestion.classtext;
			if (classtext < 3) {
				let answer = this.html2string(targetQuestion.answer);
				let option = targetQuestion.option;
				for (let i = 0; i < option.length; i++) {
					if (answer.includes(this.optionsTag[i])) {
						option[i].status = 1;
					} else {
						option[i].status = 2;
					}
				}
				targetQuestion.option = option;
				this.$set(this.questionData[contentIndex].children, questionIndex, targetQuestion);
			}

			// this.checkErrorToAdd(contentIndex,questionIndex);
			setTimeout(() => {
				this.setMathJaxText();
			}, 0);
		},

		initData() {
			this.isLoading = true;
			this.$api
				.getMenuQuestion({
					course_id:
						this.$route.query.childId ||
						this.$route.query.channelId ||
						this.$route.query.classId ||
						this.$route.query.unitId
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						let resetData = resData[0];
						resetData = resetData.children || [];
						if (resetData.length) {
							resetData.forEach((item) => {
								let children = item.children || [];
								if (children.length) {
									children.forEach((question) => {
										question.option = this.computedOptions(question.option);
										if (question.classtext < 3) {
											question.userAnswer = [];
										} else {
											question.userAnswer = '';
										}
										question.userAnswerTag = '';
										question.content = this.formatMathJaxImg(question.content);
										question.stem = this.formatMathJaxImg(question.stem);
										question.answer = this.formatMathJaxImg(question.answer);
										question.analysis = this.formatMathJaxImg(question.analysis);

										question.content = this.formatAddPointWords(question.content);
										question.stem = this.formatAddPointWords(question.stem);
										question.answer = this.formatAddPointWords(question.answer);
										question.analysis = this.formatAddPointWords(question.analysis);
									});
								}
								item.children = children;
							});
							this.questionData = resetData;
							this.questionSourse = this.deepClone(resetData);
							setTimeout(() => {
								this.setMathJaxText();
							}, 10);
						} else {
							this.isEmpty = true;
						}
					} else {
						this.isEmpty = true;
					}
					this.isLoading = false;
				});
		},

		computedOptions(str) {
			str = str || '';
			str = str.replace(/<ol>/g, '');
			str = str.replace(/<ul>/g, '');
			str = str.replace(/<\/ul>/g, '');
			str = str.replace(/<\/ol>/g, '');
			str = str.replace(/<li>/g, '');
			str = str.replace(/<\/li>/g, '');
			let arr = str.split('\n');
			let targetArr = [];
			for (let i = 0; i < arr.length; i++) {
				if (arr[i]) {
					targetArr.push({
						label: this.formatAddPointWords(this.formatMathJaxImg(arr[i])),
						status: 0
					});
				}
			}
			return targetArr;
		},
		selectAction(contentIndex, questionIndex, optionIndex, type) {
			let targetQuestion = this.questionData[contentIndex].children[questionIndex];
			let showAnalysis = targetQuestion.showAnalysis;
			if (showAnalysis) {
				return false;
			}

			let userAnswer = targetQuestion.userAnswer || [];

			let idxof = userAnswer.indexOf(optionIndex);
			if (type == 1) {
				userAnswer = [];
			}
			if (userAnswer.includes(optionIndex)) {
				userAnswer.splice(idxof, 1);
			} else {
				if (type == 1) {
					userAnswer = [optionIndex];
				}
				if (type == 2) {
					if (idxof < 0) {
						userAnswer.push(optionIndex);
					}
				}
			}
			targetQuestion.userAnswer = userAnswer.sort();
			let _userAnswerTag = '';
			for (let i = 0; i < targetQuestion.userAnswer.length; i++) {
				_userAnswerTag = _userAnswerTag + this.optionsTag[targetQuestion.userAnswer[i]];
			}

			targetQuestion.userAnswerTag = _userAnswerTag;
			this.$set(this.questionData[contentIndex].children, questionIndex, targetQuestion);
		},

		checkErrorToAdd(contentIndex, questionIndex) {
			let activeQuestion = this.questionData[(contentIndex, questionIndex)];
			let classText = activeQuestion.classtext;
			if (classText < 3) {
				let answer = this.html2string(activeQuestion.answer);
				let userAnswerTag = activeQuestion.userAnswerTag;
				if (answer != userAnswerTag) {
					let currentErrorId = activeQuestion.id;
					this.$api
						.addErrorQuestion({
							subject_id: this.$route.query.subjectId,
							questionbank_id: currentErrorId
						})
						.then(() => {
							//console.log(res);
						});
				}
			}
		}
	}
};
</script>

<style lang="scss">
.fill-blanks {
	display: flex;
}
</style>

<style lang="scss" scoped>
.user-textarea {
	margin-top: 0.3rem;
	width: 100%;
	padding: 0.3rem;
	border: 1px #dcdcdc solid;
	border-radius: 0.1rem;
	resize: none;
}
.result-list {
	padding-top: 0.3rem;
	li {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 0.3rem;
		text-align: justify;
		.label-for {
			font-size: 0.24rem;
			line-height: 1.75em;
			color: #1e9e85;
			word-break: keep-all;
			margin-right: 0.3rem;
		}
		.label-text {
			font-size: 0.24rem;
			line-height: 1.75em;
		}
	}
}

.question-wrapper {
	width: 100%;
	height: 100%;
	padding: 0.3rem;
	overflow: auto;
	.contant-main {
		font-size: 0.26rem;
		line-height: 1.75em;
		.question-item {
			margin-bottom: 0.3rem;
		}
		.question-content {
			margin-bottom: 0.3rem;
		}
		.question-list {
			margin-bottom: 0.3rem;
			li {
				margin-bottom: 0.3rem;
			}
			.stem {
				font-size: 0.26rem;
				line-height: 1.75em;
				margin-bottom: 0.2rem
			}
			.options {
				li {
					position: relative;
					padding: 0.3rem 0.3rem 0.3rem 0.8rem;
					margin: 0.3rem 0;
					cursor: default;
					background: #f8f8f8;
					border-radius: 0.1rem;
					&::after {
						position: absolute;
						left: 0.3rem;
						top: 50%;
						transform: translateY(-50%);
						padding-right:0.1rem;
					}
					&::before {
						position: absolute;
						left: 0.6rem;
						top: 50%;
						transform: translateY(-50%);
						padding-right: 0.1rem;
						content: '';
						height: 0.2rem;
						border-left: 1px #ccc solid;
					}
					&:nth-child(1)::after {
						content: 'A';
					}
					&:nth-child(2)::after {
						content: 'B';
					}
					&:nth-child(3)::after {
						content: 'C';
					}
					&:nth-child(4)::after {
						content: 'D';
					}
					&:nth-child(5)::after {
						content: 'E';
					}
					&:nth-child(6)::after {
						content: 'F';
					}
					&:nth-child(7)::after {
						content: 'G';
					}
					&:nth-child(8)::after {
						content: 'H';
					}
					&:nth-child(9)::after {
						content: 'I';
					}
					&:nth-child(10)::after {
						content: 'J';
					}
				}
				.active {
					background: #e2f5f1;
				}
				.proper {
					background: #1e9e85;
					color: #fff;
				}
				.error {
					color: #fff;
					background: #f00;
				}
			}
			.jiexi-btn-box {
				display: flex;
				justify-content: flex-end;
				margin-top: 0.3rem;
				.btn {
					width: 1rem;
					height: 0.32rem;
					background: #32b39a;
					border-radius: 0.16rem;
					font-size: 0.16rem;
					font-weight: 400;
					color: #fff;
					line-height: 0.32rem;
					text-align: center;
					padding: 0;
					border: 0;
					user-select: none;
					cursor: default;
					transition: all 0.3s ease-in-out;
					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}
}
</style>
