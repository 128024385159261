<template>
	<div class="video-box">
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<coustmEmpty v-if="isEmpty"></coustmEmpty>
			<template v-else>
				<div class="vidoe-list-box">
					<ul class="video-list">
						<li
							v-for="(video, index) in videosList"
							:key="index"
							:class="{ hideContrall: video.showMask }"
							style="position: relative"
						>
							<template v-if="video.video">
								<div class="video-item" v-show="video.show">
									<div class="item-main">
										<div class="vidoe-main" :id="`videoItem${index}`"></div>
										<div class="item-mask" v-if="video.showMask" @click="playVideoItem(index)">
											<!-- <img src="@/assets/image/baofang1.png" class="play-btn" /> -->
										</div>
									</div>
									<div class="video-base">
										<!-- <span>{{ totalRequest[index].name }}</span> -->
									</div>
								</div>
								<div
									class="vidoe-loading"
									style="position: absolute; left: 0.36rem; right: 0; top: 0; bottom: 1rem"
									v-if="!video.show"
								>
									<loadingBox></loadingBox>
								</div>
							</template>
							<div class="video-item" v-else>
								<div class="item-main empty-video">
									<p>暂无视频</p>
								</div>
								<div class="video-base">
									<span>{{ video.title }}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import DPlayer from 'dplayer';
import { setSourceUrl } from '@/utils/public';
export default {
	name: 'videoList',
	watch: {
		currentRequest: function (val) {
			// if (val == this.totalRequest.length) {
				this.isLoading = false;
				this.$nextTick(() => {
					for (let i = 0; i < val; i++) {
						let cid = `videoItem${i}`;
						let videoMask = '';
						let moudleName = window.sessionStorage.getItem('moudleName');
						let parentName = window.sessionStorage.getItem('parentName');
						// if (['高分课堂'].includes(parentName)) {
						// 	videoMask = this.videoMask1;
						// } else if (parentName == '同步课堂') {
						// 	videoMask = this.videoMask5;
						// } else if (parentName == '情景对话') {
						// 	videoMask = this.videoMask3;
						// } else if (moudleName == '语法知识') {
						// 	videoMask = this.videoMask2;
						// } else if (moudleName == '名师考点精讲') {
						// 	videoMask = this.videoMask4;
						// }
						this.initVideoItem(this.videosList[i].video, this.videosList[i].image, cid, i);
					}
				});
			// }
		}
	},
	data() {
		return {
			videoMask1: require('@/assets/img/mask1.png'),
			videoMask2: require('@/assets/img/mask2.png'),
			videoMask3: require('@/assets/img/mask3.png'),
			videoMask4: require('@/assets/img/mask4.png'),
			videoMask5: require('@/assets/img/mask5.png'),
			isLoading: false,
			isEmpty: false,
			videoLoading: [],
			totalRequest: -1,
			currentRequest: 0,
			videosList: [],
			dplayerObj: [],
			audioFile: null
		};
	},
	created() {
		this.videosList = [];
		this.getVideo();
		this.addHistory();
	},
	methods: {
		setSourceUrl,
		playVideoItem(i) {
			// this.dplayerObj[i].fullScreen.request("browser");
			this.dplayerObj[i].play();
			this.$set(this.videosList[i], 'showMask', false);
		},
		initVideoItem(url, img, id, i) {
			let that = this;
			let videoDom = document.getElementById(id);
			if (videoDom) {
				let opt = {
					url: that.setSourceUrl(url)
				};
				if (img) {
					opt.pic = 'https://aixuebang-1310518323.file.myqcloud.com/' + img;
				}
				let dplayerItem = new DPlayer({
					container: videoDom,
					video: opt,
					theme: '#20a287'
					// type:'hls'
				});

				that.$set(that.dplayerObj, i, dplayerItem);
				dplayerItem.on('canplay', () => {
					that.$set(that.videosList[i], 'show', true);
				});
				dplayerItem.on('fullscreen_cancel', () => {
					that.$set(that.videosList[i], 'showMask', true);
					dplayerItem.pause();
				});
			}
		},
		getVideo(){
			this.$api
				.getMenuContent({
					course_id: this.$route.query.channelId,
					showToast:false
				})
				.then((res) => {
					this.totalRequest  = 1
					this.currentRequest = ++this.currentRequest;
					let contentData = res.data || {};
					if(contentData){
						contentData = contentData[0];
						contentData = {
							show: true,
							showMask: true,
							showContal: false,
							...contentData
						};
						this.$set(this.videosList, 0, contentData);
					}else{
						this.isEmpty = true;
						this.isLoading = false;
					}
				});
		},
		initData() {
			this.isLoading = true;
			
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: this.$route.query.channelId,
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						// this.totalRequest = resData;
						resData.forEach((i) => {
							if (this.$route.query.childId == i.id) {
								this.totalRequest = [i];
							}
						});
						this.$set(this.videoLoading, 0, true);
						this.$api
							.getMenuContent({
								course_id: this.$route.query.childId,
								showToast:false
							})
							.then((res) => {
								this.currentRequest = ++this.currentRequest;
								let contentData = res.data || {};
								contentData = contentData[0];
								contentData = {
									show: false,
									showMask: true,
									showContal: false,
									...contentData
								};
								this.$set(this.videosList, 0, contentData);
							});
					} else {
						this.isEmpty = true;

						this.isLoading = false;
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.video-box {
	width: 100%;
	height: 100%;
	// padding: 0.3rem 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background: #fff;
	border-top: 0.2rem solid #f5f5f5;
	// margin-top: 0.2rem;
	.vidoe-list-box {
		// padding-right: 0.3rem;
		height: calc(100% - 0.5rem);
		background: #fff;
		overflow: auto;
	}
	.video-list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding: 10px;
		li {
			width: 100%;
			min-height: 195px;
			margin-bottom: 15px;
			.item-main {
				position: relative;
				width: 100%;
				font-size: 14px;
				::v-deep .dplayer-menu {
					display: none !important;
				}
				::v-deep .dplayer-full-in-icon {
					display: none !important;
				}
				.item-mask {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					z-index: 100;
					background: rgba(0, 0, 0, 0.5);
					display: flex;
					justify-content: center;
					align-items: center;
					.play-btn {
						width: 0.52rem;
						height: 0.52rem;
						transition: all 0.1s ease-in-out;
						&:hover {
							opacity: 0.8;
							transform: scale(1.05);
						}
					}
				}
			}
			.empty-video {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 3.5rem;
				font-size: 0.24rem;
				background: rgba(0, 0, 0, 0.1);
			}
			.video-base {
				display: flex;
				justify-content: center;
				font-size: 14px;
				padding: 5px 0;
				font-weight: 400;
				color: #333;
				line-height: 1.6;
			}
		}
		.hideContrall {
			::v-deep .dplayer-controller {
				opacity: 0;
			}
		}
	}

	.sync-class-title {
		width: 100%;
		display: flex;
		align-items: center;
		font-size: 0.26rem;
		font-weight: 400;
		color: #20a287;
		padding-bottom: 0.17rem;
		padding-left: 0.3rem;
		.video-icon {
			width: 0.3rem;
			height: 0.22rem;
			margin-right: 0.11rem;
			transform: translateY(0.015rem);
		}
	}
}
</style>
