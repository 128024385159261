<template>
<div class="question-page">
<navBar @goBack="gobackChapter">
<span class="title">阅读提升</span>
</navBar>
<questionList></questionList>
</div>
</template>

<script>
export default {
name: "yueDuTiSheng",
components:{
questionList:()=>import('@/views/channel/components/questionList.vue')
},
data() {
return {};
},
created() {},
methods: {

},
};
</script>

<style lang="scss" scoped>
.question-page {
width: 100%;
height: 100%;
}
</style>