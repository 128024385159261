<template>
	<div class="page-box">
		<loading-box v-if="loadingSwiper"></loading-box>
		<template v-else>
			<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="swiperImg.length">
				<van-swipe-item
					v-for="(item, index) in swiperImg"
					:key="`a${index}`"
					@click="swiperDetail(item.url, item.name)"
				>
					<div class="swiper-img">
						<van-image
							class="img"
							width="100%"
							height="100%"
							fit="cover"
							:src="setSourceUrl(item.banner)"
						/>
					</div>
				</van-swipe-item>
			</van-swipe>
		</template>
		<loading-box v-if="loadingMenu"></loading-box>
		<ul class="main-nav" v-else>
			<li v-for="(item, index) in indexNav" :key="`b${index}`" @click="goChannel(item)">
				<div class="nav-icon">
					<van-image width="100%" height="100%" fit="cover" :src="setSourceUrl(item.h5icon)" />
				</div>
				<div class="nav-text">{{ item.name }}</div>
			</li>
		</ul>

		<!-- <div class="notify" v-if="notifyData.length">
<van-image class="notify-icon" :src="notifyIcon"></van-image>
<div class="notify-con">
<van-swipe
class="notify-swipe"
:autoplay="3000"
vertical
:show-indicators="false"
>
<van-swipe-item
v-for="(item, index) in notifyData"
:key="`b${index}`"
@click="notifyDetail(item.id)"
>
<p class="notify-text">{{ item.name }}</p>
</van-swipe-item>
</van-swipe>
</div>
</div> -->
	<template v-if="!loadingHistory">
		<div class="course-learn" v-if="courseData.length">
			<div class="item-header">
				<div class="header-left">最近学习</div>
				<div class="header-right" @click="viewMore">
					查看更多
					<i class="iconfont icon-fanhui"></i>
				</div>
			</div>
			<template v-if="courseData.length">
				<!-- <ul class="course-nav">
					<li :class="{ active: activeNav == 0 }" @click="changeCourse(100)">全部</li>
					<li
						:class="{ active: activeNav == index + 1 }"
						v-for="(item, index) in dataSource"
						:key="`c${index}`"
						@click="changeCourse(index)"
					>
						{{ item[0].learningrecord.query.subjectName }}
					</li>
				</ul> -->
				<div class="van-tabs-box">
					<van-tabs  @click="changeCourse" line-width="0.24rem" color="#28b396" title-active-color="#28b396" swipeable>
						<van-tab title="全部" :key="100" />
					   <van-tab v-for="(item, index) in dataSource" :title="item[0].learningrecord.query.subjectName" :key="index" />
					</van-tabs>	
				</div>
				<courseList
					:courseData="courseData"
					:limitNum="5"
					v-if="courseData.length"
				></courseList>
			</template>
		</div>
		<div class="course-learn" v-else>
			<div class="item-header">
				<div class="header-left">最近学习</div>
				<div class="header-right" @click="viewMore">
					查看更多
					<i class="iconfont icon-fanhui"></i>
				</div>
			</div>
			<div class="nothing">
				<coustm-empty></coustm-empty>
			</div>
		</div>
	</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setSourceUrl } from '@/utils/public';
import courseList from '@/components/courseList.vue';
import LoadingBox from '@/components/loadingBox.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
export default {
	name: 'mainPage',
	components: {
		courseList,
		LoadingBox,
		CoustmEmpty
	},
	computed: {
		...mapGetters({
			swiperImg: 'mainIndex/getSwiperImg',
			indexNav: 'mainIndex/getIndexNav',
			refreshToken: 'common/getRefreshToken',
			siteUrl: 'common/getSiteurl'
		})
	},
	watch: {
		$route: function (val) {
			if (val.path == '/') {
				this.getLastLearn();
			}
		}
	},
	data() {
		return {
			loadingHistory:true,
			loadingSwiper: false,
			loadingMenu: false,
			notifyIcon: require('../../assets/img/notify_icon.png'),
			notifyData: [],
			activeNav: 0,
			dataSource: {},
			allData: [],
			courseData: [],
			fileList: [],
			currentSubject: '100'
		};
	},
	async created() {
		await this.initIndex();
		await this.getLastLearn();
	},
	mounted() {
		window.sessionStorage.clear();
	},
	methods: {
		setSourceUrl,
		initIndex() {
			if (this.swiperImg.length < 1) {
				this.getSwiperlist();
			}
			if (this.indexNav.length < 1) {
				this.getIndexNav();
			}
			//this.getIndexNotify();
		},
		getSwiperlist() {
			this.loadingSwiper = true;
			this.$api.getSwiperlist().then((res) => {
				this.$store.commit('mainIndex/setSwiperImg', res.data);
				this.loadingSwiper = false;
			});
		},
		swiperDetail(url, title) {
			if (url) {
				this.$router.push({
					path: '/webView',
					query: {
						webUrl: url,
						title: title
					}
				});
			}
		},
		getIndexNav() {
			this.loadingMenu = true;
			this.$api.getSubject().then((res) => {
				this.$store.commit('mainIndex/setIndexNav', res.data);
				this.loadingMenu = false;
			});
		},
		getIndexNotify() {
			this.$api.getIndexNotify().then((res) => {
				this.notifyData = res.data;
			});
		},
		getLastLearn() {
			let that = this;
			this.loadingHistory = true;
			that.$api.getLearnHistory().then((res) => {
				let resData = res.data || [];
				// 转换后的二维数组
				let newData = Object.values(
				  resData.reduce((res, item) => {
					let subjectName = item.learningrecord.query.subjectName;
					res[subjectName]
					  ? res[subjectName].push(item)
					  : (res[subjectName] = [item]);
				    return res;
				  }, {})
				);
				that.allData = newData.flat(1);
				that.dataSource = newData;
				that.loadingHistory = false;
				that.changeCourse(100);
				// let resData = res.data || [];
				// that.allData = resData;
				// let newData = [];
				// resData.forEach((item) => {
				// 	if (!newData.includes(item.subject_id)) {
				// 		newData.push(item.subject_id);
				// 	}
				// });
				// let navData = [];
				// for (let i = 0; i < this.indexNav.length; i++) {
				// 	if (newData.includes(this.indexNav[i].id)) {
				// 		navData.push({
				// 			id: this.indexNav[i].id,
				// 			name: this.indexNav[i].name
				// 		});
				// 	}
				// }
				// //resData.length=5
				// this.dataSource = navData;
				// this.courseData = resData;
			});
		},

		changeCourse(idx, id) {
			console.log(idx, id)
			if (idx!=100&&idx!=0) {
				this.courseData = this.dataSource[idx - 1];
			} else {
				this.courseData = this.allData;
			}
			this.activeNav = idx;
			this.currentSubject = id;
		},
		notifyDetail(id) {
			this.$router.push({
				path: '/notifyDetail',
				query: {
					id: id
				}
			});
		},
		goChannel(item) {
			this.$router.push({
				path: '/channel',
				query: {
					subjectId: item.id,
					subjectName: item.name
				}
			});
		},
		viewMore() {
			this.$router.push({
				query: {
					from: ''
				},
				path: '/moreHistory'
			});
		}
	}
};
</script>

<style lang="scss">
.my-swipe {
	.van-swipe__indicators {
		bottom: 22px;
	}
	.van-swipe__indicator {
		width: 24px;
		height: 4px;
		border-radius: 2px;
	}
}
	.page-box.hasnav {
	    top: 1.08rem ;
	}
</style>
<style lang="scss" scoped>
.course-nav {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0.55rem;
	li {
		position: relative;
		margin-right:0.2rem;
		font-size: 0.28rem;
		font-weight: 400;
		color: #909090;
		transition: all 0.3s ease-in;
		&::after {
			position: absolute;
			    left: 50%;
			    bottom: -.15rem;
			    height: .04rem;
			    width: .24rem;
			    content: "";
			    background: #28b396;
			    border-radius: 0 2rem;
			    opacity: 0;
			    transition: all .3s ease-in;
			    transform: translateX(-50%);
			
		}

		&.active {
			position: relative;
			font-weight: 500;
			font-size: 0.24rem;
			color: #28b396;
			&::after {
				opacity: 1;
			}
		}
	}
}
.course-learn {
	padding: 0 0.24rem;
	.van-tabs-box{
		margin-bottom: 0.3rem;
		.van-tab{
			font-size: 0.28rem;
			line-height: 0.4rem;
		}
	}
	.item-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.2rem;
		
		.header-left {
			display: flex;
			align-items: center;
			font-size: 0.3rem;
			font-weight: 500;
			color: #333;
			.iconfont {
				font-size:0.24rem;
				color: #0bbda8;
				margin-right: 0.2rem;
			}
		}
		.header-right {
			display: flex;
			align-items: center;
			font-size: 0.24rem;
			color: #909090;
			font-weight: 400;
			.iconfont {
				font-size: 12px;
				transform: rotate(180deg);
			}
		}
	}
}
.banner-box {
	padding: 0 0.28rem;
	margin-bottom: 1px;
}
.notify {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0 0.28rem;
	padding: 0 10px;
	height: 36px;
	margin-bottom: 23px;
	background: #f8f8f8;
	border-radius: 2px;
	.notify-icon {
		width: 52px;
		height: 16px;
		margin-right: 10px;
	}
	.notify-con {
		width: calc(100vw - 10px);
	}
	.notify-swipe {
		width: 100%;
		height: 36px;
	}
	.notify-text {
		font-size: 13px;
		line-height: 35px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.main-nav {
	padding: 0 0;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	li {
		width: 20%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-bottom: 15px;
		padding: 5px 0;
		&:active {
			background: rgba(0, 0, 0, 0.02);
		}
		.nav-icon {
			    width: .98rem;
			    height: 1.13rem;
		}
		.nav-text {
			font-size: 0.26rem;
		}
	}
}
.my-swipe {
	margin-bottom: 10px;
	.swiper-img {
		margin: 0.28rem;
		overflow: hidden;
		.img {
			width: 100%;
			height: auto;
			border-radius:0.2rem ;
			overflow: hidden;
		}
	}
}
</style>
