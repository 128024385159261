var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lianxi-box",class:{ noborder: _vm.translateMoudle }},[(_vm.translateMoudle)?_c('div',{staticClass:"lianxi-content"},[_c('div',{staticClass:"question"},[(_vm.translateMoudle == 2)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.questionItem.word)}}):_vm._e(),(_vm.translateMoudle == 1)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.questionItem.translate)}}):_vm._e()]),_c('div',{staticClass:"options",attrs:{"id":"options"}},[(_vm.translateMoudle == 2)?_c('ul',{staticClass:"options-item",attrs:{"id":"optionsItem"}},_vm._l((_vm.questionItem.yyh),function(item,index){return _c('li',{key:index,class:{
						error: _vm.questionItem.status == 1 && _vm.questionItem.userSelect == index,
						proper: _vm.questionItem.status == 2 && _vm.questionItem.userSelect == index
					},on:{"click":function($event){return _vm.selectAction(index)}}},[_c('span',{domProps:{"innerHTML":_vm._s(item)}})])}),0):_vm._e(),(_vm.translateMoudle == 1)?_c('ul',{staticClass:"options-item",attrs:{"id":"optionsItem"}},_vm._l((_vm.questionItem.hyy),function(item,index){return _c('li',{key:index,class:{
						error: _vm.questionItem.status == 1 && _vm.questionItem.userSelect == index,
						proper: _vm.questionItem.status == 2 && _vm.questionItem.userSelect == index
					},on:{"click":function($event){return _vm.selectAction(index)}}},[_c('span',{domProps:{"innerHTML":_vm._s(item)}})])}),0):_vm._e()])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.translateMoudle),expression:"translateMoudle"}],staticClass:"question-handle"},[_c('div',{staticClass:"btn",class:{ disable: _vm.currentQuestion == 0 },on:{"click":function($event){return _vm.changeQuestion(1)}}},[_vm._v("上一题")]),_c('div',{staticClass:"btn",class:{ disable: _vm.currentQuestion == _vm.totalQuestion - 1 },on:{"click":function($event){return _vm.changeQuestion(2)}}},[_vm._v(" 下一题 ")])]),(_vm.showTranslateModel)?_c('div',{staticClass:"lianxi-select"},[_c('div',{staticClass:"select-option",on:{"click":function($event){return _vm.selecTranslateModel(2)}}},[_c('img',{attrs:{"src":_vm.optionImg,"alt":""}}),_c('span',[_vm._v("英译汉")])]),_c('div',{staticClass:"select-option",on:{"click":function($event){return _vm.selecTranslateModel(1)}}},[_c('img',{attrs:{"src":_vm.optionImg,"alt":""}}),_c('span',[_vm._v("汉译英")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }