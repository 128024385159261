<template>
	<div class="words-sentence">
		<loading-box v-if="isLoading"></loading-box>
		<template v-else>
			<coustmEmpty v-if="isEmpty"></coustmEmpty>
			<template v-else>
				<div class="content-wrapper" :class="currentSubject" id="content-wrapper">
					<template v-if="questionData[currentObj]">
						<div v-html="questionData[currentObj].content" id="text-box"></div>
					</template>
					<coustmEmpty v-else></coustmEmpty>
				</div>
			</template>
		</template>

		<div class="animateSymbol-menu" v-if="showLayer" @click="closeLayer">
			<ul id="animate-symbol-menu">
				<li
					v-for="(nav, opt) in questionData"
					class="lianxi"
					:class="{
						active: currentObj == opt
					}"
					:key="`sub${opt}`"
					@click="selectNav(opt)"
				>
					{{ opt }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { html2string, formatMathJaxImg, formatAddPointWords } from '@/utils/public';
import loadingBox from '@/components/loadingBox.vue';

export default {
	components: { loadingBox },
	name: 'textImgHasMenu',
	props: {
		showLayer: Boolean,
		default: () => {
			return false;
		}
	},
	data() {
		return {
			queryParmas: {},
			isEmpty: false,
			isLoading: false,
			questionData: {},
			resquestionData: {},
			currentObj: '',
			questionTitle: [],
			accountRequest: 0,
			requestLen: -1,
			optionsTags: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
			currentSubject: ''
		};
	},
	watch: {
		accountRequest: function (val) {
			if (val == this.requestLen) {
				this.isLoading = false;
				for (let i = 0; i < this.requestLen; i++) {
					this.$set(
						this.questionData,
						this.questionTitle[i].name,
						this.resquestionData[this.questionTitle[i].name]
					);
				}
				this.$nextTick(() => {
					let textBox = window.document.getElementById('text-box');
					if (textBox) {
						let img = textBox.getElementsByTagName('img');
						for (let i = 0; i < img.length; i++) {
							let parentDom = img[i].parentNode;
							parentDom.style.textIndent = 0;
							let parentDom1 = parentDom.parentNode;
							parentDom1.style.textIndent = 0;
							let parentDom2 = parentDom1.parentNode;
							parentDom2.style.textIndent = 0;
							let parentDom3 = parentDom2.parentNode;
							parentDom3.style.textIndent = 0;
						}
					}
				});
			}
		}
	},
	created() {
		this.queryParmas = this.$route.query;
		this.initData();
		this.addHistory();
		let subjectName = this.$route.query.subjectName;
		switch (subjectName) {
			case '语文': {
				this.currentSubject = 'yuwen';
				break;
			}
			case '数学': {
				this.currentSubject = 'shuxue';
				break;
			}
			case '英语': {
				this.currentSubject = 'yingyu';
				break;
			}
			case '物理': {
				this.currentSubject = 'wuli';
				break;
			}
			case '化学': {
				this.currentSubject = 'huaxue';
				break;
			}
			case '政治': {
				this.currentSubject = 'zhengzhi';
				break;
			}
			case '历史': {
				this.currentSubject = 'lishi';
				break;
			}
			case '生物': {
				this.currentSubject = 'shengwu';
				break;
			}
			case '地理': {
				this.currentSubject = 'dili';
				break;
			}
			default: {
				this.currentSubject = '';
			}
		}
	},
	mounted() {},
	methods: {
		html2string,
		formatMathJaxImg,
		formatAddPointWords,
		setMathJaxText() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
		},
		closeLayer() {
			this.$emit('closeLayer');
		},

		selectNav(opt) {
			this.currentObj = opt;
			let scrollDome = window.document.getElementById('content-wrapper');
			scrollDome.scrollTo({
				top: 0,
				behavior: 'auto'
			});
			this.$nextTick(() => {
				let textBox = window.document.getElementById('text-box');
				if (textBox) {
					let img = textBox.getElementsByTagName('img');
					for (let i = 0; i < img.length; i++) {
						let parentDom = img[i].parentNode;
						parentDom.style.textIndent = 0;
						let parentDom1 = parentDom.parentNode;
						parentDom1.style.textIndent = 0;
						let parentDom2 = parentDom1.parentNode;
						parentDom2.style.textIndent = 0;
						let parentDom3 = parentDom2.parentNode;
						parentDom3.style.textIndent = 0;
					}
				}
			});
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuById({
					subject_id: this.queryParmas.subjectId,
					pid: this.queryParmas.channelId
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						this.currentObj = resData[0].name;
						this.questionTitle = resData;
						this.requestLen = resData.length;
						for (let i = 0; i < resData.length; i++) {
							this.isLoading = true;
							if (resData[i].menutype_id == 2) {
								this.$api
									.getMenuContent({
										course_id: resData[i].id
									})
									.then((res) => {
										this.accountRequest = ++this.accountRequest;
										let resQuestion = res.data[0] || [];
										resQuestion.content = this.formatMathJaxImg(resQuestion.content);

										resQuestion.content = this.formatAddPointWords(resQuestion.content);
										this.$set(this.resquestionData, resData[i].name, resQuestion);
										setTimeout(() => {
											this.setMathJaxText();
										}, 10);
									});
							}
							if (resData[i].menutype_id == 6) {
								this.$api
									.getMenuQuestion({
										course_id: resData[i].id
									})
									.then((res) => {
										this.accountRequest = ++this.accountRequest;
										let resQuestion = res.data[0].children || [];
										resQuestion.content = this.formatMathJaxImg(resQuestion.content);
										this.$set(this.resquestionData, resData[i].name, resQuestion);
										setTimeout(() => {
											this.setMathJaxText();
										}, 10);
									});
							}
						}
					} else {
						this.isEmpty = true;
						this.isLoading = false;
					}
				});
		}
	}
};
</script>

<style lang="scss">
.content-wrapper.yuwen {
	em {
		position: relative;
		font-style: normal;
		&::after {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 100%);
			content: '';
			width: 4px;
			height: 4px;
			background: #333;
			border-radius: 50%;
		}
	}
}
.content-wrapper {
	img {
		vertical-align: middle;
	}
	* {
		font-size: 13px !important;
		img {
			max-width: 100%;
		}
	}
}
</style>

<style lang="scss" scoped>
.words-sentence {
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
	.content-wrapper {
		height: 100%;
		padding: 15px;
		background: #fff;
		overflow: auto;
		font-size: 16px;
		line-height: 1.75em;
		color: #333;
	}
	.animateSymbol-menu {
		position: fixed;
		left: 0;
		right: 0;
		top: 50px;
		bottom: 0;
		z-index: 100;
		background: rgba(0, 0, 0, 0.8);
		user-select: none;
		cursor: pointer;
		li {
			line-height: 50px;
			text-align: center;
			height: 50px;
			padding: 0 15px;
			border-bottom: 1px #dcdcdc solid;
			font-size: 13px;
			color: #fff;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.active {
			background: #1e9e85;
			color: #fff;
		}
	}
}
</style>
