<template>
	<div class="yuanwenjiexi-page">
		<navBar @goBack="gobackChapter">
			<span class="title">原文解析</span>
		</navBar>
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<div class="text-img-content" :class="{ hasMore: 1 < classArr.length }" v-if="!isEmpty">
				<div class="class-layout">
					<van-image
						:src="setSourceUrl(contentData[activeClass].image)"
						class="layout-img"
						:preview-src-list="[setSourceUrl(contentData[activeClass].image)]"
					></van-image>
				</div>
			</div>
			<coustm-empty v-else></coustm-empty>
		</template>
		<ul class="class-length" v-if="1 < classArr.length">
			<li
				:class="{ active: activeClass == index }"
				v-for="(item, index) in classArr"
				:key="index"
				@click="changeClass(index)"
			>
				{{ index + 1 }}
			</li>
		</ul>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
export default {
	name: 'wenZhangJieGou',
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			contentData: [],
			currentClass: 0,
			classArr: [],
			activeClass: 0
		};
	},
	created() {
		this.getContent();
		this.addHistory();
	},
	methods: {
		setSourceUrl,

		changeClass(n) {
			this.activeClass = n;
			this.jieXiText = '';
			if (!this.contentData[this.activeClass]) {
				this.getContent();
			}
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: this.$route.query.childId
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						this.classArr = resData;
						this.getContent();
					} else {
						this.isEmpty = true;
						this.isLoading = false;
					}
				});
		},
		getContent() {
			this.isLoading = true;
			this.$api
				.getMenuContent({
					course_id: this.$route.query.childId
				})
				.then((res) => {
					this.isLoading = false;
					let resData = res.data[0] || {};
					if (JSON.stringify(resData) == '{}') {
						this.isEmpty = true;
					} else {
						this.$set(this.contentData, this.activeClass, resData);
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.class-length {
	position: fixed;
	left: 0;
	right: 0;
	top: 60px;
	z-index: 100;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 36px;
	border-bottom: 1px #dcdcdc solid;
	margin-bottom: 15px;
	background: #fff;
	li {
		position: relative;
		line-height: 36px;
		font-size: 13px;
	}
	.active {
		color: #28b295;
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			border-bottom: 1px #28b295 solid;
		}
	}
}
.yuanwenjiexi-page {
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
}
.text-img-content {
	width: 100%;
	height: 100%;
	padding: 15px;
	background: #fff;
	overflow: hidden;
	border-top: 0.2rem solid #f5f5f5;
}
.hasMore {
	padding-top: 50px;
}
.class-layout {
	display: flex;
	justify-content: center;
	width: 100%;
	.layout-img {
		width: auto;
		height: 100%;
	}
}
</style>
