var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-wrapper",class:_vm.currentSubject},[(_vm.isLoading)?_c('loadingBox'):[(!_vm.isEmpty)?_c('div',{staticClass:"contant-main"},_vm._l((_vm.questionData),function(item,contentIndex){return _c('div',{key:`question${contentIndex}`,staticClass:"question-item"},[_c('div',{staticClass:"question-content"},[_c('span',{domProps:{"innerHTML":_vm._s(item.content)}})]),_c('ul',{staticClass:"question-list"},_vm._l((item.children),function(question,questionIndex){return _c('li',{key:`item${questionIndex}`},[_c('div',{staticClass:"stem",domProps:{"innerHTML":_vm._s(question.stem)}}),(question.classtext < 3)?_c('div',{staticClass:"sigin-choose"},[_c('ul',{ref:"options",refInFor:true,staticClass:"options",attrs:{"id":"options"}},_vm._l((question.option),function(opt,optionIndex){return _c('li',{key:`option${optionIndex}`,class:{
										active: question.userAnswer.includes(optionIndex),
										proper:
											(question.option[optionIndex].status == 1 &&
												question.userAnswer.includes(optionIndex)) ||
											(_vm.html2string(question.answer).includes(_vm.optionsTag[optionIndex]) &&
												question.showAnalysis),
										error:
											question.option[optionIndex].status == 2 &&
											question.userAnswer.includes(optionIndex)
									},on:{"click":function($event){return _vm.selectAction(contentIndex, questionIndex, optionIndex, question.classtext)}}},[_c('span',{domProps:{"innerHTML":_vm._s(opt.label)}})])}),0)]):_vm._e(),(question.classtext == 3)?_c('div',{staticClass:"fill-blanks"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(question.userAnswer),expression:"question.userAnswer"}],staticClass:"user-textarea",attrs:{"rows":5,"disabled":question.showAnalysis},domProps:{"value":(question.userAnswer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(question, "userAnswer", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"jiexi-btn-box"},[_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.jiexiAction(contentIndex, questionIndex)}}},[_vm._v("解析")])]),(question.showAnalysis)?_c('ul',{staticClass:"result-list"},[_c('li',[_c('span',{staticClass:"label-for"},[_vm._v("【正确答案】")]),_c('span',{staticClass:"label-text",domProps:{"innerHTML":_vm._s(question.answer)}})]),_c('li',[_c('span',{staticClass:"label-for"},[_vm._v("【您的答案】")]),(question.classtext < 3)?_c('span',{staticClass:"label-text"},[_vm._v(" "+_vm._s(question.userAnswerTag || '暂未作答')+" ")]):_c('span',{staticClass:"label-text"},[_vm._v(_vm._s(question.userAnswer || '暂未作答'))])]),_c('li',[_c('span',{staticClass:"label-for"},[_vm._v("【答案解析】")]),(question.analysis)?_c('span',{staticClass:"label-text",domProps:{"innerHTML":_vm._s(question.analysis)}}):_c('span',{staticClass:"label-text"},[_vm._v("略")])])]):_vm._e()])}),0)])}),0):_c('coustm-empty')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }