<template>
	<div class="question-content">
		<navBar @goBack="gobackPrev">
			<span class="title">查看解析</span>
			<template v-slot:right>
				<div class="common-step">
					<span class="current">{{ questionObject[activeQuestion].questionIndex }}</span>
					<span class="total">/{{ questionObject[questionObject.length - 1].questionIndex }}</span>
				</div>
			</template>
		</navBar>
		<div class="proress">
			<div class="con" :style="`width:${((activeQuestion + 1) / questionObject.length) * 100}%`"></div>
		</div>
		<div class="contant-main">
			<div class="question-area">
				<div class="area content-area" v-if="questionObject[activeQuestion].content">
					<div class="con-box" v-html="questionObject[activeQuestion].content"></div>
				</div>
				<div class="area main-area">
					<div class="box question-body">
						<div class="stem" v-html="questionObject[activeQuestion].stem"></div>
						<div class="sigin-choose" v-if="questionObject[activeQuestion].classtext < 3">
							<ul class="options" id="options" ref="options">
								<li
									v-for="(opt, index) in questionObject[activeQuestion].option"
									:key="index"
									:class="{
										active: questionObject[activeQuestion].userAnswer.includes(index),
										error:
											!questionObject[activeQuestion].answer.includes(
												questionObject[activeQuestion].userAnswerTag
											) && questionObject[activeQuestion].userAnswer.includes(index)
									}"
								>
									<span v-html="opt.label"></span>
								</li>
							</ul>
						</div>

						<div class="fill-blanks" v-if="questionObject[activeQuestion].classtext == 3">
							<textarea
							 :disabled="true"
								class="user-textarea"
								:rows="5"
								v-model="questionObject[activeQuestion].userAnswer"
							/>
						</div>
					</div>
					<div class="box question-operate">
						<ul class="result-list">
							<li>
								<span class="label-for">【正确答案】</span>
								<span
									class="label-text"
									v-if="questionObject[activeQuestion].answer"
									v-html="questionObject[activeQuestion].answer"
								></span>
								<span v-else>略</span>
							</li>
							<li>
								<span class="label-for">【您的答案】</span>
								<span class="label-text" v-if="questionObject[activeQuestion].classtext < 3">
									{{ questionObject[activeQuestion].userAnswerTag || '暂未作答' }}
								</span>
								<span class="label-text" v-else>
									{{ questionObject[activeQuestion].userAnswer || '暂未作答' }}
								</span>
							</li>
							<li>
								<span class="label-for">【答案解析】</span>
								<span
									class="label-text"
									v-html="questionObject[activeQuestion].analysis"
									v-if="questionObject[activeQuestion].analysis"
								></span>
								<span class="label-text" v-else>略</span>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="question-handle">
				<div class="btn" :class="{ disable: activeQuestion == 0 }" @click="changeQuestion(1)">上一题</div>
				<div
					class="btn"
					:class="{ disable: activeQuestion == questionObject.length - 1 }"
					@click="changeQuestion(2)"
				>
					下一题
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { deepClone, getNowTime, html2string, formatMathJaxImg } from '@/utils/public';
export default {
	name: 'questionList',
	computed: {
		currentScore: function () {
			return this.questionObject[this.activeQuestion]?.score || '*';
		}
	},
	data() {
		return {
			questionObject: [],
			activeQuestion: 0,
			pushErrorStatus: []
		};
	},
	created() {
		this.questionObject = this.$store.getters['common/getQuestionObject'];
		console.log(this.questionObject, 'this.questionObjectthis.questionObject');
		for (let i = 0; i < this.questionObject.length; i++) {
			console.log(this.questionObject[i]);
			if (this.questionObject[i].classtext < 3) {
				this.$set(this.pushErrorStatus, i, false);
			} else {
				this.$set(this.pushErrorStatus, i, true);
			}
		}
		this.checkErrorToAdd();
	},
	mounted() {
		this.setMathJaxText();
	},
	methods: {
		deepClone,
		getNowTime,
		html2string,
		formatMathJaxImg,
		setMathJaxText() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
		},

		gobackPrev() {
			this.$router.go(-2);
		},
		changeQuestion(n) {
			if (n == 1) {
				if (0 < this.activeQuestion) {
					this.activeQuestion = --this.activeQuestion;
				} else {
					this.$notify({
						type: 'warning',
						message: '已经是第一道题了'
					});
				}
			}
			if (n == 2) {
				if (this.activeQuestion < this.questionObject.length - 1) {
					this.activeQuestion = ++this.activeQuestion;
				} else {
					this.$notify({
						type: 'warning',
						message: '已经是最后一道题了'
					});
				}
			}
			if (!this.pushErrorStatus[this.activeQuestion]) {
				this.checkErrorToAdd();
			}

			setTimeout(() => {
				this.setMathJaxText();
			}, 10);
		},

		checkErrorToAdd() {
			let currentQuestion = this.questionObject[this.activeQuestion];
			let classText = currentQuestion.classtext;
			if (classText < 3) {
				let answer = this.html2string(currentQuestion.answer);
				let userAnswerTag = currentQuestion.userAnswerTag;
				if (answer != userAnswerTag) {
					let currentErrorId = currentQuestion.id;
					this.$api
						.addErrorQuestion({
							subject_id: this.$route.query.subjectId,
							questionbank_id: currentErrorId
						})
						.then((res) => {
							this.$set(this.pushErrorStatus, this.activeQuestion, true);
							//console.log(res);
						});
				}
			}
		}
	}
};
</script>

<style lang="scss">
.question-content {
	img {
		vertical-align: middle;
		height: auto !important;
	}
	em {
		position: relative;
		font-style: normal;
		&::after {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 100%);
			content: '';
			width: 0.08rem;
			height: 0.08rem;
			background: #333;
			border-radius: 50%;
		}
	}
}
.question-body {
	* {
		sub,
		sup {
			font-size: 0.5em !important;
		}
	}
	.stem {
		line-height: 1.6 !important;
		font-size: 0.3rem !important;
		font-family: initial !important;
	}
}
.question-area {
	.content-area {
		.con-box {
			font-size: 0.26rem;
			line-height: 1.75em;
		}
		line-height: 1.6 !important;
		font-size: 0.3rem !important;
		font-family: initial !important;
	}
}
.question-body {
	line-height: 1.6;
	.stem {
		margin-bottom: 0.3rem;
	}
	.options {
		li {
			position: relative;
			padding: 0.3rem 0.3rem 0.3rem 40px;
			margin: 0.3rem 0;
			cursor: default;
			background: #f8f8f8;
			border-radius: 0.1rem;
			font-size: 0.28rem;
			&::after {
				position: absolute;
				left: 0.3rem;
				top: 50%;
				transform: translateY(-50%);
				padding-right: 0.1rem;
			}
			&::before {
				position: absolute;
				left: 0.6rem;
				top: 50%;
				transform: translateY(-50%);
				padding-right: 0.1rem;
				content: '';
				height: 0.2rem;
				border-left: 1px #ccc solid;
			}
			&:nth-child(1)::after {
				content: 'A';
			}
			&:nth-child(2)::after {
				content: 'B';
			}
			&:nth-child(3)::after {
				content: 'C';
			}
			&:nth-child(4)::after {
				content: 'D';
			}
			&:nth-child(5)::after {
				content: 'E';
			}
			&:nth-child(6)::after {
				content: 'F';
			}
			&:nth-child(7)::after {
				content: 'G';
			}
			&:nth-child(8)::after {
				content: 'H';
			}
			&:nth-child(9)::after {
				content: 'I';
			}
			&:nth-child(10)::after {
				content: 'J';
			}
		}
		.active {
			background: #e2f5f1;
		}
		.error {
			background: #ff1e1e !important;
			color: #fff !important;
		}
	}
}

.fill-blanks {
	display: flex;
	margin-bottom: 15px;
}
</style>

<style lang="scss" scoped>
.common-step {
	display: flex;
	align-items: flex-end;
	background: #fff;
	line-height: 1;
	.current {
		font-size: 26px;
		color: #36ba9e;
		transform: translateY(3px);
	}
	.total {
		font-size: 0.3rem;
		// color: #969799;
	}
}
.user-textarea {
	margin-top: 0.3rem;
	width: 100%;
	padding: 0.3rem;
	border: 1px #36ba9e solid;
	border-radius: 0.1rem;
	resize: none;
	font-size: 0.3rem;
}
.result-list {
	li {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 0.3rem;
		text-align: justify;
		font-size: 0.28rem;
		.label-for {
			// font-size: 0.24rem;
			line-height: 1.75em;
			color: #1e9e85;
			word-break: keep-all;
			margin-right: 0.2rem;
		}
		.label-text {
			// font-size:0.24rem;
			line-height: 1.75em;
		}
	}
}

.question-area {
	padding: 0.6rem 0.3rem 0.3rem;
	overflow: auto;
	height: calc(100% - 1.24rem);
	.area {
		width: 100%;
		margin-bottom: 0.3rem;
	}
	.main-area {
		overflow: auto;
	}
	.flexBox {
		display: flex;
		border-right: 0 !important;
		.box {
			height: 100%;
			&:first-child {
				border-right: 1px #dcdcdc dashed;
			}
		}
	}
}

.question-content {
	position: relative;
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
	// padding: 15px;
	.contant-main {
		width: 100%;
		height: 100%;
		background: #fff;
	}
}

</style>
