<template>
	<div class="zheti-page">
		<navBar @goBack="gobackMoudle">
			<span class="title">历年真题</span>
			<!-- <template v-slot:right><span class="print-btn" @click="printAction">打印</span></template> -->
		</navBar>
		<ul class="filter-box">
			<li v-for="(item, tag) in filterData" :key="tag" :class="{ active: item.active }" @click="selectRule(tag)">
				<span>{{ item.value }}</span>
				<van-icon name="play" class="icon" />
			</li>
		</ul>

		<div class="area-picker" v-if="showPicker" @click="showPicker = false">
			<div class="picker-main" :class="{ show: showPicker }" @click.stop="showPicker = true">
				<div class="body" ref="pickerBody">
					<ul class="data-list">
						<template v-for="(item, index) in areaData">
							<li
								v-if="item.label"
								:key="index"
								:class="{ active: currentIndex == index }"
								ref="li"
								@click.stop="selectAction(index, item.value, item.label, item.children)"
							>
								{{ item.label }}
							</li>
						</template>
					</ul>
				</div>
			</div>
		</div>

		<div class="test-content">
			<template v-if="activeIndex == 0">
				<div class="con" v-html="papeData.testquestions" v-if="papeData.testquestions"></div>
				<coustm-empty v-else></coustm-empty>
			</template>
			<template v-if="activeIndex == 1">
				<div class="con" v-html="papeData.answer" v-if="papeData.answer"></div>
				<coustm-empty v-else></coustm-empty>
			</template>
		</div>

		<template v-if="JSON.stringify(papeData) != '{}'">
			<div class="change-menu" v-show="activeIndex == 0" @click="activeIndex = 1">答案</div>
			<div class="change-menu" style="background: #ff494a" v-show="activeIndex == 1" @click="activeIndex = 0">
				试题
			</div>
		</template>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
import cityData from '@/utils/city.json';
import coustmEmpty from '@/components/coustmEmpty.vue';
export default {
	components: { coustmEmpty },
	name: 'zhenTi',
	data() {
		return {
			ztBg: require('@/assets/img/ztBg.png'),
			lineBg: require('@/assets/img/line.png'),
			showPicker: false,
			areaData: [],
			currentIndex: '',
			currentProvinceIndex: -1,
			currentCityIndex: -1,
			currentTimeIndex: -1,
			activeIndex: 0,
			currentTag: '',
			timeData: [],
			cityData: [],
			filterData: {
				province: {
					id: '',
					value: '--省份--',
					active: false
				},
				time: {
					id: '',
					value: '--年份--',
					active: false
				},
				city: {
					id: '',
					value: '--城市--',
					active: false
				}
			},
			papeData: {}
		};
	},
	created() {
		//this.initFilter();
		//this.getTestData();
	},
	methods: {
		setSourceUrl,
		// printAction(){
		// window.print();
		// },
		goBack() {},
		getCityData() {
			this.$api
				.getZhenTi({
					province: this.filterData.province.id,
					subject_id: this.$route.query.subjectId,
					year: this.filterData.time.value
				})
				.then((res) => {
					let resData = res.data;
					if (resData.length) {
						let _cityData = [];
						resData.forEach((item) => {
							_cityData.push({
								label: item.cityname,
								value: item.city
							});
						});
						this.cityData = _cityData;
					} else {
						this.$notify({
							type: 'warning',
							message: '暂无数据'
						});
					}
				});
		},
		getYearData() {
			this.$api
				.getZhenTi({
					province: this.filterData.province.id,
					subject_id: this.$route.query.subjectId
				})
				.then((res) => {
					let resData = res.data;
					if (Object.keys(resData).length) {
						let _timeData = [];
						Object.keys(resData).forEach((item) => {
							_timeData.push({
								label: resData[item].year,
								value: resData[item].id
							});
						});
						this.timeData = _timeData;
					} else {
						this.$notify({
							type: 'warning',
							message: '暂无数据'
						});
					}
				});
		},
		getTestData() {
			this.$api
				.getZhenTi({
					province: this.filterData.province.id,
					subject_id: this.$route.query.subjectId,
					city: this.filterData.city.id,
					year: this.filterData.time.value
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						this.papeData = resData[0] || {};
					}
				});
		},
		selectRule(tag) {
			switch (tag) {
				case 'province': {
					this.areaData = cityData;
					this.currentIndex = this.currentProvinceIndex;
					this.clickRuleItem(tag);
					break;
				}
				case 'city': {
					if (!this.filterData.time.id) {
						this.$toast({
							message: '请先选择年份'
						});
						return false;
					}
					this.areaData = this.cityData;
					this.currentIndex = this.currentCityIndex;
					this.clickRuleItem(tag);
					break;
				}
				case 'time': {
					if (!this.filterData.province.id) {
						this.$toast({
							message: '请先选择省份'
						});
						return false;
					}

					this.currentIndex = this.currentTimeIndex;
					this.areaData = this.timeData;
					this.clickRuleItem(tag);
					break;
				}
				default: {
					break;
				}
			}
		},
		clickRuleItem(tag) {
			Object.keys(this.filterData).forEach((item) => {
				this.$set(this.filterData[item], 'active', false);
				if (item == tag) {
					this.$set(this.filterData[item], 'active', true);
				}
			});

			this.showPicker = true;
			this.currentTag = tag;
			this.$nextTick(() => {
				let _height = this.$refs.li[0]?.offsetHeight;
				let _top = (this.currentIndex - 4) * _height;
				this.$refs.pickerBody.scrollTop = _top;
			});
		},
		closePicker() {
			this.showPicker = false;
			for (let i in this.filterData) {
				this.$set(this.filterData[i], 'active', false);
			}
		},
		resetProvice() {
			this.$set(this.filterData.city, 'id', '');
			this.$set(this.filterData.city, 'value', '--城市--');
			this.$set(this.filterData.city, 'active', false);
			this.currentTimeIndex = -1;
		},
		resetYear() {
			this.$set(this.filterData.time, 'id', '');
			this.$set(this.filterData.time, 'value', '--年份--');
			this.$set(this.filterData.time, 'active', false);
			this.timeData = [];
			this.currentCityIndex = -1;
		},
		selectAction(idx, id, val, child) {
			this.$set(this.filterData[this.currentTag], 'id', id);
			this.$set(this.filterData[this.currentTag], 'value', val);
			switch (this.currentTag) {
				case 'province': {
					this.currentProvinceIndex = idx;
					this.resetProvice(child);
					this.resetYear(child);
					this.getYearData(val);
					break;
				}
				case 'city': {
					this.currentCityIndex = idx;
					this.getTestData();
					break;
				}
				case 'time': {
					this.currentTimeIndex = idx;
					this.resetProvice();
					this.getCityData();
					break;
				}
				default: {
					break;
				}
			}
			this.closePicker();
		}
	}
};
</script>

<style lang="scss" scoped>
.print-btn {
	text-align: right;
	color: #28b295;
}
.change-menu {
	position: fixed;
	right: 25px;
	bottom: 25px;
	z-index: 1000;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #28b295;
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
}
.test-content {
	position: fixed;
	left: 0;
	right: 0;
	top: 130px;
	bottom: 0;
	z-index: 1;
	background: #fff;
	.con {
		width: 100%;
		height: 100%;
		background: #f0f0f0;
		overflow-x: hidden;
		overflow-y: auto;
		scroll-behavior: smooth;
		::v-deep {
			p {
				width: 100%;
			}
			img {
				max-width: 110%;
				width: 110%;
				height: auto;
				image-rendering: -webkit-optimize-contrast;
				transform: translateX(-5%);
			}
		}
	}
}

.area-picker {
	position: fixed;
	left: 0;
	right: 0;
	top: 121px;
	bottom: 0;
	z-index: 100;
	display: flex;
	align-items: flex-start;
	background: rgba(0, 0, 0, 0.3);
	.picker-main {
		width: 100%;
		background: #fff;
		background: #fff;
		border-bottom: 1px #28b295 solid;
		.body {
			max-height: calc(100vh - 250px);
			overflow-y: auto;
			scroll-behavior: smooth;
			.data-list {
				li {
					height: 46px;
					line-height: 46px;
					padding-left: 27px;
					font-size: 13px;
					font-weight: 400;
					color: #333;
				}
				.active {
					background: #28b295;
					color: #fff;
				}
			}
		}
	}
}
.main-box {
	position: fixed;
	left: 0;
	right: 0;
	top: 2.28rem;
	bottom: 1.3rem;
	padding: 1.1rem 0.28rem 0.28rem 0.28rem;
	background: #fff;
	background-size: 100% 2.7rem;
	background-position: top center;
	background-repeat: no-repeat;
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		background: #fff;
		box-shadow: 0px 2px 23px 1px rgba(128, 128, 128, 0.08);
		border-radius: 0.2rem;
		overflow-y: auto;
		scroll-behavior: smooth;
		.title {
			padding: 0.6rem 0 0.4rem 0;
			font-size: 0.3rem;
			color: #333;
			font-weight: 500;
			text-align: center;
			line-height: 0.52rem;
		}
		.base-infs {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 3.3rem;
			height: 0.6rem;
			margin-bottom: 0.95rem;
			border-radius: 0.3rem;
			background: #f2f2f2;
			li {
				flex: 1;
				font-size: 0.24rem;
				color: #333;
				font-weight: 400;
				line-height: 1;
				text-align: center;
				border-right: 0.02rem #c0c1c3 solid;
				&:last-child {
					border: 0;
				}
			}
		}
		.base-question {
			width: 100%;
			padding: 0 0.88rem;
			li {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 0.55rem;
				color: #333;
				.tit {
					font-size: 0.26rem;
					font-weight: 500;
				}
				.code {
					font-size: 0.24rem;
					font-weight: 400;
				}
				.line {
					flex: 1;
					margin: 0 0.2rem;
					height: 0.03rem;
					background-size: 0.15rem 0.03rem;
					background-position: left top;
					background-repeat: repeat-x;
				}
			}
		}
	}
}
.star-btn {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0.25rem 0.28rem;
	background: #fff;
	.btn {
		width: 100%;
		height: 0.8rem;
		background: #28b396;
		border-radius: 0.4rem;
		text-align: center;
		line-height: 0.8rem;
		font-size: 0.28rem;
		font-weight: 500;
		color: #fff;
		&:active {
			opacity: 0.8;
		}
	}
}
.zheti-page {
	padding-top: 1.08rem;
	background: #fafafa;
}
.filter-box {
	position: fixed;
	left: 0;
	right: 0;
	top: 60px;
	z-index: 100;
	padding: 15px;
	display: flex;
	justify-content: space-between;
	background: #fff;
	li {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 105px;
		height: 30px;
		line-height: 1;
		border-radius: 15px;

		span {
			width: 75px;
			text-align: center;
			font-size: 15px;
			color: #fff;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #323232;
		}
		.icon {
			font-size: 15px;
			color: #fff;
			color: #dcdee0;
			transform: rotate(90deg);
			transition: all 0.3s ease-in-out;
		}
	}
	.active {
		span {
			color: #28b295;
		}
		.icon {
			transform: rotate(-90deg);
			color: #28b295;
		}
	}
}

</style>
