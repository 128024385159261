<template>
<div class="public-imgtext-page">
<textImgContent v-if="$route.query.menutype == 2"></textImgContent>
<questionList v-else position="right" style="padding-top: 0"></questionList>
</div>
</template>

<script>
export default {
name: 'jieTiTongFa',
components: {
questionList: () => import("@/views/channel/components/questionList.vue"),
textImgContent: () => import('@/views/channel/components/textImgContent.vue')
},
data() {
return {

}
},

created() {
//this.addHistory();
},
}
</script>

<style lang="scss" scoped>
.public-imgtext-page {
height: 100%;
}
</style>