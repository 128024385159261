import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index/index.vue"),
  },
  {
    path: "/library",
    name: "library",
    component: () => import("@/views/library/index.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/user/index.vue"),
  },

  {
    path: "/moreHistory",
    name: "moreHistory",
    component: () => import("@/views/index/moreHistory.vue"),
  },
  {
    path: "/channel",
    name: "channel",
    component: () => import("@/views/channel/index.vue"),
  },
  {
    path: "/danYuanJianCe",
    name: "danYuanJianCe",
    component: () => import("@/views/channel/danYuanJianCe.vue"),
  },
  {
    path: "/qiZhongCeShi",
    name: "qiZhongCeShi",
    component: () => import("@/views/channel/qiZhongCeShi.vue"),
  },
  {
    path: "/qiMoCeShi",
    name: "qiMoCeShi",
    component: () => import("@/views/channel/qiMoCeShi.vue"),
  },
  {
    path: "/zhiShiYaoDian",
    name: "zhiShiYaoDian",
    component: () => import("@/views/channel/zhiShiYaoDian.vue"),
  },
  {
    path: "/tongBuKeTang",
    name: "tongBuKeTang",
    component: () => import("@/views/channel/tongBuKeTang.vue"),
  },
  {
    path: "/zhangMo",
    name: "zhangMo",
    component: () => import("@/views/channel/zhangMo.vue"),
  },
  {
    path: "/dianLiFenXi",
    name: "dianLiFenXi",
    component: () => import("@/views/channel/dianLiFenXi.vue"),
  },
  {
    path: "/kaoNengCeYan",
    name: "kaoNengCeYan",
    component: () => import("@/views/channel/kaoNengCeYan.vue"),
  },
  {
    path: "/gaoKaoLianJie",
    name: "gaoKaoLianJie",
    component: () => import("@/views/channel/gaoKaoLianJie.vue"),
  },
  {
    path: "/zhiShiXiaoJie",
    name: "zhiShiXiaoJie",
    component: () => import("@/views/channel/zhiShiXiaoJie.vue"),
  },
  
  {
    path: "/mingJiaLangDu",
    name: "mingJiaLangDu",
    component: () => import("@/views/channel/yuWen/mingJiaLangDu.vue"),
  },
  {
    path: "/wenXueChangShi",
    name: "wenXueChangShi",
    component: () => import("@/views/channel/yuWen/wenXueChangShi.vue"),
  },
  {
    path: "/ziCiRenDu",
    name: "ziCiRenDu",
    component: () => import("@/views/channel/yuWen/ziCiRenDu.vue"),
  },
  {
    path: "/ziXingBianXi",
    name: "ziXingBianXi",
    component: () => import("@/views/channel/yuWen/ziXingBianXi.vue"),
  },
  {
    path: "/ciYiBianXi",
    name: "ciYiBianXi",
    component: () => import("@/views/channel/yuWen/ciYiBianXi.vue"),
  },
  {
    path: "/duoYinDuoYi",
    name: "duoYinDuoYi",
    component: () => import("@/views/channel/yuWen/duoYinDuoYi.vue"),
  },
  {
    path: "/guJinYiYi",
    name: "guJinYiYi",
    component: () => import("@/views/channel/yuWen/guJinYiYi.vue"),
  },{
    path: "/ciLeiHuoYong",
    name: "ciLeiHuoYong",
    component: () => import("@/views/channel/yuWen/ciLeiHuoYong.vue"),
  },{
    path: "/juShiJiLei",
    name: "juShiJiLei",
    component: () => import("@/views/channel/yuWen/juShiJiLei.vue"),
  },{
    path: "/ciYuJiLei",
    name: "ciYuJiLei",
    component: () => import("@/views/channel/yuWen/ciYuJiLei.vue"),
  },{
    path: "/tongJiaShiYi",
    name: "tongJiaShiYi",
    component: () => import("@/views/channel/yuWen/tongJiaShiYi.vue"),
  },{
    path: "/yiCiDuoYi",
    name: "yiCiDuoYi",
    component: () => import("@/views/channel/yuWen/yiCiDuoYi.vue"),
  },
  {
    path: "/yuanWenJieXi",
    name: "yuanWenJieXi",
    component: () => import("@/views/channel/yuWen/yuanWenJieXi.vue"),
  },
  {
    path: "/wenZhangJieGou",
    name: "wenZhangJieGou",
    component: () => import("@/views/channel/yuWen/wenZhangJieGou.vue"),
  },
  {
    path: "/zhuZhiDianJing",
    name: "zhuZhiDianJing",
    component: () => import("@/views/channel/yuWen/zhuZhiDianJing.vue"),
  },
  {
    path: "/xieFaYunYong",
    name: "xieFaYunYong",
    component: () => import("@/views/channel/yuWen/xieFaYunYong.vue"),
  },
  {
    path: "/yueDuTiSheng",
    name: "yueDuTiSheng",
    component: () => import("@/views/channel/yuWen/yueDuTiSheng.vue"),
  },
  

  {
    path: "/keWenLangDu",
    name: "keWenLangDu",
    component: () => import("@/views/channel/yingYu/keWenLangDu.vue"),
  },
  {
    path: "/danCiSuJi",
    name: "danCiSuJi",
    component: () => import("@/views/channel/yingYu/danCiSuJi.vue"),
  },
  {
    path: "/gaoPinCiHui",
    name: "gaoPinCiHui",
    component: () => import("@/views/channel/yingYu/gaoPinCiHui.vue"),
  },
  // {
  //   path: "/juShiJiLei",
  //   name: "juShiJiLei",
  //   component: () => import("@/views/channel/yingYu/juShiJiLei.vue"),
  // },
  {
    path: "/danCi",
    name: "danCi",
    component: () => import("@/views/channel/yingYu/danCi.vue"),
  },
  {
    path: "/yuFaJingJiang",
    name: "yuFaJingJiang",
    component: () => import("@/views/channel/yingYu/yuFaJingJiang.vue"),
  },
  
  {
    path: "/xieZuoZhiDao",
    name: "xieZuoZhiDao",
    component: () => import("@/views/channel/yingYu/xieZuoZhiDao.vue"),
  },
  {
    path: "/changYongBiaoDa",
    name: "changYongBiaoDa",
    component: () => import("@/views/channel/yingYu/changYongBiaoDa.vue"),
  },
  {
    path: "/dianLiXiangXi",
    name: "dianLiXiangXi",
    component: () => import("@/views/channel/yingYu/dianLiXiangXi.vue"),
  },
  {
    path: "/danYuanCeShi",
    name: "danYuanCeShi",
    component: () => import("@/views/channel/yingYu/danYuanCeShi.vue"),
  },
  {
    path: "/dongHuaYinBiao",
    name: "dongHuaYinBiao",
    component: () => import("@/views/channel/yingYu/dongHuaYinBiao.vue"),
  },

  
  {
    path: "/zhangMoZongJie",
    name: "zhangMoZongJie",
    component: () => import("@/views/channel/shuXue/zhangMoZongJie.vue"),
    children:[
      {
        path: "/zhiShiGouJian",
        name: "zhiShiGouJian",
        component: () => import("@/views/channel/shuXue/zhiShiGouJian.vue"),
      },
      {
        path: "/zhuanTiGuiNa",
        name: "zhuanTiGuiNa",
        component: () => import("@/views/channel/shuXue/zhuanTiGuiNa.vue"),
      },
      {
        path: "/jieTiTongFa",
        name: "jieTiTongFa",
        component: () => import("@/views/channel/shuXue/jieTiTongFa.vue"),
      }
    ]
  },
  
  {
    path: "/kaoDianJingJiang",
    name: "kaoDianJingJiang",
    component: () => import("@/views/channel/kaoDianJingJiang.vue"),
  },
  {
    path: "/zhiShiQuanJie",
    name: "zhiShiQuanJie",
    component: () => import("@/views/moudle/zhiShiQuanJie.vue"),
  },
  {
    path: "/jiZhongJingXue",
    name: "jiZhongJingXue",
    component: () => import("@/views/moudle/jiZhongJingXue.vue"),
  },
  {
    path: "/zhuanXiangPeiYou",
    name: "zhuanXiangPeiYou",
    component: () => import("@/views/moudle/zhuanXiangPeiYou.vue"),
  },
  {
    path: "/customMoudle",
    name: "customMoudle",
    component: () => import("@/views/moudle/customMoudle.vue"),
  },
  {
    path: "/zhiJiGaoKao",
    name: "zhiJiGaoKao",
    component: () => import("@/views/moudle/zhiJiGaoKao.vue"),
  },
  {
    path: "/shiYanTanJiu",
    name: "shiYanTanJiu",
    component: () => import("@/views/moudle/shiYanTanJiu.vue"),
  },

  
  
  {
    path: "/questionResult",
    name: "questionResult",
    component: () => import("@/views/channel/components/questionResult.vue"),
  },

  {
    path: "/liNianZhenTi",
    name: "liNianZhenTi",
    component: () => import("@/views/channel/liNianZhenTi/index.vue"),
  },
  
  

  {
    path: "/bookContent",
    name: "bookContent",
    component: () => import("@/views/moudle/bookContent.vue"),
  },

  {
    path: "/publicPage",
    name: "publicPage",
    component: () => import("@/views/publicPage/index.vue"),
  },
  

  {
    path: "/userCenter",
    name: "userCenter",
    component: () => import("@/views/user/index.vue"),
  },
  {
    path: "/errorBook",
    name: "errorBook",
    component: () => import("@/views/user/errorBook.vue"),
  },
  {
    path: "/history",
    name: "history",
    component: () => import("@/views/user/history.vue"),
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: () => import("@/views/user/changePassword.vue"),
  },
  {
    path: "/errorQuestion",
    name: "errorQuestion",
    component: () => import("@/views/user/errorQuestion.vue"),
  },
  
  {
    path: "/libraryList",
    name: "libraryList",
    component: () => import("@/views/library/libraryList.vue"),
  },
  {
    path: "/bookDetail",
    name: "bookDetail",
    component: () => import("@/views/library/bookDetail.vue"),
  },

  {
    path: "/version",
    name: "version",
    component: () => import("@/views/user/version.vue"),
  },
  {
    path: "/editUser",
    name: "editUser",
    component: () => import("@/views/user/editUser.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/sigin",
    name: "sigin",
    component: () => import("@/views/sigin.vue"),
  },
  {
    path: "/complateInfs",
    name: "complateInfs",
    component: () => import("@/views/complateInfs.vue"),
  },
  {
    path: "/webView",
    name: "webView",
    component: () => import("@/views/webView.vue"),
  },

  

  {
    path: "*",
    name: "errorPage",
    component: () => import("@/views/errorPage.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    history.pushState(null, null, document.URL);
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      };
    }
  },
});

//判断是否登录
router.beforeEach(function (to, from, next) {
  let token = window.localStorage.getItem("token");
  if (to.path != "/login" && to.path != "/sigin") {
    //通过查看token判断是否登录
    if (token && token != "") {
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login",
      });
    }

    if (to.path != "/complateInfs") {
      let isComplated = window.localStorage.getItem("complatedStatus");
      if (isComplated != 0) {
        next(); //表示已经完成完善信息
      } else {
        //next可以传递一个路由对象作为参数 表示需要跳转到的页面
        next({
          name: "complateInfs",
        });
      }
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});

export default router;
