<template>
<div class="tongbuketang-page">
<navBar @goBack="gobackChapter">
<span class="title">同步课堂</span>
</navBar>
<vidoeList></vidoeList>
</div>
</template>

<script>
export default {
name:'tongBuKeTang',
components:{
vidoeList:()=>import("@/views/channel/components/videoList2.vue")
},
data(){
return{

}
},

created(){ },
}
</script>

<style lang="scss" scoped>
.tongbuketang-page{
width: 100%;
height: 100%;
padding-top:1.08rem;
}
</style>