<template>
	<div class="textimgcontent-page">
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<div class="text-img-content" :class="currentSubject" v-if="!isEmpty">
				<div class="scroll-box">
					<div class="img-box" v-if="contentData.image">
						<van-image :src="setSourceUrl(contentData.image)" class="layout-img"></van-image>
					</div>
					<div class="text-box" id="text-box">
						<div v-html="contentData.content"></div>
					</div>
				</div>
			</div>
			<coustm-empty v-else></coustm-empty>
		</template>
	</div>
</template>

<script>
import { setSourceUrl, formatMathJaxImg, formatAddPointWords } from '@/utils/public';
export default {
	name: 'textImgContent',
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			contentData: {},
			currentSubject: ''
		};
	},
	created() {
		this.getContent();
		this.addHistory();
		let subjectName = this.$route.query.subjectName;
		switch (subjectName) {
			case '语文': {
				this.currentSubject = 'yuwen';
				break;
			}
			case '数学': {
				this.currentSubject = 'shuxue';
				break;
			}
			case '英语': {
				this.currentSubject = 'yingyu';
				break;
			}
			case '物理': {
				this.currentSubject = 'wuli';
				break;
			}
			case '化学': {
				this.currentSubject = 'huaxue';
				break;
			}
			case '政治': {
				this.currentSubject = 'zhengzhi';
				break;
			}
			case '历史': {
				this.currentSubject = 'lishi';
				break;
			}
			case '生物': {
				this.currentSubject = 'shengwu';
				break;
			}
			case '地理': {
				this.currentSubject = 'dili';
				break;
			}
			default: {
				this.currentSubject = '';
			}
		}
	},
	methods: {
		setSourceUrl,
		formatMathJaxImg,
		formatAddPointWords,
		setMathJaxText() {
			this.MathJax.MathQueue('math-formula'); //传入组件id，让组件被MathJax渲染
		},
		getContent() {
			this.isLoading = true;
			this.$api
				.getMenuContent({
					course_id:
						this.$route.query.childId ||
						this.$route.query.channelId ||
						this.$route.query.classId ||
						this.$route.query.unitId
				})
				.then((res) => {
					this.isLoading = false;
					let resData = res.data[0] || {};

					if (JSON.stringify(resData) == '{}') {
						this.isEmpty = true;
					} else {
						resData.content = this.formatMathJaxImg(resData.content);
						resData.content = this.formatAddPointWords(resData.content);

						this.contentData = resData;
						this.$nextTick(() => {
							let textBox = window.document.getElementById('text-box');
							if (textBox) {
								let img = textBox.getElementsByTagName('img');
								for (let i = 0; i < img.length; i++) {
									let parentDom = img[i].parentNode;
									parentDom.style.textIndent = 0;
									let parentDom1 = parentDom.parentNode;
									parentDom1.style.textIndent = 0;
									let parentDom2 = parentDom1.parentNode;
									parentDom2.style.textIndent = 0;
									let parentDom3 = parentDom2.parentNode;
									parentDom3.style.textIndent = 0;
								}
							}
						});
						setTimeout(() => {
							this.setMathJaxText();
						}, 10);
					}
				});
		}
	}
};
</script>

<style lang="scss">
.text-img-content.yuwen {
	em {
		position: relative;
		font-style: normal;
		&::after {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 100%);
			content: '';
			width: 4px;
			height: 4px;
			background: #333;
			border-radius: 50%;
		}
	}
}
.text-img-content {
	img {
		vertical-align: middle;
	}
	.text-box {
		* {
			font-size: 16px !important;
			line-height: 1.75em !important;
			img {
				max-width: 100% !important;
			}
		}
		img {
			max-width: 100% !important;
		}
		table {
			max-width: 100% !important;
		}
	}
}
</style>

<style lang="scss" scoped>
.textimgcontent-page {
	width: 100%;
	height: 100%;
	border-top: 0.2rem solid #f5f5f5;
	.text-img-content {
		width: 100%;
		height: 100%;
		padding: 0.3rem 0;
		background: #fff;
		.scroll-box {
			height: 100%;
			padding: 0 0.3rem;
			overflow: auto;
		}
		.img-box {
			min-width: 100%;
			min-height: auto;
			max-width: 100%;
			max-height: auto;
			margin-bottom: 0.3rem;
			.layout-img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
