<template>
<div class="ciyibianxi-page">
<navBar @goBack="gobackChapter">
<span class="title">字形辨析</span>
</navBar>
<div class="content-box">
<baseInput></baseInput>
</div>
</div>
</template>

<script>
export default {
name: "duoYinDuoYi",
components: {
baseInput: () => import("../components/baseInput.vue"),
},
data() {
return {};
},
created() {},
methods: {

},
};
</script>

<style lang="scss" scoped>
.ciyibianxi-page {
width: 100%;
height: 100%;
padding-top: 1.08rem;
.content-box{
height: 100%;
background: #fff;
}
}
</style>