<template>
	<div class="moudle-page-box">
		<navBar @goBack="goBack">
			<span class="title">
				{{ currentTitle }}
			</span>
		</navBar>
		<div class="moudle-page" id="scroll-menu">
			<loadingBox v-if="!showMenu"></loadingBox>
			<template v-if="showMenu">
				<coustm-empty v-if="isEmpty"></coustm-empty>
				<!-- <menuPage fromPage="jiZhongJingXue" :menuData="dataList" v-else></menuPage> -->
				<menuItem v-else fromPage="shiYanTanJiu" :menuData="dataList"></menuItem>
			</template>
		</div>
	</div>
</template>

<script>
import { deepClone } from '@/utils/public';
// import menuPage from '@/components/treeMenu/menuPage.vue';
import menuItem from '@/components/treeMenu/menuItem.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
export default {
	name: 'shiYanTanJiu',
	components: { menuItem, CoustmEmpty },
	watch: {
		accountRequest: function (val) {
			if (val == this.totalRequest) {
				this.showMenu = true;
			}
		}
	},
	data() {
		return {
			isEmpty: false,
			currentTitle: this.$route.query.moudleName,
			showMenu: false,
			accountRequest: 0,
			totalRequest: -100
		};
	},

	created() {
		let menuData = window.sessionStorage.getItem(`${this.$route.query.subjectId}-${this.$route.query.moudleId}`);
		if (!menuData) {
			this.initData();
		} else {
			this.dataList = JSON.parse(menuData);
			this.showMenu = true;
		}
	},
	mounted() {
		let scrollMenu = window.document.getElementById('scroll-menu');
		scrollMenu.addEventListener('scroll', () => {
			let scrollTop = scrollMenu.scrollTop;
			window.sessionStorage.setItem('menuScrollTop', scrollTop);
		});
		let scrollTop = window.sessionStorage.getItem('menuScrollTop');
		this.$nextTick(() => {
			if (scrollTop) {
				scrollMenu.scrollTo({
					top: scrollTop,
					behavior: 'auto'
				});
			}
		});
	},
	methods: {
		deepClone,
		goBack() {
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					...this.$route.query
				}
			});
		},
		initData() {
			// this.$api
			// 	.getMenuById({
			// 		subject_id: this.$route.query.subjectId,
			// 		pid: this.$route.query.moudleId
			// 	})
			// 	.then((res) => {
			// 		let resData = res.data || [];
			// 		this.dataList = resData;
			// 		this.totalRequest = resData.length;
			// 		if (this.totalRequest) {
			// 			for (let i = 0; i < resData.length; i++) {
			// 				this.initChapter(resData[i].id);
			// 			}
			// 		} else {
			// 			this.showMenu = true;
			// 			this.isEmpty = true;
			// 		}
			// 	});
			this.$api
				.getCourseList({
					subject_id: this.$route.query.subjectId,
					id: this.$route.query.moudleId
				})
				.then((res) => {
					let resData = res.data || [];
					this.dataList = resData;
					this.totalRequest = resData.length;
					if (this.totalRequest) {
						for (let i = 0; i < resData.length; i++) {
							this.accountRequest = ++this.accountRequest;
							// this.initChapter(resData[i].id);
						}
						window.sessionStorage.setItem(
							`${this.$route.query.subjectId}-${this.$route.query.moudleId}`,
							JSON.stringify(this.dataList)
						);
					} else {
						this.showMenu = true;
						this.isEmpty = true;
					}
				});
		},

		initChapter(id) {
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: id
				})
				.then((res) => {
					this.accountRequest = ++this.accountRequest;
					let resData = res.data || [];
					let _dataList = this.deepClone(this.dataList);
					_dataList.forEach((item) => {
						if (item.id == id) {
							item.children = resData;
						}
					});
					this.dataList = _dataList;
					window.sessionStorage.setItem(
						`${this.$route.query.subjectId}-${this.$route.query.moudleId}`,
						JSON.stringify(_dataList)
					);
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.moudle-page {
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
	position: relative;
	overflow: auto;
}
</style>
