<template>
	<div class="children-page">
		<navBar @goBack="gobackChapter">
			<span class="title">知识小结</span>
		</navBar>
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<div class="text-img-content" v-if="!isEmpty">
				<div class="class-layout">
					<van-image
						:src="setSourceUrl(contentData.image)"
						class="layout-img"
						fit="contain"
						:preview-src-list="[setSourceUrl(contentData.image)]"
					></van-image>
				</div>
			</div>
			<coustm-empty v-else></coustm-empty>
		</template>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
export default {
	name: 'zhiShiXiaoJie',
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			contentData: {}
		};
	},
	created() {
		this.initData();
		this.addHistory();
	},
	methods: {
		setSourceUrl,
		changeClass(n) {
			this.activeClass = n;
			this.jieXiText = '';
			if (!this.contentData[this.activeClass]) {
				this.getContent();
			}
		},
		initData() {
			// debugger
			this.isLoading = true;
			this.$api
				.getMenuContent({
					// course_id: this.$route.query.subjectId
					course_id: this.$route.query.channelId
				})
				.then((res) => {
					this.isLoading = false;
					let resData = res.data[0] || {};
					if (JSON.stringify(resData) == '{}') {
						this.isEmpty = true;
					} else {
						this.contentData = resData;
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.children-page {
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
}
.text-img-content {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-top: 0.2rem solid #f5f5f5;
}
.class-layout {
	display: flex;
	justify-content: center;
	width: 100%;
	// height: 100%;
	padding: 0.3rem;
	.layout-img {
		width: 100%;
		height: 100%;
	}
}
</style>
