<template>
	<div class="tree-menu">
		<!-- <ul class="moudle-list"> -->
		<!-- <li v-for="(menu, index) in menuData" :key="index"> -->
		<!-- <div class="unit-name">{{ menu.name }}</div> -->
		<menuItem :fromPage="fromPage" :menuData="menuData" :navType="navType"></menuItem>
		<!-- </li> -->
		<!-- </ul> -->
	</div>
</template>

<script>
import menuItem from './menuItem.vue';
export default {
	name: 'treeMenu',
	components: { menuItem },
	props: {
		menuData: {
			type: [Object, Array],
			default: () => {
				return [];
			}
		},
		navType: {
			type: [String, Number],
			default: 0
		},
		fromPage: {
			type: [String],
			default: ''
		}
	},
	watch: {
		// menuData: {
		// 	handler: function (val) {
		// 		if (val.length&&!val[0].showChild) {
		// 			val.forEach((item) => {
		// 			  let idx = 0;
		// 			  item.index = idx;
		// 			  item.showChild = true;
		// 			  this.forEachData(item,idx);
		// 			});
					
		// 		}
		// 		this.menuList = val;
		// 	},
		// 	immediate: true
		// },
	},
	data() {
		console.log(this.menuData)
		return {
			menuList: [],
			fromPages:''
		};
	},
	methods: {}
};
</script>

<style lang="scss" scoped>
.tree-menu {
	.unit-name {
		font-size: 14px;
		line-height: 56px;
		padding-left: 15px;
		color: #333;
		background: #fafafa;
	}
}
</style>
