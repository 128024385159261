<template>
	<div id="app">
		<keep-alive :include="cachePage">
			<router-view />
		</keep-alive>

		<ul class="component tabbar" v-if="hasTabBar.includes(currentPath)">
			<li
				v-for="(item, index) in tabBar"
				:key="index"
				:class="{ active: index == active }"
				@click="changeTab(item, index)"
			>
				<van-image v-show="index == active" class="icon-image" :src="item.activeIcon"></van-image>
				<van-image v-show="index != active" class="icon-image" :src="item.icon"></van-image>
				<div class="bar-text">{{ item.text }}</div>
			</li>
		</ul>

		<div class="server-dialog" v-if="showServer" @click="showServer = false">
			<div class="server-main" :style="`backgroundImage:url(${serverBg})`">
				<p class="text">请拨打客服电话售后服务</p>
				<p class="phone">400-801-8691</p>
				<a class="call-btn" href="tel:400-801-8691">立即拨打</a>
				<div class="cancle-call" @click="showServer = false">取消</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
	name: 'App',
	watch: {
		$route: function (val) {
			this.currentPath = this.$route.path || '/';
			this.active = this.$route.query.active || 0;
			if (val.path == '/login') {
				window.document.title = '登录';
			} else {
				window.document.title = '精准学移动端';
			}
		}
	},
	data() {
		return {
			currentPath: '/',
			hasTabBar: ['/', '/library', '/user'],
			cachePage: ['mainPage', 'libraryPage', 'userPage'],
			showServer: false,
			active: 0,
			serverBg: require('@/assets/img/serverBg.png'),
			tabBar: [
				{
					icon: require('@/assets/icon/home0.png'),
					activeIcon: require('@/assets/icon/home1.png'),
					text: '首页',
					url: '/'
				},
				{
					icon: require('@/assets/icon/library0.png'),
					activeIcon: require('@/assets/icon/library1.png'),
					text: '图书馆',
					url: '/library'
				},
				{
					icon: require('@/assets/icon/user0.png'),
					activeIcon: require('@/assets/icon/user1.png'),
					text: '我的',
					url: '/user'
				}
			]
		};
	},
	mounted() {
		this.$eventBus.$on('showServer', (val) => {
			this.showServer = val;
		});
		window.addEventListener('click', (e) => {
			let routerPath = this.$route.path;
			if (['/wenZhangJieGou', '/liNianZhenTi'].includes(routerPath)) {
				let childrenDom = e.path[0].children;
				let imgStr = null;
				for (let i = 0; i < childrenDom.length; i++) {
					if (childrenDom[i].nodeName == 'IMG') {
						imgStr = childrenDom[i];
						break;
					}
				}
				if (imgStr) {
					if (imgStr.currentSrc) {
						ImagePreview({
							images: [imgStr.currentSrc],
							startPosition: 0
						});
					}
				}
			}
		});
	},
	destroyed() {},
	methods: {
		changeTab(item, idx) {
			this.active = idx;
			this.$router.push({
				path: item.url,
				query: {
					active: idx
				}
			});
		}
	}
};
</script>

<style lang="scss">
@import 'assets/css/baseStyle.scss';
@import 'assets/css/iconfont.css';
@import 'assets/css/questionStyle.scss';
</style>
<style lang="scss" scoped>
#app {
	width: 100%;
	height: 100%;
}
.server-dialog {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	.server-main {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-end;
		width: 5.64rem;
		height: 323px;
		background-size: cover;
		animation: showServer 0.3s ease-in;
		.cancle-call {
			padding-bottom: 17px;
			font-weight: 400;
			color: #2db79a;
			font-size: 13px;
		}
		.call-btn {
			margin-bottom: 13px;
			width: 224px;
			height: 40px;
			background: linear-gradient(182deg, #29bc9d, #179d81);
			border-radius: 20px;
			text-align: center;
			line-height: 40px;
			font-size: 15px;
			color: #fff;
			font-weight: 500;
			&:active {
				background: linear-gradient(182deg, #179d81, #29bc9d);
			}
		}
		.phone {
			margin-bottom: 17px;
			font-size: 20px;
			font-weight: 500;
			color: #333;
		}
		.text {
			margin-bottom: 17px;
			font-size: 13px;
			font-weight: 400;
			color: #333;
		}
	}
}
.tabbar {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 200;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 0.98rem;
	background: #fff;
	border-top: 1px #dcdcdc solid;
	li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		i {
			line-height: 1;
			font-size: 0.4rem;
		}
		.bar-text {
			font-size: 0.24rem;
			font-weight: 400;
			color: #333;
			line-height: 0.34rem;
		}
		.icon-image {
			width: 0.3rem;
			height: 0.3rem;
		}
	}
	.active {
		i,
		.bar-text {
			color: #28b396;
		}
	}
}
</style>
