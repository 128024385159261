<template>
	<div class="dianlixiangxi-page">
		<navBar @goBack="goBack">
			<span class="title">典例详析</span>
		</navBar>
		<div class="content-box" v-if="questionData[currentQuestion]">
			<div class="text-box" v-html="questionData[currentQuestion].content"></div>
			<div class="textarea-box">
				<textarea class="user-input" v-model="userAnswer" rows="15"></textarea>
				<div class="btn" @click="questionData[currentQuestion].showAnalysis = true">查看解析</div>
			</div>

			<div
				class="jiexi-text text-box"
				v-if="questionData[currentQuestion].showAnalysis"
				v-html="questionData[currentQuestion].analysis"
			></div>
			<div
				class="jiexi-text text-box"
				v-if="questionData[currentQuestion].showAnalysis"
				v-html="questionData[currentQuestion].answer"
			></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'dianLiXiangXi',
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			questionData: [],
			currentQuestion: 0,
			userAnswer: ''
		};
	},
	created() {
		this.initData();
	},
	methods: {
		goBack() {
			let query = this.$route.query;
			delete query.channelId;
			delete query.channelName;
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					...query,
					from: 'zhiShiQuanJie'
				}
			});
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuQuestion({
					course_id: this.$route.query.childId || this.$route.query.channelId
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						let resetData = resData || [];
						//console.log(resetData);
						if (resetData.length) {
							this.questionData = resetData[0]?.children || [];
							//console.log(this.questionData);
						} else {
							this.isEmpty = true;
						}
					} else {
						this.isEmpty = true;
					}
					this.isLoading = false;
				});
		}
	}
};
</script>
<style lang="scss">
.dianlixiangxi-page {
	.text-box {
		* {
			font-size: 0.32rem !important;
			line-height: 1.75em !important;
			img {
				max-width: 100% !important;
			}
		}
		img {
			max-width: 100% !important;
		}
		table {
			max-width: 100% !important;
		}
	}
}
</style>
<style lang="scss" scoped>
.textarea-box {
	position: relative;
	.btn {
		position: absolute;
		right: 0.2rem;
		bottom: 0.2rem;
		width: 1.4rem;
		height: 0.4rem;
		border-radius: 0.2rem;
		font-size: 0.24rem;
		font-weight: 500;
		color: #fff;
		cursor: pointer;
		background: #27b295;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.dianlixiangxi-page {
	width: 100%;
	height: 100%;
	background: #fff;
	padding: 1.08rem 0 0.3rem 0;
	.content-box {
		padding: 0.2rem 0.3rem;
		height: 100%;
		overflow: auto;
		border-top: 0.2rem solid #f5f5f5;
	}
	.user-input {
		width: 100%;
		margin-top: 0.3rem;
		padding: 0.2rem;
		resize: none;
		border: 1px #333 solid;
		border-radius: 0.1rem;
		font-size: 0.3rem;
	}
}
</style>
