<template>
<div class="zhishiyaodian-page">
<navBar @goBack="gobackChapter">
<span class="title">知识要点</span>
</navBar>
<zhiShiYaoDian></zhiShiYaoDian>
</div>
</template>

<script>
export default {
name:'zhiShiYaoDianChannel',
components:{
zhiShiYaoDian:()=>import('@/views/channel/components/zhiShiYaoDian.vue')
}
}
</script>

<style lang="scss" scoped>
.zhishiyaodian-page{
width: 100%;
height: 100%;
}
</style>