<template>
	<div class="question-page">
		<navBar @goBack="gobackChapter">
			<span class="title">考能测验</span>
		</navBar>
		<questionContent></questionContent>
	</div>
</template>

<script>
export default {
	name: 'kaoNengCeYan',
	components: {
		questionContent: () => import('@/views/channel/components/questionContent.vue')
	},
	data() {
		return {};
	},
	created() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
.question-page {
	width: 100%;
	height: 100%;
}
</style>
