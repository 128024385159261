<template>
<div class="public-imgtext-page">
<textImgContent></textImgContent>
</div>
</template>

<script>
export default {
name:'zhShiGouJian',
components:{
textImgContent:()=>import('@/views/channel/components/textImgContent.vue')
},
data(){
return{

}
},
created(){
//this.addHistory();
}
}
</script>

<style lang="scss" scoped>
.public-imgtext-page{
height: 100%;
}
</style>