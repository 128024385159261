<template>
<div class="children-page">
<navBar @goBack="gobackChapter">
<span class="title">
{{ currentTitle }}
</span>
<template v-slot:right>
<span
style="color: #333; font-size: 18px"
class="menu-icon iconfont icon-mulu"
:class="{ active: showLayer }"
@click="showMenu"
></span>
</template>
</navBar>
<textImgHasMenu :showLayer="showLayer" @closeLayer="closeLayer"></textImgHasMenu>
</div>
</template>

<script>
export default {
name: "danCiSuJiPage",
components:{
textImgHasMenu: ()=> import("@/views/channel/components/textImgHasMenu.vue")
},
data(){
return {
showLayer:false,
currentTitle:this.$route.query.channelName
}
},
created(){},
methods:{
closeLayer(){
this.showLayer=false;
},
showMenu(){
this.showLayer=!this.showLayer;
},

}
};
</script>

<style lang="scss" scoped>
.children-page{
width: 100%;
height: 100%;
background: #fafafa;
}
</style>