import { render, staticRenderFns } from "./questionResult.vue?vue&type=template&id=0ae7eef0&scoped=true&"
import script from "./questionResult.vue?vue&type=script&lang=js&"
export * from "./questionResult.vue?vue&type=script&lang=js&"
import style0 from "./questionResult.vue?vue&type=style&index=0&id=0ae7eef0&prod&lang=scss&"
import style1 from "./questionResult.vue?vue&type=style&index=1&id=0ae7eef0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae7eef0",
  null
  
)

export default component.exports