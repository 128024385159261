<template>
	<div class="yuanwenjiexi-page" :class="{ emptyBorder: isEmpty }">
		<navBar @goBack="gobackChapter">
			<span class="title">原文解析</span>
		</navBar>
		<loadingBox v-if="isLoading"></loadingBox>
		<template v-else>
			<div class="text-img-content" :class="{ hasMore: 1 < classArr.length }" v-if="!isEmpty">
				<div class="scroll-box">
					<van-image
						v-if="contentData[activeClass].image"
						:src="setSourceUrl(contentData[activeClass].image)"
						class="class-img"
					></van-image>
					<!-- <div class="class-text" id="imgTextContent" v-if="classArr[activeClass].classtype != 2">
						<div v-html="contentData[activeClass].content" id="yuanWenContent"></div>
					</div> -->

					<div class="class-text centerLayout"  id="imgTextContent">
						<div v-html="contentData[activeClass].content" id="yuanWenContent"></div>
					</div>
				</div>
			</div>
			<coustm-empty v-else></coustm-empty>
		</template>
		<ul class="class-length" v-if="1 < classArr.length">
			<li
				:class="{ active: activeClass == index }"
				v-for="(item, index) in classArr"
				:key="index"
				@click="changeClass(index)"
			>
				{{ index + 1 }}
			</li>
		</ul>

		<div class="answer-layer" v-if="showJieXi" @click="showJieXi = false">
			<div class="layer-main" v-html="jieXiText"></div>
		</div>
	</div>
</template>

<script>
import { setSourceUrl } from '@/utils/public';
export default {
	name: 'yuanWenJieXi',
	data() {
		return {
			isLoading: false,
			isEmpty: false,
			contentData: [],
			jieXiText: '',
			classArr: [],
			activeClass: 0,
			showJieXi: false,
			yiwenStatus: false
		};
	},
	created() {
		this.getContent();
		this.addHistory();
	},
	methods: {
		setSourceUrl,

		changeClass(n) {
			this.activeClass = n;
			this.jieXiText = '';
			if (!this.contentData[this.activeClass]) {
				this.getContent();
			}
			this.$nextTick(() => {
				this.initEvent();
				this.appendYiWenBtn();
			});
		},
		appendYiWenBtn() {
			if (this.contentData[this.activeClass].yiwen) {
				let boxDom = window.document.getElementById('yuanWenContent');
				let pdom = boxDom.getElementsByTagName('p');

				let btnHtml = '<span class="jixiebtn"><span class="jiexi-handle yiwen">译</span></span></span>';
				let lastDom = pdom[pdom.length - 1];
				lastDom.insertAdjacentHTML('beforeend', btnHtml);
			}
		},
		initData() {
			this.isLoading = true;
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: this.$route.query.childId
				})
				.then((res) => {
					let resData = res.data || [];
					if (resData.length) {
						this.classArr = resData;
						this.getContent();
					} else {
						this.isEmpty = true;
						this.isLoading = false;
					}
				});
				
		},
		getContent() {
			this.isLoading = true;
			this.$api
				.getMenuContent({
					// course_id: this.classArr[this.activeClass].id
					course_id:this.$route.query.channelId
				})
				.then((res) => {
					this.isLoading = false;
					let resData = res.data[0] || {};

					// if (JSON.stringify(resData) == '{}') {
					// 	this.isEmpty = true;
					// } else {
						resData.content = this.formatContent(resData.content);
						this.$set(this.contentData, this.activeClass, resData);
						this.$nextTick(() => {
							this.appendYiWenBtn();
							this.initEvent();
						});
					// }
				});
		},
		initEvent() {
			// 初始化 原文解析事件
			let that = this;
			let content = document.getElementById('imgTextContent');
			this.$nextTick(() => {
				let jiexibtn = content?.getElementsByClassName('jixiebtn');
				if (jiexibtn) {
					for (var i = 0; i < jiexibtn.length; i++) {
						(function () {
							let item = jiexibtn[i];
							item.classList.remove('active');
							item.onclick = function (e) {
								that.yiwenStatus = false;
								for (let j = 0; j < jiexibtn.length; j++) {
									jiexibtn[j].classList.remove('active');
								}
								item.classList.add('active');

								if (e.target.innerText == '译') {
									that.jieXiText = that.contentData[that.activeClass].yiwen;
								} else {
									let reg = new RegExp('<[^>]+>', 'gi');
									let jiexi = item.getElementsByClassName('jiexi')[0].innerHTML;
									jiexi = jiexi.replace(reg, '');
									that.jieXiText = jiexi;
								}
								that.showJieXi = true;
							};
						})(i);
					}
				}
			});
		},
		formatContent(str) {
			// 格式化 “原文解析” 文章内容
			let content = str || '';
			let content1 = content.replace(
				/&lt;段析--/g,
				'<span class="jixiebtn"><span class="jiexi-handle duanxi">段</span><span class="jiexi">'
			);
			let content2 = content1.replace(/--段析&gt;/g, '</span></span>');
			let content3 = content2.replace(
				/&lt;句析--/g,
				'<span class="jixiebtn"><span class="jiexi-handle juxi">句</span><span class="jiexi">'
			);
			let content4 = content3.replace(/--句析&gt;/g, '</span></span>');
			let content5 = content4.replace(
				/&lt;词析--/g,
				'<span class="jixiebtn"><span class="jiexi-handle cixi">词</span><span class="jiexi">'
			);
			let content6 = content5.replace(/--词析&gt;/g, '</span></span>');

			let content7 = content6.replace(
				/&lt;译文--/g,
				'<span class="jixiebtn"><span class="jiexi-handle yiwen">译</span><span class="jiexi">'
			);
			let content8 = content7.replace(/--译文&gt;/g, '</span></span>');
			content8 = content8.replace(new RegExp('&lt;', 'g'), '<');
			content8 = content8.replace(new RegExp('&gt;', 'g'), '>');
			return content8;
		}
	}
};
</script>

<style lang="scss">
.class-text {
	* {
		font-size: 16px;
		line-height: 1.75em;
	}
	.duanxi {
		background: #ff3939;
	}
	.juxi {
		background: #28b295;
	}
	.cixi {
		background: #ff9600;
	}
	.yiwen {
		background: #007eff;
	}
	.jiexi-handle {
		position: relative;
		z-index: 1000;
		color: #fff !important;
		font-size: 13px !important;
		text-indent: 0 !important;
		width: 20px;
		height: 20px;
		margin: 5px;
		border-radius: 50%;
		line-height: 20px !important;
		display: inline-flex;
		justify-content: center !important;
		align-items: center !important;
		cursor: default;
		user-select: none;
	}
	.active {
		.jiexi-handle {
			border: 1px #fff solid;
			box-shadow: 0 0 5px #007eff;
		}
	}
	.jiexi {
		display: none !important;
	}
}
</style>

<style lang="scss" scoped>
.yiwen-btn {
	display: flex;
	justify-content: center;
	padding: 5px 0;
	span {
		font-size: 13px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		user-select: none;
		cursor: default;
		background: #007eff;
		color: #fff;
		text-align: center;
		line-height: 20px;
		border: 1px #007eff solid;
	}
	.active {
		border: 1px #fff solid;
		box-shadow: 0 0 5px #007eff;
	}
}
.answer-layer {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	.layer-main {
		width: 90%;
		min-height:3rem;
		max-height: 90%;
		overflow: auto;
		padding: 0.3rem;
		border-radius: 0.1rem;
		background: #fff;
		font-size: 0.26rem;
		::v-deep * {
			font-size: 0.26rem !important;
			color: #333 !important;
			line-height: 1.75em !important;
		}
	}
}
.class-length {
	position: fixed;
	left: 0;
	right: 0;
	top: 60px;
	z-index: 100;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 36px;
	border-bottom: 1px #dcdcdc solid;
	margin-bottom: 15px;
	background: #fff;
	li {
		position: relative;
		line-height: 36px;
		font-size: 13px;
	}
	.active {
		color: #28b295;
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			border-bottom: 1px #28b295 solid;
		}
	}
}
.scroll-box {
	height: 100%;
	padding-right: 0.3rem;
	overflow: auto;
}
.class-text {
	height: 100%;
	font-size: 0.24rem;
	font-weight: 400;
	color: #333;
	line-height: 1.75em;
	text-align: justify;
	justify-content: space-between;
}
.centerLayout {
	display: flex;
	justify-content: center;
	.paragraph {
		text-indent: 0;
	}
}
.class-img {
	width: 100%;
	height: auto;
}
.jiexi-text {
	font-size: 0.24rem;
	font-weight: 400;
	color: #333;
	line-height: 1.6;
	text-align: justify;
	justify-content: space-between;
}
.text-img-content {
	width: 100%;
	height: 100%;
	padding: 15px 0;
	background: #fff;
	border-top: 0.2rem solid #f5f5f5;
	.scroll-box {
		height: 100%;
		padding: 0 15px;

		background: #fff;
		overflow: auto;
	}
}
.hasMore {
	padding-top: 50px;
}
.yuanwenjiexi-page {
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
}
.emptyBorder {
	border: 1px #dcdcdc solid;
	border-radius: 10px;
}
</style>
