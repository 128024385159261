<template>
	<div class="moudle-page-box">
		<navBar @goBack="goBack">
			<span class="title">
				{{ currentTitle }}
			</span>
		</navBar>
		<div class="moudle-page" id="scroll-menu">
			<loading-box v-if="!showMenu"></loading-box>
			<template v-if="showMenu">
				<coustm-empty v-if="isEmpty"></coustm-empty>
				<menuPage fromPage="jiZhongJingXue" :menuData="dataList" v-else></menuPage>
			</template>
		</div>
	</div>
</template>

<script>
import { deepClone } from '@/utils/public';
import menuPage from '@/components/treeMenu/menuPage.vue';
import CoustmEmpty from '@/components/coustmEmpty.vue';
export default {
	name: 'zhiShiQuanJiePage',
	components: { menuPage, CoustmEmpty },
	watch: {
		accountRequest: function (val) {
			if (val == this.totalRequest) {
				this.showMenu = true;
			}
		}
	},
	data() {
		return {
			isEmpty: false,
			currentTitle: this.$route.query.moudleName,
			showMenu: false,
			accountRequest: 0,
			totalRequest: -100
		};
	},

	created() {
		this.dataList = this.$store.getters['common/getBookContent'];
		if (this.dataList.length == 0) {
			this.initData();
		} else {
			this.showMenu = true;
		}
	},
	mounted() {
		let scrollMenu = window.document.getElementById('scroll-menu');
		scrollMenu.addEventListener('scroll', () => {
			let scrollTop = scrollMenu.scrollTop;
			window.sessionStorage.setItem('menuScrollTop', scrollTop);
		});
		let scrollTop = window.sessionStorage.getItem('menuScrollTop');
		this.$nextTick(() => {
			if (scrollTop) {
				scrollMenu.scrollTo({
					top: scrollTop,
					behavior: 'auto'
				});
			}
		});
		window.addEventListener('popstate',this.clertData,false);
	},
	destroyed() {
		window.removeEventListener('popstate',this.clertData,false);
	},
	methods: {
		deepClone,
		goBack() {
			this.$store.commit('common/setBookContent', []);
			this.$router.push({
				path: `/${this.$route.query.from}`,
				query: {
					...this.$route.query
				}
			});
		},
		clertData(){
			this.$store.commit("common/setBookContent", []);
		},
		initData() {
			this.$api
				.getCourseList({
					subject_id: this.$route.query.subjectId,
					id: this.$route.query.moudleId
				})
				.then((res) => {
					let resData = res.data || [];
					resData.forEach((item) => {
						let idx = 0;
						item.index = idx;
						item.showChild = true;
						this.forEachData(item, idx);
					});
					this.$store.commit('common/setBookContent', resData);
					this.dataList = resData;
					this.totalRequest = resData.length;
					this.showMenu = true;
					if (this.totalRequest <= 0) {
						this.isEmpty = true;
					}
				});
		},
		forEachData(arr, idx) {
			if (arr.children) {
				++idx;
				arr.children.forEach((item1) => {
					item1.index = idx;
					item1.showChild = false;
					if (item1.children) {
						this.forEachData(item1, idx);
					}
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.moudle-page {
	width: 100%;
	height: 100%;
	padding-top: 1.08rem;
	position: relative;
	overflow: auto;
}
</style>
