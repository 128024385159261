<template>
<div class="children-page">

<navBar @goBack="gobackChapter">
<span class="title">
{{ currentTitle }}
</span>
</navBar>
<langDuKeWen></langDuKeWen>
</div>
</template>

<script>
export default {
name:'mingJiaLangDu',
components:{
langDuKeWen:()=>import('@/views/channel/components/langDuKeWen.vue')
},
data(){
return {
currentTitle:this.$route.query.channelName||this.$route.query.childName
}
},
methods:{

}
}
</script>

<style lang="scss" scoped>
.children-page{
width: 100%;
height: 100%;
}
</style>