import { render, staticRenderFns } from "./zhuanXiangPeiYou.vue?vue&type=template&id=cecaaaf4&scoped=true&"
import script from "./zhuanXiangPeiYou.vue?vue&type=script&lang=js&"
export * from "./zhuanXiangPeiYou.vue?vue&type=script&lang=js&"
import style0 from "./zhuanXiangPeiYou.vue?vue&type=style&index=0&id=cecaaaf4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cecaaaf4",
  null
  
)

export default component.exports