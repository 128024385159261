<template>
<div class="duoyinduoyi-page">
<navBar @goBack="gobackChapter">
<span class="title">多音多义</span>
</navBar>
<div class="content-box">
<baseChoose :questionType="1"></baseChoose>
</div>
</div>
</template>

<script>
export default {
name: "duoYinDuoYi",
components: {
baseChoose: () => import("../components/baseChoose.vue"),
},
data() {
return {};
},
created() {},
methods: {

},
};
</script>

<style lang="scss" scoped>
.duoyinduoyi-page {
width: 100%;
height: 100%;
padding-top: 1.08rem;
.content-box{
height: 100%;
background: #fff;
}
}
</style>