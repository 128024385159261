<template>
	<div class="xiezuo-page">
		<navBar @goBack="gobackChapter">
			<span class="title">{{ channelName }}</span>
		</navBar>
		<loadingBox v-if="isLoading"></loadingBox>

		<ul class="children-nav">
			<li
				v-for="(nav, index) in childrenNav"
				:key="`nav${index}`"
				:class="{ active: activeNav == index }"
				@click="changeChildNav(nav, index)"
			>
				{{ nav.name }}
			</li>
		</ul>

		<!-- <div class="children-content" v-if="!isLoading">
<textImgContent v-if="menutype == 2"></textImgContent>
<questionList
v-else
position="right"
style="padding-top: 0"
></questionList> -->
		<template>
			<div class="children-content" v-if="!isLoading">
				<keep-alive>
					<router-view :key="urlKey"></router-view>
				</keep-alive>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'xieZuo',
	components: {
		// textImgContent: () =>
		// import("@/views/channel/components/textImgContent.vue"),
		// questionList: () => import("@/views/channel/components/questionList.vue"),
	},
	data() {
		return {
			isLoading: false,
			childrenNav: [],
			activeNav: 0,
			menutype: '',
			url: '',
			channelName: ''
		};
	},
	computed: {
		// 解决不刷新问题
		urlKey() {
			return this.$route.query.t;
		}
	},
	created() {
		// this.initData();
		this.menutype = this.$route.query.menutype;
		this.channelName = this.$route.query.channelName||this.$route.query.childName;
	},
	methods: {
		changeChildNav(item, index) {
			const { path } = this.$route;
			this.activeNav = index;
			// this.isLoading = true;
			this.$router.replace({
				path: this.url,
				query: {
					...this.$route.query,
					childId: item.id,
					childName: item.name,
					t: Date.now()
				}
			});
			// this.$nextTick(() => {
			// this.isLoading = false;

			// })
		},
		initData() {
			this.isLoading = true;
			// alert(1)
			this.$api
				.getMenuById({
					subject_id: this.$route.query.subjectId,
					pid: this.$route.query.channelId
				})
				.then((res) => {
					let resData = res.data || [];
					this.childrenNav = resData;

					this.isLoading = false;
					for (let i = 0; i < resData.length; i++) {
						if (this.$route.query.childId == resData[i].id) {
							this.activeNav = i;
							break;
						}
					}
					this.url = resData[this.activeNav].url;

					this.$router.replace({
						path: this.url,
						query: {
							...this.$route.query,
							childId: resData[this.activeNav].id,
							childName: resData[this.activeNav].name,
							t: Date.now()
						}
					});
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.xiezuo-page {
	height: 100%;
	width: 100%;
	.children-content {
		height: calc(100% - 0.6rem);
	}
	.children-nav {
		overflow-y: auto;

		display: flex;
		align-items: center;
		// justify-content: space-around;
		font-size: 0.24rem;
		color: #333;
		font-weight: 400;
		word-break: keep-all;
		white-space: nowrap;
		cursor: pointer;
		// margin-top: 50px;
		user-select: none;
		height: 1.08rem;
		li {
			display: flex;
			align-items: center;
			font-size: 15px;
			padding: 0 10px;
			&:hover {
				opacity: 0.8;
			}
		}
		.active {
			position: relative;
			color: #20a287;
			&::after {
				position: absolute;
				left: 50%;
				bottom: -0.15rem;
				content: '';
				transform: translateX(-50%);
				width: 12px;
				height: 2px;
				background: #20a287;
				border-radius: 0.02rem;
			}
		}
		.activeWord {
			color: #20a287;
		}
	}
}
</style>
