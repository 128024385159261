<template>
<div class="zhuzhidianjing-page">
<navBar @goBack="gobackChapter">
<span class="title">主旨点睛</span>
</navBar>
<textImgContent></textImgContent>
</div>
</template>

<script>
export default {
name: "zhuZhiDianJing",
components: {
textImgContent: () => import("../components/textImgContent.vue"),
},
data() {
return {};
},
created() {},
methods: {

},
};
</script>

<style lang="scss" scoped>
.zhuzhidianjing-page {
width: 100%;
height: 100%;
padding-top: 1.08rem;
display: flex;
justify-content: center;
align-items: center;
&::after{
	content: "";
	width: 100%;
	height: 0.2rem;
	background-color: #f5f5f5;
	position: absolute;
	left: 0;
	top: 1.08rem;;
}
}
</style>