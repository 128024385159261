import http from "../http";
const menu = {
  //获取目录
  getMenuById: function (params) {
    return http.get("/api/v2/courses", params);
  },
  //获取目录下的内容
  getMenuContent: function (params) {
    return http.get("/api/v2/coursecontent", params);
  },

  //获取目录下的题目
  getMenuQuestion: function (params) {
    return http.get("/api/v2/questionbank", params);
  },

  //获取作者简介
  getMenuAuthor: function (params) {
    return http.get("/api/v2/getzzjj", params);
  },

  //获取名家朗读
  getMenuMjld: function (params) {
    return http.get("/api/v2/mjld", params);
  },

  //获取单词
  getWords: function (params) {
    return http.get("/api/v2/word", params);
  },
  //添加单词速记记录
  getAddnum: function (params) {
    return http.get("/api/v2/addnum", params);
  },//获取单词速记记录
  getWordnum: function (params) {
    return http.get("/api/v2/getwordnum", params);
  },

  //获取真题
  getZhenTi: function (params) {
    return http.get("/api/v2/truetopic", params);
  },

  //获取用户选择的版本
  getUserPress: function (params) {
    return http.get("/api/v2/pressuser", params);
  },

  getGaopincihui: function (params) {
    return http.get("/api/v2/getgaopincihui",params);
  },

  // /api/v2/getcourselist?id=2&role_id=2&subject_id=1
  getCourseList: function (params) {
    return http.get("/api/v2/getcourselist",params);
  }
	
};

export default menu;
